import { MenuItem } from "primereact/menuitem"
import { getFirstEmail, humanNameAsString } from "fhir"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons"
import {
  faCancel,
  faCheck,
  faCopy,
  faExternalLink,
  faEye,
  faPaperPlane,
  faSitemap,
  faUserDoctor,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { AvatarImage, BadgeProps, StackedListItemProps } from "commons"
import { copyToClipboard, displayNotificationSuccess } from "utils"

import { PatientInfo } from "../types"

const patientModelBuilder = (
  patientInfo: PatientInfo,
  handleInviteModal: (patientInfo: PatientInfo) => void,
  showPatientDetails: (patientInfo: PatientInfo) => void,
  handleActiveDeactivePatient: (patientInfo: PatientInfo) => void,
): StackedListItemProps => {
  const getImage = () => {
    return (
      <AvatarImage
        name={humanNameAsString(patientInfo.patient.name?.[0])}
        photoUrl={patientInfo.patient.photo?.[0]?.url}
        className="h-10 w-10 rounded-full"
      />
    )
  }

  const ehrURL = `${window.VITE_APP_EHR_APP_URL}/orgs/${patientInfo.organization?.id}/patients/${patientInfo.patient.id}?kp=summary`

  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        label: "Show Details",
        icon: <FontAwesomeIcon icon={faEye} size="sm" className="mr-2" />,
        command: () => showPatientDetails(patientInfo),
      },
      {
        label: "See on EHR",
        icon: <FontAwesomeIcon icon={faExternalLink} size="sm" className="mr-2" />,
        url: ehrURL,
        target: "_blank",
        disabled: !patientInfo.organization,
      },
      {
        label: "Invite",
        icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
        command: () => handleInviteModal(patientInfo),
        disabled: !!patientInfo.b2cUserId,
      },
      {
        label: patientInfo.patient?.active === false ? "Activate" : "Deactivate",
        icon: <FontAwesomeIcon icon={patientInfo.patient?.active ? faCancel : faCheck} size="sm" className="mr-2" />,
        command: () => handleActiveDeactivePatient(patientInfo),
      },
      {
        label: "Copy id",
        icon: <FontAwesomeIcon icon={faCopy} size="sm" className="mr-2" />,
        command: () =>
          copyToClipboard(patientInfo.patient.id as string, () => displayNotificationSuccess("Copied to clipboard")),
      },
    ]
    return menuItems
  }
  const getBadgeProps = (): BadgeProps => ({
    text: patientInfo.patient.active === false ? "deactivated" : " active",
    colorStyle: patientInfo.patient.active === false ? "red" : "green",
  })

  return {
    leftData: [
      { lineItems: [{ name: "Name", value: humanNameAsString(patientInfo.patient.name?.[0]) }] },
      {
        lineItems: [
          { name: "Email", value: getFirstEmail(patientInfo.patient.telecom) ?? "No email provided", icon: faEnvelope },
          patientInfo?.organization
            ? { name: "Organization", value: patientInfo.organization.display, icon: faSitemap }
            : {},
          patientInfo?.practioner
            ? { name: "Practitioner", value: patientInfo?.practioner.display, icon: faUserDoctor }
            : {},
        ],
      },
    ],
    badge: getBadgeProps(),
    image: getImage(),
    menu: getMenuItems(),
  }
}

export { patientModelBuilder }
