import { Patient } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { ContactPointSystem } from "data"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { patientQueryKey } from "../query-keys"

const useUpdatePatientEmail = (onSuccess?: () => void, onSettled?: (error: Error | null) => void) => {
  const { update } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const updatePatient = async ({ patient, email }: { patient: Patient; email: string }) => {
    const newMailTelecom = [{ system: ContactPointSystem.email, use: "home", value: email }]
    const newTelecom = patient.telecom ?? newMailTelecom

    if (patient.telecom) {
      const index = newTelecom?.findIndex((t) => t.system === ContactPointSystem.email)
      newTelecom.splice(index, index >= 0 ? 1 : 0, ...newMailTelecom)
    }

    return update("Patient", patient.id!, { ...patient, telecom: newTelecom })
  }

  const { mutate: updatePatientEmail, isPending: isUpdating } = useMutation({
    mutationFn: updatePatient,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: patientQueryKey.all })
      onSuccess?.()
    },
    onSettled: async (_, error) => {
      await queryClient.invalidateQueries({ queryKey: patientQueryKey.all })
      onSettled?.(error)
    },
  })

  return { updatePatientEmail, isUpdating }
}

export { useUpdatePatientEmail }
