import { datadogRum } from "@datadog/browser-rum"

import packageJson from "../../package.json"

const isDevelopment = process.env.NODE_ENV === "development"

const dataDogInit = () => {
  datadogRum.init({
    applicationId: "c835ca7a-09a4-414c-9d4d-c8c55cfd89ca",
    clientToken: "pub9bdf69c408ed4209ff900cf19ec452a3",
    site: "us5.datadoghq.com",
    service: "app-admin",
    env: window.VITE_APP_ENVIROMENT_NAME,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: () => {
      return !isDevelopment
    },
  })
}

export { dataDogInit, datadogRum }
