import { useQuery } from "@tanstack/react-query"

import { IClient, useClient } from "api"

const useGetSignedUrl = (url: string) => {
  const { getSignedUrl } = useClient(IClient.AIDBOX)
  const queryKey = ["signedUrl", url]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await getSignedUrl(url, signal)
      return response.url
    },
    meta: { context: { queryKey, url } },
  })

  return { signedUrl: data, isLoading }
}

export { useGetSignedUrl }
