import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters, ResourceObject, Setting, SettingItemArray } from "fhir"

import { IClient, useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"

const useOverwriteSetting = () => {
  const queryClient = useQueryClient()
  const { patch, operationRequest } = useClient(IClient.AIDBOX)

  const updatedSettings = async ({
    settingDefinitionId,
    organizationId,
    items,
  }: {
    settingDefinitionId: string
    organizationId: string
    items: SettingItemArray[]
  }) => {
    const parameters = {
      resourceType: "Parameters",
      parameter: [
        { name: "code", value: { code: settingDefinitionId } },
        { name: "owner", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
      ],
    } as Parameters

    const setting = await operationRequest<Setting>("/Setting", "POST", "instantiate", undefined, parameters)

    return patch("Setting", setting?.id as string, { item: items, meta: setting.meta } as ResourceObject)
  }

  const { mutate: overwriteSetting, isPending: isOverwriting } = useMutation({
    mutationFn: updatedSettings,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { organizationId, settingDefinitionId }) => {
      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.all(organizationId, settingDefinitionId),
        refetchType: "all",
      })

      displayNotificationSuccess("Settings updated successfully")
      datadogLogs.logger.info(`Settings on ${organizationId} updated successfully`)
    },
  })

  return { overwriteSetting, isOverwriting }
}

export { useOverwriteSetting }
