import { Reference } from "fhir"
import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { PractitionerApi } from "commons"

import { practitionerQueryKeys } from "../query-keys"

const COUNT_PER_PAGE = 20

const usePractitioners = (
  filter = "",
  email?: string,
  gender?: string,
  organization?: Reference,
  role?: string,
  status?: string,
) => {
  const { search } = useClient()
  const queryKey = practitionerQueryKeys.list(filter, email, gender, organization, role, status)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PractitionerQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { term: filter } : {}),
        ...(email ? { _email: email } : {}),
        ...(gender ? { _gender: gender } : {}),
        ...(organization ? { organization: organization.id } : {}),
        ...(role ? { role: role } : {}),
        ...(status ? { active: status } : {}),
        _count: COUNT_PER_PAGE.toString(),
        _elements: "id,identifier,name,gender,address,photo,birthDate,active,telecom",
        _sort: "name",
        _page: `${pageParam}`,
      })

      const bundle = await search("practitioners", filters, undefined, signal)

      const practitioners = bundle.entry as PractitionerApi[]

      const next = (pageParam as number) * COUNT_PER_PAGE < (bundle?.total ?? 0) ? (pageParam as number) + 1 : undefined

      return { practitioners, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, filter, email, gender, organization, role, status } },
  })

  const { practitionerGroups, count } = useMemo(() => {
    let count = 0

    const groupMap = data?.pages?.reduce((map, { practitioners }) => {
      count += practitioners.length

      practitioners.forEach((prat) => {
        const key = prat?.practitioner?.name?.[0]?.family?.[0] ?? "other"
        const value = map.get(key)

        if (value) {
          value.push(prat)
        } else {
          map.set(key, [prat])
        }
      })

      return map
    }, new Map<string, PractitionerApi[]>())

    return {
      practitionerGroups: groupMap
        ? Array.from(groupMap.entries()).map(([key, items]) => ({
            key,
            name: key,
            items,
          }))
        : [],
      count,
    }
  }, [data?.pages])

  return {
    practitionerGroups,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type PractitionerQueryData = { practitioners: PractitionerApi[]; next: number | undefined; total: number }

export { usePractitioners }
