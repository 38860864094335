import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Form, useFormikContext } from "formik"
import { Button } from "primereact/button"
import { DropdownChangeEvent } from "primereact/dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { AutoCompletePatientField, DateField, DropdownField, MultiSelectField, OrganizationAutocomplete } from "commons"
import { srCategoryCodes } from "data"

import { OrderFilter } from "../types"
import { getStatusesByType } from "../utils"

const OrdersFilters = ({ onClearFilters }: Props) => {
  const [params] = useSearchParams()
  const {
    values: { type, status, authored, occurrence, organization, patient },
    isSubmitting,
    setFieldValue,
  } = useFormikContext<OrderFilter>()
  const srCategories = Object.values(srCategoryCodes)

  const [statusCodes, setStatusCodes] = useState(() => {
    const type = params.get("type") ?? ""
    return getStatusesByType(type)
  })

  const handleChangeType = (e: DropdownChangeEvent) => {
    const type = e.value
    setStatusCodes(getStatusesByType(type))
  }

  useEffect(() => {
    const statusFiltered = status?.filter((x) => statusCodes.find((y) => y.code === x))
    setFieldValue("status", statusFiltered)
  }, [type])

  const isClearButtonDesabled =
    isSubmitting || (!patient && !status && !type && !organization && !status && !authored && !occurrence)
  return (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        label="Type"
        field="type"
        options={srCategories}
        optionLabel="display"
        horizontal
        showClear
        inputClassName="slashed text-sm"
        handleChange={handleChangeType}
      />
      <AutoCompletePatientField
        label="Patient"
        field="patient"
        horizontal
        className="border-none"
        inputClassName="slashed text-sm w-full "
      />
      <OrganizationAutocomplete
        horizontal
        className="border-none"
        inputClassName="slashed text-sm w-full "
        label="Organization"
        field="organization"
      />
      <MultiSelectField
        label="Status"
        field="status"
        options={statusCodes}
        optionLabel="display"
        horizontal
        showClear
        inputClassName="slashed text-sm"
      />
      <DateField
        label="Authored on"
        field="authored"
        horizontal
        inputClassName="slashed text-sm pl-2"
        className="h-10"
        maxDate={new Date()}
      />
      <DateField
        label="Occurrence"
        field="occurrence"
        horizontal
        inputClassName="slashed text-sm pl-2"
        className="h-10"
        maxDate={new Date()}
      />
      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isClearButtonDesabled}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )
}

type Props = {
  onClearFilters(): void
}

export { OrdersFilters }
