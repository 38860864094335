import { SelectButton } from "primereact/selectbutton"
import { FC, useEffect, useMemo, useReducer, useState } from "react"

import { useOrganizationContext } from "organizations"

import { medicineClassificationCodings } from "../../data"
import { MEDICATIONS_SECTION_TYPE, MedicationsAdvanceFilter } from "../../types"
import { MedicationsFilters } from "./MedicationsFilters"
import { MedicationsNutraceuticals } from "./MedicationsNutraceuticals"
import { MedicationsRxs } from "./MedicationsRxs"

const MedicationsContainer: FC<Props> = ({ isNutrasActive, isRxActive }) => {
  const allowedSections = [
    ...(isRxActive ? [{ name: "Pharmaceuticals", value: MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION }] : []),
    ...(isNutrasActive
      ? [{ name: "Nutraceuticals", value: MEDICATIONS_SECTION_TYPE.MEDICATIONS_NUTRACEUTICALS_SECTION }]
      : []),
  ]
  const [activeTab, setActiveSection] = useState(allowedSections[0]?.value ?? allowedSections[1]?.value)
  const { nutraCatalogs, rxCatalogs } = useOrganizationContext()

  const { catalogs, catalogsById } = useMemo(() => {
    const catalogs = activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? rxCatalogs : nutraCatalogs
    const catalogsById = catalogs.map((c) => c.id as string)
    return { catalogs, catalogsById }
  }, [activeTab])

  /* Force set initial catalogs according with the ones available for org */
  useEffect(() => {
    if (catalogsById.length) filter({ mkCatalogs: catalogsById })
  }, [catalogsById])

  const medicineClassificationByCode = medicineClassificationCodings.flatMap((coding) => coding.code!)

  const { mkCatalogs, medsClassificationCodes, searchText, search, filter, reset } = useReducerState(
    catalogsById,
    medicineClassificationByCode,
  )

  return (
    <div className="flex flex-col w-full h-full px-1.5 pt-1.5 overflow-hidden">
      <SelectButton
        value={activeTab}
        options={allowedSections}
        optionValue="value"
        optionLabel="name"
        onChange={(e) => setActiveSection(e.value)}
        className="mb-5"
        allowEmpty={false}
      />
      <MedicationsFilters
        activeTab={activeTab}
        filters={
          activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION
            ? { mkCatalogs, searchText, medsClassificationCodes }
            : { mkCatalogs, searchText }
        }
        onFilter={filter}
        onSearch={search}
        onReset={reset}
        catalogOptions={catalogs}
        medicineClassificationOptions={medicineClassificationCodings}
      />
      <div className="flex flex-col overflow-y-auto grow mt-6">
        {activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? (
          <MedicationsRxs filters={{ mkCatalogs, searchText, medsClassificationCodes }} filter={filter} />
        ) : (
          <MedicationsNutraceuticals filters={{ mkCatalogs, searchText }} filter={filter} />
        )}
      </div>
    </div>
  )
}

const getInitialState = (catalogs?: string[] | undefined, medicineClassificationCodes?: string[]) => {
  return {
    searchText: "",
    mkCatalogs: catalogs,
    medsClassificationCodes: medicineClassificationCodes,
  } as State
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "search" | "filter"
    payload?: string | MedicationsAdvanceFilter
  },
) => {
  switch (type) {
    case "reset":
      return { ...state }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return {
        ...state,
        mkCatalogs: (payload as MedicationsAdvanceFilter)?.mkCatalogs,
        medsClassificationCodes: (payload as MedicationsAdvanceFilter)?.medsClassificationCodes,
      }
    default:
      return state
  }
}

const useReducerState = (catalogs?: string[], medicineClassificationCodes?: string[]) => {
  const state = getInitialState(catalogs, medicineClassificationCodes)
  const [{ searchText, mkCatalogs, medsClassificationCodes }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: MedicationsAdvanceFilter) => {
    dispatch({
      type: "filter",
      payload: {
        ...filters,
        mkCatalogs: filters.mkCatalogs?.length ? filters.mkCatalogs : catalogs,
        medsClassificationCodes: filters.medsClassificationCodes?.length
          ? filters.medsClassificationCodes
          : medicineClassificationCodes,
      },
    })
  }

  return {
    searchText,
    mkCatalogs,
    medsClassificationCodes,
    filter,
    search,
    reset,
  }
}

type State = {
  searchText?: string
} & MedicationsAdvanceFilter

type Props = {
  isNutrasActive: boolean
  isRxActive: boolean
}

export { MedicationsContainer }
