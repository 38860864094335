import { createContext, FC, PropsWithChildren, useState } from "react"

const OrgIdContext = createContext<State>({ orgId: { value: "", display: "" }, setOrgId: () => {} })
OrgIdContext.displayName = "OrgIdContext"

const OrgIdContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [orgId, setOrgId] = useState<OrgIdState>({ value: "", display: "" })

  return <OrgIdContext.Provider value={{ orgId, setOrgId }}>{children}</OrgIdContext.Provider>
}

type OrgIdState = {
  value: string
  display: string | undefined
}

type State = {
  orgId: OrgIdState
  setOrgId: (orgId: OrgIdState) => void
}

export { OrgIdContextProvider, OrgIdContext }
