import { formatsByTypes } from "data"
import { format } from "date-fns"
import { ServiceRequest } from "fhir"

const getOrderDate = (order?: ServiceRequest) => {
  let dateString = order?.authoredOn ?? order?.occurrence?.dateTime
  if (!dateString) return "Unspecified"

  if (!dateString.includes("T")) dateString += "T00:00:00"
  return format(new Date(dateString), formatsByTypes.LONG_DATE)
}

export { getOrderDate }
