import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { User } from "fhir/fhir-extended"

import { usersQueryKeys } from "../query-keys"

const useUsers = (filter = "", status?: string, after?: string, before?: string) => {
  const { search } = useClient()
  const queryKey = usersQueryKeys.list(filter, status, after, before)
  const date = () => {
    let n = ""
    if (after || before) {
      n = `${after}$${before}`
    }
    return n
  }

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    UsersQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = "" }) => {
      const filters = new URLSearchParams({
        ...(filter ? { filter: filter } : {}),
        ...(status ? { accountEnabled: status } : {}),
        ...(date() ? { createdOn: date() } : {}),
        ...(pageParam ? { nextLink: pageParam as string } : {}),
      })

      const bundle = (await search(`users`, filters)) as UsersQueryData

      const users = bundle.users

      const nextLink = bundle.nextLink ? (pageParam = bundle.nextLink) : undefined

      return { users, nextLink }
    },
    initialPageParam: "",
    getNextPageParam: (lastPage) => lastPage.nextLink,
    meta: { context: { queryKey, filter, status, after, before } },
  })

  const { users } = useMemo(() => {
    const users = data?.pages.flatMap((page) => page.users)
    return {
      users: users as User[],
    }
  }, [data?.pages])

  return {
    users,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadUsers: refetch,
  }
}

type UsersQueryData = {
  users?: User[]
  nextLink?: string
}

export { useUsers }
