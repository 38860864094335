import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "./constants"
import {
  Bundle,
  CodeableConcept,
  ContactPoint,
  HumanName,
  isResourceObject,
  Reference,
  ResourceObject,
  Address,
  isPatient,
  isPractitioner,
  isOrganization,
  isPractitionerRole,
  isComposition,
} from "./fhir"

const getResources = <T extends ResourceObject>(bundle: Bundle, resourceType?: string) =>
  bundle.entry?.reduce((acc, { resource }) => {
    if (isResourceObject(resource)) {
      if (resourceType) return resourceType === resource.resourceType ? [...acc, resource as T] : [...acc]
      else return [...acc, resource as T]
    }

    return acc
  }, [] as T[]) ?? []

const getResource = <T extends ResourceObject>(bundle: Bundle, resourceType: string) =>
  bundle.entry?.find(({ resource }) => isResourceObject(resource) && resource.resourceType === resourceType)
    ?.resource as T

const getResourcesByTypeAsIndex = <T extends ResourceObject>(
  bundle: Bundle,
  resourceType: string,
  getIndex?: (resource: T) => string | undefined,
) =>
  bundle.entry?.reduce(
    (acc, { resource }) => {
      if (isResourceObject(resource) && resource.id && resource.resourceType === resourceType) {
        const index = getIndex?.(resource as T) ?? resource.id
        return { ...acc, [index]: resource as T }
      }

      return acc
    },
    {} as Record<string, T>,
  ) ?? {}

const getResourcesAsIndex = <T extends ResourceObject>(bundle: Bundle, resourceType?: string) =>
  bundle.entry?.reduce(
    (acc, { resource }) => {
      if (isResourceObject(resource) && resource.id) {
        if (resourceType)
          return resourceType === resource.resourceType ? { ...acc, [resource.id]: resource as T } : { ...acc }
        else return { ...acc, [resource.id]: resource as T }
      }

      return acc
    },
    {} as Record<string, T>,
  ) ?? {}

const getAPIResourcesAsIndex = <T extends ResourceObject>(bundle: CustomBundle<T>, resourceType?: string) =>
  bundle.included?.reduce(
    (acc, resource) => {
      if (isResourceObject(resource) && resource.id) {
        if (resourceType)
          return resourceType === resource.resourceType ? { ...acc, [resource.id]: resource as T } : { ...acc }
        else return { ...acc, [resource.id]: resource as T }
      }

      return acc
    },
    {} as Record<string, T>,
  ) ?? {}
// const getResourcesAsIndex = <T extends ResourceObject>(bundle: Bundle) =>
//   bundle.entry?.reduce((acc, { resource }) => {
//     if (isResourceObject(resource) && resource.id) {
//       return { ...acc, [resource.id]: resource as T }
//     }

//     return acc
//   }, {} as Record<string, T>) ?? {}

const humanNameAsString = (name: HumanName | undefined): string => {
  if (!name) {
    return "No name provided"
  }

  if (name.text) {
    return name.text.trim()
  }

  const given = name?.given ? `${name?.given.join(" ")} ` : ""

  return `${given}${name.family}`
}

const getFirstEmail = (telecom: ContactPoint[] | undefined) => {
  const email = telecom?.find(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)
  if (!email || !email?.value) {
    return "No email provided"
  }

  return email?.value
}

const getFirstPhone = (telecom: ContactPoint[] | undefined) => {
  const phone = telecom?.find(({ system }) => system === PHONE_CONTACT_POINT_SYSTEM)

  if (!phone || !phone?.value) {
    return "No phone provided"
  }

  const matches = phone.value.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (matches && matches.length === 4) return `(${matches[1]}) ${matches[2]}-${matches[3]}`

  return phone.value
}

const getAddress = (address: Address[] | undefined) => {
  if (!address?.[0]) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address?.[0] ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const getStringAddressByType = (address: Address[] | undefined, addressType?: string) => {
  const index = address?.findIndex(({ use, type }) => use === "home" && type === addressType) ?? -1
  if (!address?.[index]) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address?.[index] ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const codeableConceptAsString = (cc: CodeableConcept | undefined) => {
  if (!cc?.coding?.[0].code) {
    return "unspecified"
  }

  return cc.text ?? cc.coding?.[0].display ?? cc.coding?.[0].code
}

const asReference = (resource: ResourceObject): Reference => {
  let display

  if (isPatient(resource) || isPractitioner(resource)) {
    display = humanNameAsString(resource.name?.[0])
  }

  if (isPractitionerRole(resource)) {
    display = codeableConceptAsString(resource.code?.[0])
  }

  if (isOrganization(resource)) {
    display = resource.name
  }

  if (isComposition(resource)) {
    display = resource.title
  }
  if (!display) display = (resource as Reference).display

  return { id: resource.id, resourceType: resource.resourceType, ...(display ? { display } : {}) }
}
type CustomBundle<T> = {
  entry?: T[]
  included?: T[]
  total?: number
}

export {
  getResources,
  getResource,
  getResourcesByTypeAsIndex,
  humanNameAsString,
  getFirstEmail,
  getFirstPhone,
  getAddress,
  codeableConceptAsString,
  asReference,
  getResourcesAsIndex,
  getStringAddressByType,
  getAPIResourcesAsIndex,
}
