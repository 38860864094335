import { faSearch, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { useRef } from "react"

import { Input } from "./Input"

const SearchInput = ({
  isLoading,
  className,
  placeholder = "Search",
  search,
  initialValue,
  autoFocus = true,
}: Props) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const onSearch = (text: string) => {
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => search(text), 300)
  }

  return (
    <div className={classNames("relative block", className)}>
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        <FontAwesomeIcon className="text-slate-400" icon={faSearch} />
      </span>
      <Input
        placeholder={placeholder}
        className="pl-9 pr-3 h-full"
        type={!isLoading ? "search" : undefined}
        onChange={(event) => onSearch(event.currentTarget?.value)}
        autoFocus={autoFocus}
        defaultValue={initialValue}
      />
      <span
        className={classNames("absolute inset-y-0 right-0 flex items-center pr-3", {
          hidden: !isLoading,
        })}
      >
        <FontAwesomeIcon className="text-slate-400" icon={faSpinner} spin />
      </span>
    </div>
  )
}

type Props = {
  isLoading?: boolean
  autoFocus?: boolean
  placeholder?: string
  className?: string
  search(searchText?: string): void
  initialValue?: string
}

export { SearchInput }
