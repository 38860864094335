import { getAddress } from "fhir"

import { AddressField, PractitionerApi } from "commons"
import { useLoginContext } from "security"

import { InformationCard } from "./InformationCard"
import { useUpdatePractitioner } from "practitioners/hooks"
import { APIPractitionerValidationSchema, sanitize } from "./validations"

const AddressInformation = () => {
  const { logedInPractitioner, reloadPractitioner } = useLoginContext()
  const { updatePractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)

  const onSubmit = (practitionerData: PractitionerApi) => {
    updatePractitioner(sanitize(practitionerData))
  }

  return (
    <InformationCard
      title="Address Information"
      data={{
        Address: getAddress(logedInPractitioner.practitioner.address),
      }}
      lineEditData={[logedInPractitioner]}
      initialValue={logedInPractitioner}
      validationSchema={APIPractitionerValidationSchema()}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
    >
      <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
        <AddressField parentFieldName="practitioner.address[0]" context="PractitionerApi" />
      </fieldset>
    </InformationCard>
  )
}

export { AddressInformation }
