import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { Children, ReactElement, useState } from "react"

import { useScreenContext } from "commons"
import { Accordion, AccordionTab } from "primereact/accordion"

const MasterDetailSection = ({ selectedSectionId, onSectionChanged, children }: Props) => {
  const getChildId = (child: ReactElement<ItemProps>) => child?.props?.id ?? ""
  const { isSmallScreen } = useScreenContext()

  const [currentSection, setCurrentSection] = useState<string>(
    getChildId(children.find((child) => !!child.props.id) as ReactElement<ItemProps>),
  )
  const sectionId = selectedSectionId ?? currentSection
  const onChanged = onSectionChanged ?? setCurrentSection
  const [activeIndex, setActiveIndex] = useState<number | number[]>(-1)

  return (
    <div className={classNames("flex flex-1 overflow-hidden ", { "h-full overflow-y-auto": isSmallScreen })}>
      {isSmallScreen ? (
        <div className="overflow-scroll flex flex-col flex-1 pb-20">
          <div className="mb-2">
            <span className="mx-4 font-medium text-md pb-1">Settings</span>
            {children.find((child) => child.props.id === "settings")}
          </div>
          <div>
            <span className="mx-4 font-medium text-md pb-1">Administration</span>
            <Accordion className=" w-full px-2" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
              {Children.map(children, (child, i) =>
                child.props.title && child.props.id !== "settings" ? (
                  <AccordionTab tabIndex={i} header={child.props.title}>
                    {child.props.description && (
                      <div className="text-sm pb-3 text-gray-400">{child.props.description}</div>
                    )}
                    {child}
                  </AccordionTab>
                ) : null,
              )}
            </Accordion>
          </div>
        </div>
      ) : (
        <>
          <div className="w-72 xl:w-96 pl-8 pt-8 border-r overflow-y-auto">
            <ul>
              {Children.map(children, (child) =>
                child.props.title ? (
                  <li
                    className={classNames(
                      "p-3 text-gray-500 cursor-pointer border-l-2 hover:text-primary hover:bg-gray-100",
                      getChildId(child) === sectionId
                        ? "text-primary border-primary bg-gray-50"
                        : " border-transparent",
                    )}
                    onClick={() => onChanged(getChildId(child))}
                  >
                    {child.props.icon && <FontAwesomeIcon icon={child.props.icon} className="mr-2" />}
                    {child.props.title}
                  </li>
                ) : null,
              )}
            </ul>
          </div>
          <div className="flex flex-col flex-1 pl-8 pt-8 pr-3 overflow-hidden">
            {Children.map(children, (child) =>
              child.props.title ? (
                getChildId(child) === sectionId ? (
                  <div className="flex flex-col flex-1 overflow-hidden">
                    <div className={classNames("text-lg", child.props.description ? "pb-1" : "pb-4")}>
                      {child.props.title}
                    </div>
                    {child.props.description && (
                      <div className="text-sm pb-4 text-gray-400">{child.props.description}</div>
                    )}
                    {child}
                  </div>
                ) : null
              ) : (
                child
              ),
            )}
          </div>
        </>
      )}
    </div>
  )
}

const SectionItem = ({ children, className }: ItemProps) => (
  <div className={classNames("flex flex-1", className)}>{children}</div>
)

interface Props {
  selectedSectionId?: string
  onSectionChanged?(section: string): void
  sectionToUrlParam?: string
  children: ReactElement<ItemProps>[]
}

interface ItemProps {
  id?: string
  icon?: IconDefinition
  title: string
  description?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
}

export { MasterDetailSection, SectionItem }
