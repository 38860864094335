import { Organization } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useCreateOrganization = (onSettled: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newOrganization = async (organization: Organization) => create("organizations", organization)

  const { mutate: createOrganization, isPending: isAdding } = useMutation({
    mutationFn: newOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      displayNotificationSuccess("Organization created successfully!")
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createOrganization, isAdding }
}

export { useCreateOrganization }
