import { PractitionerApi } from "commons"
import { createContext, FC, ReactNode } from "react"

import { useLogedInPractitioner } from "user"

const LoginContext = createContext<State | undefined>(undefined)
LoginContext.displayName = "LoginContext"

const LoginProvider: FC<Props> = ({ children }) => {
  const { logedInPractitioner, isAdmin, reloadPractitioner, isLoading, isRootAdmin } = useLogedInPractitioner()

  if (!logedInPractitioner?.practitioner.id || !isAdmin) {
    throw new Error("Unauthorized", {
      cause: {
        name: "401",
        message: !isAdmin
          ? "Sorry , but you don't have permission to access to Admin APP. If you think it is a mistake, contact to support."
          : "Practitioner not found",
      },
    })
  }

  return (
    <LoginContext.Provider
      value={{
        logedInPractitioner,
        logedInPractitionerId: logedInPractitioner.practitioner.id,
        reloadPractitioner,
        isLoading,
        isRootAdmin,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

type State = {
  logedInPractitioner: PractitionerApi
  logedInPractitionerId: string
  reloadPractitioner(): void
  isLoading: boolean
  isRootAdmin: boolean
}

type Props = {
  children: ReactNode
}

export { LoginProvider, LoginContext }
