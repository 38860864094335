import { Bundle, ResourceObject, Parameters } from "fhir"
import { useMemo } from "react"

import { useAuth } from "security"

import { ApiClient } from "../ApiClient"
import { AidboxClient } from "../AidboxClient"

enum IClient {
  "AIDBOX",
  "API",
}

const useClient = (type: IClient = IClient.API) => {
  const { user, logout } = useAuth()

  const client = useMemo(() => {
    switch (type) {
      case IClient.AIDBOX:
        return new AidboxClient(logout, user)
      case IClient.API:
        return new ApiClient(logout, user)
    }
  }, [logout, user, type])

  const operationRequest = <T extends ResourceObject>(
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "PATCH",
    operation: string,
    id?: string,
    parameters?: Parameters,
    filters?: URLSearchParams,
  ) => client.operationRequest<T>(endpoint, method, id, parameters, filters, operation)

  const read = <T>(endpoint: string, id?: string, filters?: URLSearchParams, operation?: string) =>
    client.read<T>(endpoint, id, filters, operation)

  const search = (endpoint: string, filters?: URLSearchParams, operation?: string, signal?: AbortSignal) =>
    client.search(endpoint, filters, operation, signal)

  const create = <T extends ResourceObject>(endpoint: string, resource: T) => client.create(endpoint, resource)

  const update = <T extends ResourceObject>(endpoint: string, id: string, resource: T) =>
    client.update(endpoint, id, resource)

  const patch = <T extends ResourceObject>(endpoint: string, id: string, resource: T) =>
    client.patch(endpoint, id, resource)

  const remove = <T extends ResourceObject>(endpoint: string, id: string) => client.remove<T>(endpoint, id)

  const transaction = (bundle: Bundle) => client.transaction(bundle)

  const uploadFile = (file: File, containerName: string) => client.uploadFile(file, containerName)

  const getSignedUrl = (url: string, signal?: AbortSignal) => client.getSignedUrl(url, signal)

  return { read, search, create, update, patch, remove, transaction, uploadFile, getSignedUrl, operationRequest }
}

export { useClient, IClient }
