import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import { PropsWithChildren, ReactNode } from "react"

import { Button } from "commons"

const InformationCardContainer = ({ title, showEdit, onEdit, customButton, children }: PropsWithChildren<Props>) => (
  <div className="border rounded-lg shadow-sm p-2 ">
    <div className="flex justify-between items-start mb-1">
      <h3 className="flex font-semibold text-lg text-gray-900">{title}</h3>
      {showEdit && (customButton ?? <Button buttonStyle="default" label="Edit" icon={faEdit} onClick={onEdit} />)}
    </div>
    {children}
  </div>
)

type Props = {
  title: string
  showEdit?: boolean
  onEdit?(): void
  customButton?: ReactNode
}

export { InformationCardContainer }
