import { Parameters } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { usersQueryKeys } from "../query-keys"

const useInviteUser = (onSettled: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const invite = async (data: UserInvite) => operationRequest("users/invite", "POST", "", undefined, data as Parameters)

  const { mutate: inviteUser, isPending: isInviting } = useMutation({
    mutationFn: invite,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.userInvitation })
      displayNotificationSuccess(`In order to invite, we are trying to stablish communication via email.`)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.userInvitation })
      onSettled()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { inviteUser, isInviting }
}

export type UserInvite = {
  email: string
  displayName: string
  website: string
}

export { useInviteUser }
