import { useInfiniteQuery } from "@tanstack/react-query"
import { Parameters, PlanDefinition, Reference } from "fhir"
import { useMemo } from "react"

import { IClient, useClient } from "api"
import { getPDSkuValue } from "commons/utils"

import { settingsQueryKeys } from "../../query-keys"
import { AdvanceFilter, LabCombo, LabPanel } from "../../types"

const useLabCombos = (organization: string, filters: AdvanceFilter & { searchText?: string }) => {
  const { operationRequest } = useClient(IClient.AIDBOX)
  const { archived, performerLabs, searchText, hidden } = filters ?? {}
  const queryKey = settingsQueryKeys.labCombos(organization, performerLabs, archived, searchText, hidden)

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const params: Parameters = {
        parameter: [
          ...(performerLabs?.length
            ? performerLabs.map((lab) => {
                return {
                  name: "lab",
                  value: { Reference: { id: lab, resourceType: "Organization" } },
                }
              })
            : []),
          ...(archived !== undefined ? [{ name: "retired", value: { boolean: archived } }] : []),
        ],
        resourceType: "Parameters",
      }

      const filter = new URLSearchParams({
        ...(searchText ? { _filter: searchText } : {}),
        ...(hidden !== undefined ? { _showHidden: `${hidden}` } : {}),
        _count: "20",
        _page: `${pageParam}`,
        _sort: "title",
      })

      const respnse = await operationRequest<Parameters>(
        "Organization",
        "POST",
        "get-combos",
        organization,
        params,
        filter,
      )
      let total = 0
      let next: number | undefined

      const combos = respnse.parameter?.reduce<LabCombo[]>((prev, parameter) => {
        if (parameter.name === "next") {
          next = parameter.value?.integer
        } else if (parameter.name === "total") {
          total = parameter.value?.integer ?? 0
        }

        if (parameter.name === "combo-info") {
          const { combo, price, fee, performerLab, tests } = parameter?.part?.reduce<{
            combo: PlanDefinition
            price: number
            fee: number
            performerLab: Reference
            tests: LabPanel[]
          }>(
            (prevParam, param) => {
              if (param.name === "combo") {
                const pd = param.resource as PlanDefinition
                const sku = getPDSkuValue(pd.identifier) ?? "no sku code"

                return { ...prevParam, combo: pd, display: pd.title ?? "no title", sku }
              }

              if (param.name === "base-price") {
                return { ...prevParam, price: param.value?.decimal ?? 0 }
              }

              if (param.name === "fee") {
                return { ...prevParam, fee: param?.value?.decimal ?? 0 }
              }

              if (param.name === "lab") {
                return { ...prevParam, performerLab: param?.value?.Reference ?? {} }
              }

              return prevParam
            },
            { combo: {} as PlanDefinition, price: 0, fee: 0, performerLab: {}, tests: [] },
          ) ?? { combo: {} as PlanDefinition, price: 0, fee: 0, performerLab: {}, tests: [] }

          return [...prev, { combo, price, fee, performerLab, tests }]
        }

        return [...prev]
      }, [])

      return { combos, next, total }
    },
    meta: { context: { queryKey, organization } },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
  })

  const { combos, count } = useMemo(() => {
    const combos = data?.pages.flatMap((page) => page.combos ?? [])

    return { combos, count: combos?.length }
  }, [data])

  return {
    labCombos: combos ?? [],
    count,
    isLoadingLabCombos: isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}

export { useLabCombos }
