import { classNames } from "primereact/utils"

import chIconLogo from "images/chIconLogo.webp"

const AppIconLogo = ({ showCollapsed, className }: Props) => {
  const fallbackLogo = (
    <>
      <img src={chIconLogo} alt="app logo" className="w-12" />
      {!showCollapsed ? <span className="text-3xl ml-1 mt-1 mr-5"> ChartedHealth</span> : null}
    </>
  )

  return <div className={classNames("flex justify-center w-full h-fit", className)}>{fallbackLogo}</div>
}

type Props = {
  showCollapsed?: boolean
  className?: string
}

export { AppIconLogo }
