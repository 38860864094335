import { FormikValues } from "formik"
import { createContext, PropsWithChildren, useContext } from "react"

import { DialogFormProps, FormContainerProps } from "../form/types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReplaceFormContext = createContext<State<any>>({})

ReplaceFormContext.displayName = "ReplaceFormContext"

const useReplaceFormContext = <T extends FormikValues>() => {
  const context = useContext<State<T>>(ReplaceFormContext)

  if (!Object.keys(context).length) return undefined

  return context
}

export type FormProps<T extends FormikValues> = DialogFormProps<T> & Omit<FormContainerProps<T>, "initialValue">

export type State<T extends FormikValues> = {
  replacementContent?: ReplacementProps<T>
  setReplacementContent?(content?: ReplacementProps<T>): void
  toogleShowReplacementContent?(): void
  setReplacementInitialData?(data?: T): void
  setShowReplacementContent?(show: boolean): void
}

export type ReplacementProps<T extends FormikValues> = PropsWithChildren<Omit<FormProps<T>, "width">>

export { ReplaceFormContext, useReplaceFormContext }
