import { useId, useReducer, useRef, useState } from "react"
import { faBars, faBell } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayPanel } from "primereact/overlaypanel"
import { SubsNotification } from "fhir"
import { classNames } from "primereact/utils"
import { Dialog } from "primereact/dialog"

import {
  SkeletonLoader,
  InfiniteScroll,
  Button,
  useScreenContext,
  SearchWithFilters,
  StackedListContainer,
} from "commons"

import { useResendSubsNotification, useSubsNotifications } from "../hooks"
import { notificationModelBuilder } from "./notificationModelBuilder"
import { SubsNotificationFilters } from "./SubsNotificationFilter"
import { SubsNotificationsListProps } from "../types"

const SubsNotificationsList = ({ showDetails }: Props) => {
  const [showResenSubsNotification, setShowResenSubsNotification] = useState(false)
  const [subsNotification, setSubsNotification] = useState<SubsNotification | undefined>(undefined)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const hideDialog = () => {
    setShowResenSubsNotification(false)
    setSubsNotification(undefined)
  }
  const showResendDialog = (subsnotifiation: SubsNotification) => {
    setShowResenSubsNotification(true)
    setSubsNotification(subsnotifiation)
  }

  const { resenSubsNotification, isSending } = useResendSubsNotification(() => setShowResenSubsNotification(false))
  const { searchText, status, search, filter, reset } = useReducerState()

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)

  const { subsNotifications, count, total, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useSubsNotifications(searchText, status?.map((status) => status).join(","))

  const renderModalFooter = () => (
    <div className="mt-2">
      <Button label="Cancel" disabled={isSending} className="button-default" onClick={() => hideDialog()} />
      <Button
        label="Send"
        className="button-primary"
        onClick={() => {
          resenSubsNotification(subsNotification?.id ?? "")
        }}
        loading={isSending}
      />
    </div>
  )

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      <div className="px-6 border-b shadow-sm">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">SubsNotifications</h6>
            <p className="text-sm text-gray-500">
              Showing {count} notifications of {total} found
            </p>
          </div>
        </div>
        <div className="flex py-4 w-full justify-between">
          <div className={classNames("flex mr-2  ", { "w-full": isSmallScreen })}>
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              onTextFilter={search}
              isLoading={isLoading || isFetchingNextPage}
              filterNone={!status}
              formContent={
                <SubsNotificationFilters
                  initialValues={{ status }}
                  onSearch={(filters) => {
                    filter(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    reset()
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        loader()
      ) : !subsNotifications?.length ? (
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex flex-col items-center">
            <FontAwesomeIcon icon={faBell} size="3x" className="text-gray-400" />
            <h6 className="font-semibold text-xl pt-4 pb-2">No SubsNotifications</h6>
          </div>
        </div>
      ) : (
        <div className="flex flex-col overflow-auto grow ml-2">
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage?.()
            }}
            hasMore={hasNextPage}
            useWindow={false}
            loader={loader()}
            initialLoad={false}
          >
            <StackedListContainer
              itemPadding
              data={subsNotifications}
              itemModelBuilder={(item) => notificationModelBuilder(item, showDetails, showResendDialog)}
            />
          </InfiniteScroll>

          <Dialog
            header="Confirmation"
            closable={false}
            draggable={false}
            visible={showResenSubsNotification}
            className="w-full md:w-[70%] lg:w-[30%] m-2 "
            onHide={() => setShowResenSubsNotification(false)}
            footer={renderModalFooter}
          >
            <span>Are you sure you want to resend this notification?</span>
          </Dialog>
        </div>
      )}
    </div>
  )
}

const initialState: SubsNotificationsListProps = {
  searchText: "",
  status: undefined,
}

const reducer = (
  state: SubsNotificationsListProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload: string | string[] | SubsNotificationsListProps },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as string[]) }

    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, status }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset", payload: {} })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: SubsNotificationsListProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, status, filter, search, reset }
}
export type FilterProps = {
  status?: string[]
}

type Props = {
  showDetails(subnotification: SubsNotification): void
}

export { SubsNotificationsList }
