import { Parameters } from "fhir"
import { useMutation } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useAuthorizeBasisTheorySession = () => {
  const { operationRequest } = useClient(IClient.AIDBOX)

  const authorizeBT = async ({
    nonce,
    organizationId,
  }: {
    nonce: string
    organizationId: string
  }): Promise<AuthorizeResponse> => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "nonce",
          value: { string: nonce },
        },
      ],
    }

    const responseParameters = await operationRequest<Parameters>(
      `Organization/${organizationId}`,
      "POST",
      "bt-authorize",
      undefined,
      parameters,
    )

    const container = responseParameters.parameter?.find(({ name }) => name === "container")?.value?.string ?? ""

    return { container }
  }

  const { mutateAsync: authorize, isPending: isAuthorizing } = useMutation({
    mutationFn: authorizeBT,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { authorize, isAuthorizing }
}

type AuthorizeResponse = {
  container: string
}

export { useAuthorizeBasisTheorySession }
