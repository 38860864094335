import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { Identifier } from "fhir"
import { faSearch } from "@fortawesome/pro-light-svg-icons"

import {
  Button,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InformationCardContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { useLoginContext } from "security"
import { defaultEditRemoveMenu } from "utils-components"
import { IdentifierForm, identifierInitialValues } from "identifier"

import { useUpdatePractitioner } from "practitioners/hooks"
import { sanitize } from "./validations"

const IdentifiersCard = () => {
  const { logedInPractitioner, reloadPractitioner } = useLoginContext()
  const { showSlide, initialValue, deleteIndex, add, reset, setDeleteIndex } = useCrudReducer({
    defaultEntity: identifierInitialValues,
  })
  const { identifier } = logedInPractitioner.practitioner

  const { updatePractitioner, isUpdating } = useUpdatePractitioner(() => {
    reloadPractitioner()
    reset()
  })

  const onSubmit = (newIdentifier: Identifier) => {
    let newIdentifiers = logedInPractitioner.practitioner.identifier ?? []
    newIdentifiers = [...newIdentifiers, newIdentifier]
    const editPract = logedInPractitioner
    editPract.practitioner.identifier = newIdentifiers
    updatePractitioner(sanitize(editPract))
  }

  const onDelete = () => {
    const newIdentifiers = [...(identifier ?? [])]
    newIdentifiers.splice(deleteIndex as number, 1)
    const editPract = logedInPractitioner
    editPract.practitioner.identifier = newIdentifiers
    updatePractitioner(sanitize(editPract))
  }

  return (
    <InformationCardContainer
      title="Identifiers"
      showEdit={!!identifier?.length}
      customButton={<Button buttonStyle="default" label="Add new" icon={faPlus} onClick={add} />}
    >
      {isUpdating ? (
        <SkeletonLoader repeats={2} loaderType="two-lines" />
      ) : (
        <DataContainerSlideoverForm
          hasData={!!identifier?.length}
          showSlide={showSlide}
          formTitle="Identifiers"
          iconDataNotFound={faSearch}
          formInitialValue={initialValue}
          onSubmit={onSubmit}
          onCancel={reset}
          form={<IdentifierForm />}
          // showAddButton={false}
          onButtonAddClick={add}
          showFab={false}
        >
          <StackedListContainer
            data={identifier ?? []}
            itemModelBuilder={(item, index) => identifierModel(item, () => setDeleteIndex(index))}
          />
          <ConfirmDialog
            confirmText="Are you sure you want to remove this identifier?"
            actionName="Remove"
            visible={deleteIndex !== undefined}
            onConfirm={onDelete}
            hideDialog={() => setDeleteIndex(undefined)}
          />
        </DataContainerSlideoverForm>
      )}
    </InformationCardContainer>
  )
}

const identifierModel = (identifier: Identifier, onDelete: () => void): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Value", value: identifier.value }],
    },
    {
      lineItems: [
        { name: "System", value: identifier.system },
        {
          name: "Period",
          value: `${identifier.period?.start ?? ""}-${identifier.period?.end ?? "No period provided"}`,
        },
      ],
    },
  ],
  menu: defaultEditRemoveMenu(undefined, onDelete),
})

export { IdentifiersCard }
