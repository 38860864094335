import { ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { PractitionerApi, CustomError } from "commons"

import { practitionerQueryKeys } from "../query-keys"

const useUpdatePractitioner = (onSuccess: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updatedPractitioner = async (practitioner: PractitionerApi) =>
    update<PractitionerApi & ResourceObject>("practitioners", practitioner?.practitioner?.id as string, practitioner)

  const { mutate: updatePractitioner, isPending: isUpdating } = useMutation({
    mutationFn: updatedPractitioner,

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.all })
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.all })
      onSuccess()
      displayNotificationSuccess("Practitioner updated successfully!")
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updatePractitioner, isUpdating }
}

export { useUpdatePractitioner }
