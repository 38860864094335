import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters, Reference } from "fhir"

import { CustomError } from "commons"
import { IClient, useClient } from "api"
import { registerErrorTrace } from "logger"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"

import { practitionerQueryKeys } from "../query-keys"

const usePractitionerLabSetup = (practId: string, onSettled?: () => void) => {
  const { operationRequest } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const labSetup = async ({ value, organization }: LabSetupType) => {
    const parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "account-number",
          value: {
            string: value,
          },
        },
        {
          name: "assigner",
          value: {
            Reference: organization,
          },
        },
        {
          name: "send-email",
          value: {
            boolean: false,
          },
        },
        {
          name: "tenant",
          value: {
            Reference: {
              resourceType: "Organization",
              id: "t-e3844b610d467f5623bbad45",
            },
          },
        },
      ],
    }

    return await operationRequest(`Practitioner/${practId}`, "POST", "lab-setup", undefined, parameter)
  }

  const { mutate: practitionerLabSetup, isPending } = useMutation({
    mutationFn: labSetup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.list() })
      displayNotificationSuccess(`Lab setyp success.`)
    },
    onSettled: async () => {
      onSettled?.()
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.list() })
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { practitionerLabSetup, isPending }
}

type LabSetupType = {
  value?: string
  organization?: Reference
}

export { usePractitionerLabSetup }
