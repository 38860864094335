import { useSuspenseQuery } from "@tanstack/react-query"
import { Practitioner, getResources, isPractitioner, isUser } from "fhir"

import { IClient, useClient } from "api"
import { useAuth } from "security"
import { PractitionerApi } from "commons"

import { userQueryKeys } from "../query-keys"

const useLogedInPractitioner = () => {
  const { search } = useClient(IClient.AIDBOX)
  const { read } = useClient(IClient.API)
  const { user, setLinkedResource } = useAuth()
  const queryKey = userQueryKeys.loggedIn(user?.linkedUser.id)

  const { data, isLoading, error, refetch } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      let practitioners: Practitioner[] = []
      let loggedPractitioner: PractitionerApi | undefined = undefined

      if (isUser(user?.linkedUser)) {
        const filters = new URLSearchParams({
          id: user?.linkedUser.id ?? "",
          _include: "link:Practitioner",
        })

        const bundle = await search("User", filters)
        practitioners = getResources<Practitioner>(bundle, "Practitioner")

        if (!practitioners.length) {
          throw new Error("Not found", {
            cause: {
              name: "404",
              message: `Not found a practitioner linked to logged in user ${
                user?.name ?? "unspecified name"
              } with email ${user?.email ?? "unspecified email"}`,
            },
          })
        } else if (practitioners.length > 1) {
          throw new Error("Forbidden", {
            cause: {
              name: "403",
              message: `Logged in user ${user?.name ?? "unspecified name"} with email ${
                user?.email ?? "unspecified email"
              } has several practitioners linked. This isn't allowed for data integrity and safety.`,
            },
          })
        }

        loggedPractitioner = await read<PractitionerApi>("practitioners", practitioners[0].id)
      } else if (isPractitioner(user?.linkedUser)) {
        loggedPractitioner = await read<PractitionerApi>("practitioners", user?.linkedUser.id)
      }

      if (!loggedPractitioner?.practitioner) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: `Not found a practitioner linked to logged in user ${
              user?.name ?? "unspecified name"
            } with email ${user?.email ?? "unspecified email"}`,
          },
        })
      }

      const isAdmin = loggedPractitioner.roles?.some(({ code }) => code?.[0].coding?.[0].code === "admin") ?? false
      const isRootAdmin =
        loggedPractitioner.roles?.some(({ organization }) => organization?.id === "evexias-health-solutions") ?? false

      setLinkedResource(loggedPractitioner.practitioner)
      return { practitioner: loggedPractitioner, isAdmin, isRootAdmin }
    },
    meta: { context: { queryKey, user } },
  })

  return {
    logedInPractitioner: data?.practitioner as PractitionerApi,
    isAdmin: data?.isAdmin,
    isRootAdmin: data?.isRootAdmin,
    isLoading,
    error,
    reloadPractitioner: refetch,
  }
}

export { useLogedInPractitioner }
