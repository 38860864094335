import { InputSwitch } from "primereact/inputswitch"
import { SettingItemArray } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVials } from "@fortawesome/pro-light-svg-icons"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"

import { useOrganizationLabLocation } from "organizations"

const OrderLabsWithLocationSetting = ({ setting, isDisabled, onChange }: Props) => {
  const { locations } = useOrganizationLabLocation(setting.value?.Reference?.id as string)

  return (
    <div className="border-b p-3 inline-flex items-center space-x-5 last:border-b-0 w-full relative overflow-hidden">
      <div className="flex items-center flex-1">
        <FontAwesomeIcon icon={faVials} size="2x" className="w-8 text-gray-400" />
        <span className="capitalize ml-2">
          {setting.code?.display?.toLocaleLowerCase().replace("enabled", "") || "Unspecified"}
        </span>
      </div>
      <InputSwitch
        className="button-primary"
        disabled={isDisabled || !setting.value?.Reference?.id}
        checked={setting.value?.boolean ?? false}
        onChange={(e) => {
          onChange(setting.code?.code as string, {
            ...setting,
            value: {
              ...setting.value,
              boolean: e.value,
              ...(locations[0]?.id ? { uri: "Location/" + locations[0].id } : {}),
            },
          })
        }}
      />
      <div className="inline-flex justify-between items-center gap-2">
        <label
          htmlFor="locations-select"
          className={classNames("font-medium text-sm", { "text-gray-400": !setting.value?.boolean })}
        >
          Location
        </label>
        <Dropdown
          inputId="locations-select"
          className="p-inputtext-sm w-44"
          options={locations}
          value={setting.value?.uri?.replace("Location/", "")}
          optionValue="id"
          optionLabel="name"
          disabled={!setting.value?.boolean || isDisabled}
          onChange={(e) =>
            onChange(setting.code?.code as string, {
              ...setting,
              value: { ...setting.value, uri: "Location/" + e.value },
            })
          }
        />
      </div>
    </div>
  )
}

type Props = {
  setting: SettingItemArray
  isDisabled: boolean
  onChange(code: string, item: SettingItemArray): void
}

export { OrderLabsWithLocationSetting }
