import { PropsWithChildren } from "react"
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FieldWithClearInputAction = ({ showClear, clearAction, children }: PropsWithChildren<Props>) => (
  <div className="flex items-center p-input-icon-right w-full">
    {showClear && <ClearInputAction className="right-8" onClick={clearAction} />}
    {children}
  </div>
)

const ClearInputAction: React.FC<{ className?: string; onClick: () => void }> = ({
  className = "right-2",
  onClick,
}: {
  className?: string
  onClick: () => void
}) => (
  <div
    title="Clear filter"
    className={`absolute translate-y-4 -translate-x-2 cursor-pointer h-2/4 mb-8 flex items-end z-10 ${className}`}
  >
    <FontAwesomeIcon icon={faTimesCircle} className="text-md" onClick={onClick} />
  </div>
)

type Props = {
  showClear?: boolean
  clearAction(): void
}

export { FieldWithClearInputAction }
