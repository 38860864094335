import { humanNameAsString } from "fhir/utils"
import { format, parseISO } from "date-fns"

import { AvatarImage, BirthdateField, GenderField, InputField, PractitionerApi } from "commons"
import { formatsByTypes } from "data"
import { useLoginContext } from "security"

import { InformationCard } from "./InformationCard"
import { ProfileImageField } from "./ProfileImageField"
import { useUpdatePractitioner } from "practitioners/hooks"
import { APIPractitionerValidationSchema, sanitize } from "./validations"

const BasicInformation = () => {
  const { logedInPractitioner, reloadPractitioner } = useLoginContext()
  const { updatePractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)

  const formattedBirthday = logedInPractitioner.practitioner.birthDate
    ? format(parseISO(logedInPractitioner.practitioner.birthDate), formatsByTypes.LONG_DATE)
    : "Unknown"

  const onSubmit = (practitionerData: PractitionerApi) => updatePractitioner(sanitize(practitionerData))

  return (
    <InformationCard
      title="Basic Information"
      data={{
        Photo: (
          <div className="relative w-12 h-12 lg:w-16 lg:h-16 rounded-full overflow-hidden">
            <AvatarImage
              photoUrl={logedInPractitioner.practitioner.photo?.[0]?.url}
              name={humanNameAsString(logedInPractitioner.practitioner.name?.[0])}
              className="w-12 h-12 lg:w-16 lg:h-16"
            />
          </div>
        ),
        Name: humanNameAsString(logedInPractitioner.practitioner.name?.[0]),
        Birthdate: formattedBirthday,
        Gender: logedInPractitioner.practitioner.gender ?? "Unknown",
      }}
      initialValue={logedInPractitioner}
      validationSchema={APIPractitionerValidationSchema("practitioner")}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
    >
      <div className="2xl:flex 2xl:flex-row-reverse gap-2 space-y-2 ">
        <ProfileImageField />
        <div className="flex flex-col flex-1 gap-2">
          <InputField field="practitioner.name[0].given[0]" label="First Name" />
          <InputField field="practitioner.name[0].given[1]" label="Middle Name" />
          <InputField field="practitioner.name[0].family" label="Last Name" className="col-span-2" />
          <InputField field="practitioner.name[0].prefix[0]" label="Prefix" />
          <InputField field="practitioner.name[0].suffix[0]" label="Sufix" />
          <BirthdateField field="practitioner.birthDate" label="Birthdate" />
          <GenderField field="practitioner.gender" label="Biological Sex" />
        </div>
      </div>
    </InformationCard>
  )
}

export { BasicInformation }
