import { Parameters } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { usersQueryKeys } from "../query-keys"

const useChangeEmail = (onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const changeEmail = async ({ email, newEmail }: Props) => {
    return operationRequest("Users/change-email", "POST", "", undefined, {
      email: email,
      newEmail: newEmail,
    } as Parameters)
  }

  const { mutate: changeUserEmail, isPending: isChangingEmail } = useMutation({
    mutationFn: changeEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      displayNotificationSuccess("Changed email successfully")
    },
    onSettled: () => {
      onSettled?.()
      queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
    },
    onError: (error: CustomError) => {
      displayNotificationError(error)
    },
  })

  return { changeUserEmail, isChangingEmail }
}

type Props = {
  email: string
  newEmail: string
}

export { useChangeEmail }
