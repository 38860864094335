import { Organization } from "fhir"
import InfiniteScroll from "react-infinite-scroller"
import { faBars, faPlus, faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useId, useReducer, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { classNames } from "primereact/utils"
import { Dialog } from "primereact/dialog"
import { OverlayPanel } from "primereact/overlaypanel"

import { Button, GroupedList, SearchWithFilters, SkeletonLoader, StackedListItem, useScreenContext } from "commons"

import { OrganizationApi, OrganizationListState } from "../types"
import { useActiveDeactiveOrganization, useOrganizations } from "../hooks"
import { OrganizationEmptyState } from "./OrganizationEmptyState"
import { OrganizationFilters } from "./OrganizationFilters"
import { orgModelBuilder } from "./orgModelBuilder"
import { OrganizationOnboardModal } from "./OrganizationOnboardModal"
import { OrganizationPaymentModal } from "./OrganizationPaymentModal"

const OrganizationtList: React.FC<Props> = ({ newOrganization, edit }: Props) => {
  const { searchText, active, search, filter, reset } = useReducerState()

  const { organizationGroups, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } =
    useOrganizations(searchText, active)

  const [showPaymentDialog, setShowPaymentDialog] = useState(false)

  const [showEnableDisableConfirm, setShowEnableDisableConfirm] = useState(false)
  const [showOnboardDialog, setShowOnboardDialog] = useState(false)
  const [org, setOrg] = useState<OrganizationApi | undefined>(undefined)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()

  const hidePaymentDialog = () => {
    setShowPaymentDialog(false)
    setOrg(undefined)
  }

  const hideEnableDisableConfirm = () => {
    setShowEnableDisableConfirm(false)
    setOrg(undefined)
  }
  const navigate = useNavigate()

  const viewAdministration = (org: Organization) => {
    navigate(`/organizations/${org.id}/administration`)
  }

  const { isUpdating, updateOrganizationStatus } = useActiveDeactiveOrganization(hideEnableDisableConfirm)

  const handleShowStatusDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowEnableDisableConfirm(true)
  }

  const changeStatus = useCallback(() => {
    if (org?.organization) {
      updateOrganizationStatus(org.organization)
    }
  }, [org, updateOrganizationStatus])

  const handleShowPaymentDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowPaymentDialog(true)
  }

  const handleShowOnboardDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowOnboardDialog(true)
  }

  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      <div className="px-6 border-b shadow-sm">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">Organizations</h6>
            <p className="text-sm text-gray-500">
              Showing {count} organizations of {total} found
            </p>
          </div>
        </div>

        <div className="flex py-4 w-full justify-between">
          <div className="flex mr-2">
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              onTextFilter={search}
              isLoading={isLoading || isFetchingNextPage}
              filterNone={!active}
              formContent={
                <OrganizationFilters
                  initialValues={{ active }}
                  onSearch={(filters) => {
                    filter(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    reset()
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>

          <Button
            label={isSmallScreen ? "Add" : "Add organization"}
            icon={isSmallScreen ? faPlus : undefined}
            className="button-primary sm:text-sm"
            onClick={newOrganization}
          />
        </div>
      </div>
      {isLoading ? (
        loader()
      ) : !organizationGroups?.length ? (
        <OrganizationEmptyState onAdd={newOrganization} />
      ) : (
        <div className="flex flex-col overflow-auto grow mb-5 ">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <GroupedList
              className="grow"
              groups={organizationGroups}
              renderItem={(organizationApi) => {
                return (
                  <StackedListItem
                    itemPadding
                    modelData={orgModelBuilder(
                      organizationApi,
                      () => edit(organizationApi.organization),
                      handleShowStatusDialog,
                      handleShowOnboardDialog,
                      handleShowPaymentDialog,
                      viewAdministration,
                    )}
                  />
                )
              }}
              renderDivider={(name) => (
                <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                  <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                </div>
              )}
              renderEmptyState={() => (
                <div className="flex flex-col items-center justify-center pt-10">
                  <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                  <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                </div>
              )}
            />
          </InfiniteScroll>
          {showPaymentDialog && (
            <OrganizationPaymentModal onCancel={hidePaymentDialog} show={showPaymentDialog} org={org?.organization} />
          )}

          <Dialog
            header="Confirmation"
            closable={true}
            draggable={false}
            visible={showEnableDisableConfirm}
            className="w-full md:w-[70%] lg:w-[30%] m-2 "
            onHide={hideEnableDisableConfirm}
            footer={
              <div className="mt-2">
                <Button
                  label="Cancel"
                  disabled={isUpdating}
                  className="button-default"
                  onClick={hideEnableDisableConfirm}
                />
                <Button label="Accept" className="button-primary" onClick={changeStatus} loading={isUpdating} />
              </div>
            }
          >
            <span>Are you sure you want to change the organization status?</span>
          </Dialog>

          {showOnboardDialog && (
            <OrganizationOnboardModal
              organization={org?.organization}
              show={showOnboardDialog}
              onCancel={() => {
                setShowOnboardDialog(false)
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}

const initialState: OrganizationListState = {
  searchText: "",
  active: "",
}

const reducer = (
  state: OrganizationListState,
  { type, payload }: { type: "reset" | "search" | "filter"; payload: string | OrganizationListState },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as OrganizationListState) }

    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, active }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset", payload: {} as OrganizationListState })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: OrganizationListState) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, active, filter, search, reset }
}

type Props = {
  newOrganization(): void
  edit(organization: Organization): void
}

export { OrganizationtList }
