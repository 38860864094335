import InfiniteScroll from "react-infinite-scroller"
import { faBars, faPaperPlane } from "@fortawesome/pro-light-svg-icons"
import { useCallback, useId, useReducer, useRef, useState } from "react"
import { OverlayPanel } from "primereact/overlaypanel"
import { User } from "fhir/fhir-extended"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Sidebar } from "primereact/sidebar"

import {
  ConfirmDialog,
  SkeletonLoader,
  StackedListContainer,
  Button,
  useScreenContext,
  LoadingView,
  EmailCheckProvider,
  EmailCheckContext,
  DialogFormContainer,
  SearchWithFilters,
} from "commons"

import { Auth2faMethods, DialogTitle, FilterProps } from "../types"
import { UsersFilters } from "./UsersFilters"
import { userModelBuilder } from "./userModelBuilder"
import { UsersEmptyState } from "./UserEmptyState"
import {
  useActiveDeactiveUser,
  useRequestResetPassword,
  useUsers,
  useRemove2faPhoneMethod,
  useRemove2faSoftwareMethod,
  useChangeEmail,
  useInviteUser,
  useChangePassword,
  useUsersReducer,
} from "../hooks"
import { UserDetailView } from "./UserDetailView"
import { SetUserEmail } from "../utils"

const UserList = () => {
  const { searchText, status, after, before, search, filter, reset } = useReducerState()
  const {
    showDialog,
    user,
    dialogTitle,
    content,
    initialValue,
    validateSchema,
    usersReset,
    setUser,
    handleInviteUser,
    handleEditEmail,
    handleChangePassword,
  } = useUsersReducer()

  const { users, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useUsers(
    searchText,
    status,
    after,
    before,
  )
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const [showRequestResetPasswordDialog, setShowRequestResetPasswordDialog] = useState(false)
  const [auth2faDialog, setAuth2faDialog] = useState(Auth2faMethods.NONE)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const [showOverlayForm, setShowOverlayForm] = useState(false)

  const onCancel = () => {
    setShowActiveDeactiveDialog(false)
    setShowRequestResetPasswordDialog(false)
    setAuth2faDialog(Auth2faMethods.NONE)
    usersReset()
  }
  const { changeUserEmail, isChangingEmail } = useChangeEmail(onCancel)
  const { inviteUser, isInviting } = useInviteUser(onCancel)
  const { changePassword, isChangingPassword } = useChangePassword(onCancel)

  const changeEmail = (data: SetUserEmail) => {
    changeUserEmail(data)
  }

  const closeForm = () => {
    setUser(undefined)
    setShowOverlayForm(false)
  }

  const { activeDeactiveUser, isChangingStatus } = useActiveDeactiveUser(onCancel)
  const { requestResetPassword, isRequesting } = useRequestResetPassword(onCancel)
  const { remove2faPhone, isRemoving2faPhone } = useRemove2faPhoneMethod(onCancel)
  const { remove2faSoftware, isRemoving2faSoftware } = useRemove2faSoftwareMethod(onCancel)

  const handleChangeStatus = useCallback(() => {
    if (user) {
      activeDeactiveUser(user)
    }
  }, [user, activeDeactiveUser])

  const handleShowStatusDialog = (user: User) => {
    setUser(user)
    setShowActiveDeactiveDialog(true)
  }

  const handleRequestResetPassword = useCallback(() => {
    if (user) {
      requestResetPassword(user)
    }
  }, [user, requestResetPassword])

  const handleShowRequestResetPasswordDialog = (user: User) => {
    setUser(user)
    setShowRequestResetPasswordDialog(true)
  }
  const showUserDetails = (user: User) => {
    setUser(user)
    setShowOverlayForm(true)
  }

  const handleShow2faMethodDialog = (user: User, method: Auth2faMethods) => {
    setUser(user)
    setAuth2faDialog(method)
  }
  const customSidebarTitlebar = (
    <span className="bg-white w-full flex items-center space-x-2">
      <h6 className="font-semibold">User details</h6>
    </span>
  )

  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      <div className="px-6 border-b shadow-sm">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">Users</h6>
            <p className="text-sm text-gray-500">Platform users</p>
          </div>
        </div>
        <div className="flex py-4 w-full justify-between">
          <div className="flex mr-2">
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              onTextFilter={search}
              isLoading={isLoading || isFetchingNextPage}
              filterNone={!status && !after && !before}
              formContent={
                <UsersFilters
                  initialValues={{ status, after, before }}
                  onSearch={(filters) => {
                    filter(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    reset()
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>

          <Button
            label={isSmallScreen ? "Invite" : "Invite user"}
            icon={isSmallScreen ? faPaperPlane : undefined}
            className="button-primary"
            onClick={handleInviteUser}
          />
        </div>
      </div>

      {isLoading ? (
        loader()
      ) : !users?.length ? (
        <>
          <UsersEmptyState />
        </>
      ) : (
        <div className="flex flex-col overflow-auto grow">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <StackedListContainer
              itemPadding
              data={users}
              itemModelBuilder={(item) =>
                userModelBuilder(
                  item,
                  showUserDetails,
                  handleShowStatusDialog,
                  handleShowRequestResetPasswordDialog,
                  handleChangePassword,
                  handleShow2faMethodDialog,
                  handleEditEmail,
                )
              }
            />
          </InfiniteScroll>
          <Sidebar
            visible={showOverlayForm}
            position={isSmallScreen ? "bottom" : "right"}
            style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
            header={customSidebarTitlebar}
            onHide={closeForm}
          >
            {isLoading ? (
              <LoadingView />
            ) : (
              <div className="relative h-full pt-5 w-full">{user && <UserDetailView {...user} />}</div>
            )}
          </Sidebar>
        </div>
      )}
      <Dialog
        header="Change status"
        visible={showActiveDeactiveDialog}
        className="w-full md:w-[70%] lg:w-[30%] m-2"
        onHide={onCancel}
        footer={
          <div className="mt-2">
            <Button label="Cancel" disabled={isChangingStatus} className="button-default" onClick={onCancel} />
            <Button
              label="Accept"
              className="button-primary"
              loading={isChangingStatus}
              disabled={isChangingStatus}
              onClick={handleChangeStatus}
            />
          </div>
        }
      >
        <span>Do you want to change account status to {user?.displayName}?</span>
      </Dialog>
      <ConfirmDialog
        confirmText="Are you sure you want to remove this 2FA method?"
        visible={auth2faDialog !== Auth2faMethods.NONE || isRemoving2faPhone || isRemoving2faSoftware}
        isLoading={isRemoving2faPhone}
        actionName="Remove"
        onConfirm={() => {
          if (user) auth2faDialog === Auth2faMethods.PHONE ? remove2faPhone(user) : remove2faSoftware(user)
        }}
        hideDialog={() => setAuth2faDialog(Auth2faMethods.NONE)}
      />
      <Dialog
        header="Request reset password"
        visible={showRequestResetPasswordDialog}
        className="w-full md:w-[70%] lg:w-[30%] m-2 "
        onHide={onCancel}
        footer={
          <div className="mt-2">
            <Button label="Cancel" disabled={isRequesting} className="button-default" onClick={onCancel} />
            <Button
              label="Accept"
              className="button-primary"
              loading={isRequesting}
              disabled={isRequesting}
              onClick={handleRequestResetPassword}
            />
          </div>
        }
      >
        <span>
          Do you want to request reset password for <span className="font-semibold">{user?.displayName}</span>?
        </span>
      </Dialog>
      <EmailCheckProvider>
        <EmailCheckContext.Consumer>
          {({ isEqualToInitialValue }) => (
            <DialogFormContainer
              title={dialogTitle}
              onCancel={onCancel}
              onSubmit={
                dialogTitle === DialogTitle.inviteUser
                  ? () => inviteUser
                  : dialogTitle === DialogTitle.editEmail
                    ? () => changeEmail
                    : () => changePassword
              }
              showForm={showDialog || isChangingEmail || isChangingPassword || isInviting}
              initialValue={initialValue}
              disableSave={isEqualToInitialValue}
              validationSchema={validateSchema}
            >
              {content}
            </DialogFormContainer>
          )}
        </EmailCheckContext.Consumer>
      </EmailCheckProvider>
    </div>
  )
}

const initialState = {
  searchText: "",
  status: "",
  after: "",
  before: "",
} as FilterProps

const reducer = (
  state: FilterProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload?: string | FilterProps },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as FilterProps) }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, status, before, after }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: FilterProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, status, after, before, filter, search, reset }
}

export { UserList }
