import { useMemo } from "react"
import { getResources, SubsNotification } from "fhir"
import { useInfiniteQuery } from "@tanstack/react-query"

import { useClient, IClient } from "api"

import { subsnotificationQueryKeys } from "../query-keys"

const useSubsNotifications = (filter = "", status?: string) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = subsnotificationQueryKeys.list(filter, status)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    SubNotificationQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filters = new URLSearchParams({
        ...(filter ? { _ilike: filter } : {}),
        ...(status ? { _status: status } : {}),
        _sort: "-createdAt",
        _count: "20",
        _page: `${pageParam}`,
      })

      const bundle = await search("SubsNotification", filters)

      const subsNotifications = getResources(bundle) as SubsNotification[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { subsNotifications, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, filter, status } },
  })
  const { subsNotifications, count } = useMemo(() => {
    const temp = data?.pages.flatMap((page) => page.subsNotifications)
    const count = temp?.length ?? 0

    return {
      subsNotifications: temp,
      count,
    }
  }, [data?.pages])

  return {
    subsNotifications,
    count,
    isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type SubNotificationQueryData = { subsNotifications: SubsNotification[]; next: number | undefined; total: number }

export { useSubsNotifications }
