import { faBarcode, faHouseBuilding } from "@fortawesome/pro-regular-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, codeableConceptAsString } from "fhir"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { FC, useId, useMemo, useReducer } from "react"

import { DropdownMenu, InfiniteScroll, SkeletonLoader } from "commons"
import { MEDICATION_CATALOG } from "data"
import { useOrganizationContext } from "organizations"
import { getCommonCode, sumPrice } from "utils"

import { useMksByCategory } from "../../hooks"
import { MedItem, MedicationsAdvanceFilter } from "../../types"
import { MedicationFormContainer } from "./MedicationFormContainer"

const MedicationsRxs: FC<Props> = ({ filters, isLoadingCatalogs }) => {
  const { currentOrganizationId } = useOrganizationContext()

  const { medsWithCID, isLoading, hasNextPage, fetchNextPage } = useMksByCategory(
    currentOrganizationId,
    MEDICATION_CATALOG.RX,
    filters,
    !isLoadingCatalogs,
  )

  const { edit, showForm, reset, med } = useReducerState()

  const medDatas = useMemo(
    () =>
      medsWithCID.map((med) => {
        const { num1: price, num2: fee, sum: total } = sumPrice(med.price, med.fee)
        const { mk } = med
        const sku = getCommonCode({ codes: mk.code?.coding })
        const display = codeableConceptAsString(mk.code)
        const catalog = mk.catalogHeader?.[0]?.display ?? ""

        return {
          medData: { display, sku, catalog, mk },
          price: price.toNumber(),
          fee: fee.toNumber(),
          total: total.toFixed(2),
          externalAction: [
            {
              label: "Edit",
              icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
              command: () => edit({ ...med, catalog, display, sku }),
            },
          ],
        }
      }),
    [medsWithCID],
  )

  const medDetailsBodyTemplate = ({
    medData,
  }: {
    medData: {
      display: string
      catalog: string
      sku: string
      mk: MedicationKnowledge
    }
  }) => {
    const strength = medData.mk?.ingredient?.[0]?.strength?.numerator?.unit

    const header = (
      <>
        {medData.catalog && (
          <p
            title="Medication"
            className="mr-2 flex items-baseline gap-2 font-medium truncate max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-full"
          >
            <span className="min-w-0 truncate" title={medData.display}>
              {medData.display}
            </span>
            {strength && (
              <span className="min-w-0 text-gray-500 truncate" title={strength}>
                {strength}
              </span>
            )}
          </p>
        )}
      </>
    )

    const details = (
      <>
        {medData.catalog && (
          <span title="catalog" className="flex items-center">
            <FontAwesomeIcon icon={faHouseBuilding} className="mr-1.5 text-gray-400" />
            {medData.catalog}
          </span>
        )}
        {medData.sku && (
          <span title="code" className="flex items-center">
            <FontAwesomeIcon icon={faBarcode} className="mr-1.5 ml-1.5 text-gray-400" />
            {medData.sku}
          </span>
        )}
        {medData?.mk?.doseForm?.coding?.[0]?.display && (
          <span title="form" className="flex items-center pl-1.5">
            {`Dose form: ${medData?.mk?.doseForm?.coding?.[0]?.display}`}
          </span>
        )}
        {medData.mk?.packaging?.type?.coding?.[0]?.display && medData.mk?.packaging?.quantity?.value && (
          <span title="packaging" className="flex items-center pl-1.5">
            {`Pkg: ${medData.mk?.packaging?.type?.coding?.[0]?.display}/${medData?.mk?.packaging?.quantity?.value}`}
          </span>
        )}
      </>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center w-full">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                <div className="mt-2 flex">
                  <div className="flex items-center text-xs text-gray-400 divide-x divide-gray-400 gap-2 space-around">
                    {details}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  const externalActionBodyTemplate = ({
    externalAction,
  }: {
    externalAction: { title: string; icon: JSX.Element; command: () => void }[]
  }) => {
    return (
      <div className="flex items-center justify-between">
        {externalAction.length > 1 ? (
          <DropdownMenu dropdownMenuItems={externalAction} />
        ) : (
          <button
            key={1}
            title={externalAction?.[0]?.title}
            onClick={externalAction?.[0]?.command}
            className="flex items-center justify-center p-1 text-gray-400 rounded-md cursor-pointer"
          >
            {externalAction?.[0]?.icon}
          </button>
        )}
      </div>
    )
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
        <DataTable value={medDatas} loading={isLoading || isLoadingCatalogs}>
          <Column field="medData" body={medDetailsBodyTemplate} rowSpan={6} headerClassName="bg-transparent" />
          <Column
            field="price"
            header="Price"
            bodyClassName="text-gray-400"
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column
            field="fee"
            header="Fee"
            bodyClassName="text-gray-400"
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column
            field="total"
            header="Total"
            bodyClassName="text-gray-400"
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column field="external_action" headerClassName="bg-transparent" body={externalActionBodyTemplate} />
        </DataTable>
      </InfiniteScroll>
      {showForm && <MedicationFormContainer med={med} onHide={reset} />}
    </>
  )
}

const initialState = {
  showForm: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "edit"
    payload?: MedItem | string | undefined
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "edit":
      return { ...state, showForm: true, med: payload as MedItem }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ showForm, med }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const edit = (payload: MedItem) => {
    dispatch({ type: "edit", payload })
  }

  return {
    showForm,
    med,
    reset,
    edit,
  }
}

type State = {
  med?: MedItem
  showForm: boolean
  isClone: boolean
}

type Props = {
  filters: MedicationsAdvanceFilter & { searchText?: string }
  filter(filters: MedicationsAdvanceFilter): void
  isLoadingCatalogs?: boolean
}

export { MedicationsRxs }
