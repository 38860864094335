import { User } from "fhir/fhir-extended"

import { DetailsRow } from "commons"
import { formatsByTypes } from "data"
import { formatStringDate } from "utils"

const UserDetailView = (user: User) => (
  <div className="flex flex-col h-full relative">
    <div className="flex flex-col h-full space-y-4 place-items-start pl-4 pr-3">
      <DetailsRow title="Name:" content={user.displayName} />
      <DetailsRow title="Email:" content={user.email ?? "No email provided"} />
      <DetailsRow title="Created at:" content={formatStringDate(user.createdDateTime, formatsByTypes.LONG_DATETIME)} />
      <DetailsRow title="Status:" content={user.accountEnabled ? " Active" : "Deactivated"} />
    </div>
  </div>
)

export { UserDetailView }
