import { asReference } from "fhir"
import { useQuery } from "@tanstack/react-query"
import { useContext } from "react"

import { useClient } from "api"
import { PatientApi } from "commons"
import { OrgIdContext } from "orders"

import { patientsQueryKeys } from "../query-keys"

const usePatientsRefs = (filter?: string) => {
  const { search } = useClient()
  const { orgId } = useContext(OrgIdContext)
  const queryKey = patientsQueryKeys.refs(orgId.value ?? "", filter)

  const { data, isLoading, isPending, isError } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(filter
          ? {
              name: filter
                .split(" ")
                .map((i, index) => (index > 0 ? `name=${i}` : i))
                .join("&"),
            }
          : {}),
        _count: "100",
        _elements: "id,identifier,name",
        _sort: "name",
      })

      const bundle = await search("patients", filters, undefined, signal)

      const patients = bundle.entry as PatientApi[]

      return patients.map((p) => asReference(p.patient))
    },
    meta: { context: { queryKey, filter } },
    enabled: !!filter,
  })

  return { patientRefs: data, isLoading: isLoading || isPending, isError }
}

export { usePatientsRefs }
