import { Formik } from "formik"
import { OrderFilter } from "../types"
import { OrdersFilters } from "./OrdersFilters"

const OrdersFiltersFormContiner = ({ initialValues, onSearch, onClearFilters }: Props) => (
  <Formik initialValues={initialValues} onSubmit={onSearch}>
    <OrdersFilters onClearFilters={onClearFilters} />
  </Formik>
)

type Props = {
  initialValues: OrderFilter
  onSearch(filters: OrderFilter): void
  onClearFilters(): void
}

export { OrdersFiltersFormContiner }
