import { Patient } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { patientQueryKey } from "../query-keys"

const useActiveDeactivePatient = () => {
  const queryClient = useQueryClient()
  const { patch } = useClient(IClient.AIDBOX)

  const updatedPatient = async (patient: Patient) => {
    if (!patient.id) {
      throw new Error(`No patient id provided for ${patient.name ?? "No name"}`)
    }

    return patch<Partial<Patient>>("Patient", patient.id!, { active: !patient?.active })
  }

  const { mutate: updatePatientStatus, isPending: isUpdating } = useMutation({
    mutationFn: updatedPatient,
    onSuccess: async (_, patient) => {
      await queryClient.invalidateQueries({ queryKey: patientQueryKey.all })
      displayNotificationSuccess(`Patient has been ${!patient?.active ? "activated" : "deactivated"} successfully!`)
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updatePatientStatus, isUpdating }
}

export { useActiveDeactivePatient }
