import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DetailsRow } from "commons"
import { getFirstEmail, getFirstPhone, humanNameAsString, getStringAddressByType } from "fhir"
import { formatStringDate } from "utils"

import { PatientInfo } from "../types"

const PatientDetailView = ({ patient, practioner, organization }: PatientInfo) => {
  const ehrURL = `${window.VITE_APP_EHR_APP_URL}/orgs/${organization?.id}/patients/${patient.id}?kp=summary`

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex flex-col h-full space-y-4 place-items-start pl-4 pr-3">
        <DetailsRow title="Name:" content={humanNameAsString(patient.name?.[0])} />
        <DetailsRow title="Email:" content={getFirstEmail(patient.telecom) ?? "No email provided"} />
        <DetailsRow title="Phone:" content={getFirstPhone(patient?.telecom) ?? "No phone provided"} />
        <DetailsRow title="Birth Date:" content={formatStringDate(patient?.birthDate)} />
        <DetailsRow title="Address:" content={getStringAddressByType(patient?.address)} />
        <DetailsRow title="Organization:" content={organization?.display ?? "No organization provided"} />
        <DetailsRow title="Practitioner:" content={practioner?.display} />
      </div>

      <div className="flex w-full justify-end border-t-2 border-slate-200 pt-2 bg-white pr-4 mb-2">
        <a
          href={ehrURL}
          className="button-primary rounded-lg p-1.5 px-3 flex items-center"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faExternalLink} className="mr-2 font-medium" />
          See on EHR
        </a>
      </div>
    </div>
  )
}

export { PatientDetailView }
