import { faUserLock } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { User } from "fhir/fhir-extended"
import { useReducer } from "react"

import { DropdownField, EmailField, InputField } from "commons"

import {
  changePasswordSchema,
  inviteUserSchema,
  INVITE_INITIAL_VALUES,
  passwordInitialValues,
  setUserEmailInitialValues,
  setUserEmailSchema,
} from "../components/validations"
import { displaySystem } from "../utils"
import { DialogTitle, UserActionsProps } from "../types"

const userActionInitialState = {
  showDialog: false,
  user: undefined,
  dialogTitle: undefined,
  content: undefined,
  initialValue: undefined,
  validateSchema: undefined,
} as UserActionsProps

const userReducer = (
  state: UserActionsProps,
  {
    type,
    payload,
  }: { type: "reset" | "setUser" | "inviteUser" | "editEmail" | "changePassword"; payload?: UserActionsProps },
) => {
  switch (type) {
    case "reset":
      return { ...userActionInitialState }
    case "setUser":
      return { ...state, ...(payload as UserActionsProps) }
    case "inviteUser":
      return { ...state, ...(payload as UserActionsProps) }
    case "editEmail":
      return { ...state, ...(payload as UserActionsProps) }
    case "changePassword":
      return { ...state, ...(payload as UserActionsProps) }
    default:
      return state
  }
}

const useUsersReducer = () => {
  const state = userActionInitialState
  const [{ showDialog, user, dialogTitle, content, initialValue, validateSchema }, dispatch] = useReducer(
    userReducer,
    state,
  )

  const usersReset = () => {
    dispatch({ type: "reset" })
  }
  const setUser = (user?: User) => {
    dispatch({ type: "setUser", payload: { user: user } })
  }
  const handleInviteUser = () => {
    dispatch({
      type: "inviteUser",
      payload: {
        showDialog: true,
        dialogTitle: DialogTitle.inviteUser,
        content: (
          <>
            <EmailField field="email" label="Email" validateDuplicate />
            <InputField field="displayName" label="Name" className="m" />
            <DropdownField
              field="website"
              label="Web site"
              options={displaySystem}
              optionLabel="display"
              optionValue="code"
              className="mt-3"
            />
          </>
        ),
        initialValue: INVITE_INITIAL_VALUES,
        validateSchema: inviteUserSchema,
      },
    })
  }
  const handleEditEmail = (user: User) => {
    dispatch({
      type: "editEmail",
      payload: {
        showDialog: true,
        user: user,
        dialogTitle: DialogTitle.editEmail,
        content: (
          <>
            <div className="mb-4">
              User: <span className="font-semibold">{user?.displayName}</span>
            </div>
            <EmailField
              field="newEmail"
              label=" New email"
              initialValue={user?.displayName}
              validateNotEqualToInitialValue
            />
          </>
        ),
        initialValue: setUserEmailInitialValues(user?.displayName ?? ""),
        validateSchema: setUserEmailSchema,
      },
    })
  }
  const handleChangePassword = (user: User) => {
    dispatch({
      type: "editEmail",
      payload: {
        showDialog: true,
        user: user,
        dialogTitle: DialogTitle.changePassword,
        content: (
          <>
            <div title="User" className="space-x-1">
              <FontAwesomeIcon icon={faUserLock} />
              <span className="font-semibold">{user?.displayName}</span>
            </div>
            <InputField field="temporalPassword" type="password" label="Password" />
          </>
        ),
        initialValue: passwordInitialValues(user?.id ?? ""),
        validateSchema: changePasswordSchema,
      },
    })
  }
  return {
    showDialog,
    user,
    dialogTitle,
    content,
    initialValue,
    validateSchema,
    usersReset,
    setUser,
    handleInviteUser,
    handleEditEmail,
    handleChangePassword,
  }
}

export { useUsersReducer }
