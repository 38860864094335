import { useState } from "react"
import { Sidebar } from "primereact/sidebar"
import { SubsNotification } from "fhir"

import { useScreenContext } from "commons"

import { SubsNotificationsList } from "./SubsNotificationsList"
import { SubsNotificationDetails } from "./SubsNotificationDetails"

const SubsNotificationsListView = () => {
  const [showOverlayDetails, setShowOverlayDetails] = useState(false)
  const [subsNotification, setSubsNotification] = useState<SubsNotification>()
  const { isSmallScreen } = useScreenContext()

  const showDetails = (subsnotification: SubsNotification) => {
    setSubsNotification(subsnotification)
    setShowOverlayDetails(true)
  }
  const closeDetails = () => {
    setShowOverlayDetails(false)
  }

  const customSidebarTitle = (
    <span className="bg-white w-full">
      <h6 className="font-semibold">Notification</h6>
    </span>
  )

  return (
    <>
      <SubsNotificationsList showDetails={showDetails} />
      <Sidebar
        visible={showOverlayDetails}
        position={isSmallScreen ? "bottom" : "right"}
        style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
        header={customSidebarTitle}
        onHide={closeDetails}
      >
        <div className="relative h-full w-full">
          <SubsNotificationDetails subsNotification={subsNotification} />
        </div>
      </Sidebar>
    </>
  )
}

export { SubsNotificationsListView }
