import { Organization, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient, IClient } from "api"
import { displayNotificationError } from "errors"
import { ContactPointSystem } from "data"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useUpdateOrganizationEmail = (onSuccess?: () => void, onSettled?: (error: Error | null) => void) => {
  const { patch } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const updateOrganization = async ({ organization, email }: { organization?: Organization; email: string }) => {
    const newMailTelecom = [{ system: ContactPointSystem.email, use: "home", value: email }]
    const newTelecom = organization?.telecom ?? newMailTelecom

    if (organization?.telecom) {
      const index = newTelecom?.findIndex((t) => t.system === ContactPointSystem.email)
      newTelecom.splice(index, index >= 0 ? 1 : 0, ...newMailTelecom)
    }

    return patch("Organization", organization?.id as string, { telecom: newTelecom } as ResourceObject)
  }

  const { mutate: updateOrganizationEmail, isPending: isUpdating } = useMutation({
    mutationFn: updateOrganization,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      onSuccess?.()
    },
    onSettled: async (_, error) => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      onSettled?.(error)
    },
  })

  return { updateOrganizationEmail, isUpdating }
}

export { useUpdateOrganizationEmail }
