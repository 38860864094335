import { Routes, Route, Navigate } from "react-router-dom"

import { useLogedInPractitioner, UserInformation } from "user"
import { PractitionerContainer } from "practitioners"
import { OrganizationListView } from "organizations"
import { AdministrationContainer } from "administration"
import { SubsNotificationsListView } from "subnotifications"
import { UserList } from "users"
import { PatientList } from "patients"
import { OrdersView } from "orders"

const RoutesContainer: React.FC = () => {
  const { isRootAdmin } = useLogedInPractitioner()

  return (
    <Routes>
      <Route path="/" element={<Navigate to={"organizations"} />} />
      <Route path="practitioners">
        <Route index element={<PractitionerContainer />} />
      </Route>
      <Route path="patients">
        <Route index element={<PatientList />} />
      </Route>
      <Route path="practitioner-profile">
        <Route path=":practitionerId" element={<UserInformation />} />
      </Route>
      <Route path="organizations">
        <Route index element={<OrganizationListView />} />
        <Route path=":organizationId/administration" element={<AdministrationContainer />} />
      </Route>
      <Route path="users">
        <Route index element={<UserList />} />
      </Route>
      <Route path="orders">
        <Route index element={<OrdersView />} />
      </Route>
      {isRootAdmin && (
        <Route path="subnotifications">
          <Route index element={<SubsNotificationsListView />} />
        </Route>
      )}
    </Routes>
  )
}

export { RoutesContainer }
