import { humanNameAsString } from "fhir"
import { useId, useRef } from "react"
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Menu } from "primereact/menu"
import { Badge } from "primereact/badge"
import { Tooltip } from "primereact/tooltip"
import { faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faSitemap,
  faUserAlt,
  faUsers,
  faUsersCog,
  faUsersMedical,
  faMemoCircleCheck,
} from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"

import { useAuth, useLoginContext } from "security"

import { AppIconLogo } from "./AppIconLogo"
import { AvatarImage } from "./AvatarImage"
import "./Sidebar.css"

const Sidebar = ({ className }: Props) => {
  const { logout, user } = useAuth()
  const menu = useRef<Menu>(null)
  const navigate = useNavigate()

  const { logedInPractitioner, isRootAdmin } = useLoginContext()

  const viewProfile = () => {
    navigate(`/practitioner-profile/${user?.practitionerResource?.id}`)
  }

  const items = [
    {
      label: "View Profile",
      icon: <FontAwesomeIcon icon={faUserAlt} className="mr-3" />,
      command: () => viewProfile(),
    },
    { label: "Logout", icon: <FontAwesomeIcon icon={faSignOut} className="mr-3" />, command: logout },
  ]

  return (
    <div className={classNames("bg-white border-r-2 border-gray-200 relative shadow-sm min-w-max", className)}>
      <nav className="flex flex-col leading-6 h-full">
        <div className="flex mx-5 mt-2">
          <AppIconLogo />
        </div>

        <ul className={classNames("grow pt-5 px-4")}>
          <SidebarLink text="Organizations" icon={faSitemap} to="/organizations" />

          <SidebarLink text="Practitioners" icon={faUsersMedical} to="/practitioners" />

          <SidebarLink text="Patients" icon={faUsers} to="/patients" />

          <SidebarLink text="Users" icon={faUsersCog} to="/users" />

          <SidebarLink text="Orders" icon={faMemoCircleCheck} to={`/orders`} />

          {isRootAdmin && <SidebarLink text="Notifications" icon={faBell} to={`/subnotifications`} />}
        </ul>

        <div
          className="flex items-center border-t border-gray-100 py-3 hover:bg-gray-50 cursor-pointer"
          onClick={(event) => menu?.current?.toggle(event)}
        >
          <div className="relative w-full">
            <div className={classNames("flex ease-in-out ml-3")}>
              <span className="sr-only">Open user menu</span>
              <AvatarImage
                photoUrl={logedInPractitioner?.practitioner.photo?.[0]?.url}
                name={humanNameAsString(logedInPractitioner?.practitioner.name?.[0])}
                className="h-8 w-8 rounded-full"
              />

              <div className="pl-3 text-left">
                <p className="text-sm">{user?.name}</p>
                <p className="text-xs text-gray-500">{user?.email}</p>
              </div>
            </div>

            <Menu className="ml-3" popup model={items} ref={menu} style={{ width: "230px" }} />
          </div>
        </div>
      </nav>
    </div>
  )
}

const SidebarLink = ({ text, to, icon, collapsed, qty = 0, showBadgeOnCollapsed }: LinkProps) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname })

  const tooltipId = useId().replace(/:/g, "")

  return (
    <li
      id={tooltipId}
      className={classNames("sidebar-link rounded", {
        "bg-gray-100": match,
        "hover:bg-gray-50": !match,
        "flex justify-center w-full": collapsed,
      })}
      data-pr-tooltip={text}
    >
      <Link to={to} className="flex items-center text-slate-500 my-4">
        <div className={classNames(collapsed ? "w-8 flex justify-center relative" : "flex-1 text-left")}>
          <span className="link-item items-center justify-center text-xl">
            <FontAwesomeIcon icon={icon} className="fa-fw" />
          </span>
          {collapsed && (
            <Tooltip target={`.sidebar-link#${tooltipId}`} className="text-xs">
              {text}
            </Tooltip>
          )}
          {!collapsed && <span className="pl-3">{text}</span>}
          {qty > 0 && collapsed && showBadgeOnCollapsed && <Badge className="sidebar-link-badge" />}
        </div>
        {qty > 0 && !collapsed && <span className="px-3 mx-3 bg-gray-50 rounded-xl text-sm">{qty}</span>}
      </Link>
    </li>
  )
}

type Props = {
  className: string
  collapsible?: boolean
}

type LinkProps = {
  to: string
  icon: IconProp
  text: string
  qty?: number
  collapsed?: boolean
  showBadgeOnCollapsed?: boolean
}

export { Sidebar }
