import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { IClient, useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"
import { PDParams } from "../../types"

const useEditPanel = (onSettled?: () => void) => {
  const { operationRequest } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const editPanelMutation = async ({ fee, id, organization }: PDParams) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "organization",
          value: {
            Reference: organization,
          },
        },
        {
          name: "fee",
          value: {
            decimal: fee ?? 0,
          },
        },
      ],
    }

    return operationRequest("PlanDefinition", "PATCH", "edit-lab-pd", id, parameters)
  }

  const { mutate: editPanel, isPending } = useMutation({
    mutationFn: editPanelMutation,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { organization, laboratory }) => {
      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.labPanels(organization.id as string),
        refetchType: "active",
      })

      displayNotificationSuccess("Lab test updated successfully!")
      datadogLogs.logger.info(`Lab test on ${laboratory.display} updated successfully!`)
    },
    onSettled,
  })

  return { editPanel, isUpdating: isPending }
}

export { useEditPanel }
