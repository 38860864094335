import { AutoComplete } from "primereact/autocomplete"
import { useState, FC } from "react"
import { Reference } from "fhir"
import { Field, FieldProps, ErrorMessage } from "formik"
import { classNames } from "primereact/utils"

import { useOrganizationRefs } from "organizations"

const OrganizationAutocomplete: FC<Props> = ({
  field,
  label,
  className,
  horizontal,
  inputClassName,
  organozationType,
  validation,
}) => {
  const [filter, setFilter] = useState("")
  const { organizationRefs } = useOrganizationRefs(filter, organozationType)

  const itemTemplate = (item: Reference) => {
    return <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{item.display}</span>
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
        return (
          <div
            className={classNames(
              "field relative ",
              horizontal ? "inline-flex justify-between" : "flex flex-col",
              className,
            )}
          >
            {label && (
              <label
                htmlFor={name}
                className={classNames("text-sm font-medium text-gray-700 mb-2", {
                  "mr-3 mb-0 mt-2": horizontal,
                })}
              >
                {label}
              </label>
            )}
            <div className="flex flex-col w-full">
              <AutoComplete
                id={name}
                name={name}
                value={typeof value === "object" ? value.display : value}
                field={name}
                suggestions={organizationRefs}
                completeMethod={(e) => setFilter(e.query)}
                onChange={(e) => {
                  setFieldValue(name, e.value)
                }}
                itemTemplate={itemTemplate}
                onSelect={(e) => {
                  setFieldValue(name, { id: e.value.id, display: e.value.display, resourceType: e.value.resourceType })
                }}
                className={classNames(
                  "p-inputtext-sm border-none ",
                  {
                    "p-invalid": touched && error,
                    horizontal: horizontal,
                  },
                  inputClassName,
                )}
                inputClassName={inputClassName}
              />
              <div className="flex items-start p-error h-2 mt-1">
                <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
              </div>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

export { OrganizationAutocomplete }

type Props = {
  field: string
  label?: string
  className?: string
  isReadonly?: boolean
  horizontal?: boolean
  inputClassName?: string
  organozationType?: string
  validation?(value: string): void
}
