import { useQuery } from "@tanstack/react-query"
import { ValueSet } from "fhir"

import { IClient, useClient } from "api"
import { ValueSetIds } from "commons"

import { valueSetQueryKeys } from "../query-keys"

const useValueSet = ({ valueSetId, filter, enabled }: ValueSetArgs) => {
  filter = filter?.trim()

  const { read } = useClient(IClient.AIDBOX)
  const queryKey = valueSetQueryKeys.list(valueSetId, filter)

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = filter ? new URLSearchParams({ filter, count: "30" }) : undefined
      const { expansion } = await read<ValueSet>("ValueSet", valueSetId, filters, "expand")
      return expansion?.contains?.map(({ code, system, display }) => ({ code, system, display })) ?? []
    },
    enabled,
    meta: { context: { queryKey, valueSetId, filter } },
  })

  return { codes: data, isLoading, isFetching, refetch }
}

interface ValueSetArgs {
  valueSetId: ValueSetIds
  filter?: string
  enabled?: boolean
}

export { useValueSet }
