import { useCallback, useState } from "react"
import { Organization } from "fhir"
import { Dialog } from "primereact/dialog"
import { InputTextarea } from "primereact/inputtextarea"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-light-svg-icons"

import { Button } from "commons"
import { copyToClipboard } from "utils"

import { useOrganizationPaymentSetup } from "../hooks"

const OrganizationPaymentModal = ({ show, org, onCancel }: Props) => {
  const [copied, setCopied] = useState(false)
  const { link, isSending, sendPaymentSetup } = useOrganizationPaymentSetup()
  const useRootStripe = org?.type?.find((item) => item.coding?.[0].code === "stripe-root-account")

  const handleCopiedToClipboard = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  const resendPaymentSetup = useCallback(() => {
    if (org?.id) {
      sendPaymentSetup(org.id)
    }
  }, [org, sendPaymentSetup])
  return (
    <Dialog
      header={link ? "Stripe link" : "Confirmation"}
      closable={false}
      className="w-full md:w-[70%] lg:w-[30%] m-2 "
      draggable={false}
      visible={show}
      onHide={onCancel}
      footer={
        <div>
          <Button
            label={link ? "Close" : "Cancel"}
            disabled={isSending}
            className="button-default h-12 px-6"
            onClick={onCancel}
          />
          {!link ? (
            <Button
              label="Send"
              className="button-primary h-12 px-6"
              onClick={resendPaymentSetup}
              loading={isSending}
            />
          ) : (
            <Button
              label="Copy"
              className="button-primary h-12 px-6"
              size="lg"
              onClick={() => copyToClipboard(link, handleCopiedToClipboard)}
            />
          )}
        </div>
      }
    >
      {link ? (
        <div className=" flex flex-col items-end h-44">
          <InputTextarea value={link} className="w-full h-36 p-0 m-0 mb-2" />
          <div>
            {copied && (
              <div className="flex items-center  ">
                <FontAwesomeIcon icon={faCheck} className="mr-2 text-primary" />
                <span className="  text-primary">copied to clipboard</span>
              </div>
            )}
          </div>
        </div>
      ) : useRootStripe ? (
        <span>
          Currently, this practice utilizes the Stripe account of <strong>{org?.partOf?.display}</strong>. Would you
          like to upgrade this practice to enable the creation of its own Stripe account? Once the email is received by
          the manager of the practice <strong>{org?.name}</strong> and the account creation process is completed, it
          will be automatically associated with the practice.
        </span>
      ) : (
        <span>Are you sure you want to resend payment setup?</span>
      )}
    </Dialog>
  )
}
type Props = {
  show: boolean
  org?: Organization
  onCancel: () => void
}

export { OrganizationPaymentModal }
