import { faFileMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayPanel } from "primereact/overlaypanel"
import { Sidebar } from "primereact/sidebar"
import { useId, useReducer, useRef, useState } from "react"

import { InfiniteScroll, SearchWithFilters, SkeletonLoader } from "commons"
import { useOrganizationContext, useOrganizationPractitioners } from "organizations"

import { useAuditEvents } from "../hooks"
import { FilterProps } from "../types"
import { AuditEventDetail } from "./AuditEventDetail"
import { AuditEventListItem } from "./AuditEventListItem"
import { EventsFilters } from "./EventsFilters"

const AuditEventsView = () => {
  const { currentOrganizationId } = useOrganizationContext()
  const { practitionerRefs } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  const {
    searchText,
    type,
    dates,
    patient,
    practitioner,
    search,
    filter,
    reset,
    showSidePanel,
    toggleSidePanel,
    selectEvent,
    selectedEventId,
  } = useReducerState()

  const { auditEvents, isLoading, count, total, hasNextPage, fetchNextPage, isFetchingNextPage } = useAuditEvents(
    currentOrganizationId,
    searchText,
    type,
    dates,
    patient,
    practitioner,
  )

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />
  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)

  return (
    <>
      <div className="px-6 pt-3 border-b shadow-sm">
        <p className="text-sm text-gray-500">
          Showing {count} audit events of {total} found
        </p>

        <div className="flex py-4 w-full justify-between">
          <div className="flex">
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              onTextFilter={search}
              isLoading={isLoading || isFetchingNextPage}
              filterNone={!type || !dates || !patient || !practitioner}
              formContent={
                <EventsFilters
                  initialValues={{ type, dates, patient, practitioner }}
                  practitioners={practitionerRefs}
                  onSearch={(filters) => {
                    filter(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    reset()
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        loader()
      ) : (
        <div className="flex flex-col overflow-auto grow">
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage()
            }}
            hasMore={hasNextPage}
            loader={loader()}
          >
            <div className="overflow-hidden bg-white">
              <ul className="divide-y divide-gray-200">
                {auditEvents?.map((event) => (
                  <AuditEventListItem
                    key={event.id}
                    auditEvent={event}
                    toggleSidePanel={toggleSidePanel}
                    selectEvent={selectEvent}
                  />
                ))}
              </ul>
            </div>
          </InfiniteScroll>
          <>
            {auditEvents?.length === 0 ? (
              <div className="flex items-center justify-center w-full h-full bg-white">
                <div className="flex flex-col items-center">
                  <FontAwesomeIcon icon={faFileMagnifyingGlass} size="3x" className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No results found</h3>
                  <p className="mt-1 text-sm text-gray-500">Check filters criteria and try again</p>
                </div>
              </div>
            ) : null}
          </>
        </div>
      )}
      <Sidebar
        visible={showSidePanel}
        position="right"
        style={{ minWidth: "30%" }}
        header={
          <span className="bg-white w-full">
            <h6 className="font-semibold">Audit Event</h6>
            <p className="text-slate-400 text-sm">Details</p>
          </span>
        }
        onHide={() => toggleSidePanel(false)}
        className="sidebar-form"
      >
        <div className="relative h-full px-4">
          {selectedEventId && <AuditEventDetail auditEventId={selectedEventId} />}
        </div>
      </Sidebar>
    </>
  )
}

const initialState = {
  searchText: "",
  type: undefined,
  dates: undefined,
  pacient: undefined,
  showSidePanel: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "search" | "filter" | "toggleSidePanel" | "selectEvent"
    payload?: string | FilterProps | boolean
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as FilterProps) }
    case "toggleSidePanel":
      return { ...state, showSidePanel: payload as boolean }
    case "selectEvent":
      return { ...state, selectedEventId: payload as string }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, type, dates, patient, practitioner, showSidePanel, selectedEventId }, dispatch] = useReducer(
    reducer,
    state,
  )

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: FilterProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  const toggleSidePanel = (show: boolean) => {
    dispatch({ type: "toggleSidePanel", payload: show })
  }

  const selectEvent = (id: string) => {
    dispatch({ type: "selectEvent", payload: id })
  }

  return {
    searchText,
    type,
    dates,
    patient,
    practitioner,
    filter,
    search,
    reset,
    showSidePanel,
    toggleSidePanel,
    selectEvent,
    selectedEventId,
  }
}

type State = {
  showSidePanel: boolean
  selectedEventId?: string
} & FilterProps

export { AuditEventsView }
