import { classNames } from "primereact/utils"
import { FC, ImgHTMLAttributes } from "react"
import { AccountBETACreditCardArray } from "fhir/fhir-extended"
import { Address, Extension } from "fhir"

import visa from "assets/visasm.png"
import masterCard from "assets/mastercard.png"
import americanExpress from "assets/amex.png"
import discover from "assets/discover.png"
import dinersClub from "assets/dinersclub.png"
import jcb from "assets/jcb.png"
import genericCard from "assets/gcard.png"

const CreditCardImage: FC<Props> = ({ creditCard, className, ...props }) => (
  <img
    src={cardImg[creditCard.type as cardType] ?? genericCard}
    alt="creditCard.type"
    className={classNames("rounded-lg h-12", className)}
    title="Type"
    {...props}
  />
)

const cardImg: Record<cardType, string> = {
  AE: americanExpress,
  MC: masterCard,
  V: visa,
  D: discover,
  DC: dinersClub,
  JCB: jcb,
}

type cardType = "AE" | "MC" | "V" | "D" | "DC" | "JCB"

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  creditCard: AccountBETACreditCardArray<Address, Extension>
}

export { CreditCardImage }
