import { Coding, Identifier, Reference } from "fhir"
import { useFormikContext } from "formik"

import { CodeableConceptField, InputField, PeriodField, ReferenceDropdownField } from "commons"
import { IDENTIFIER_CODE } from "data"

import { isValidNPIValue } from "../utils"

const IdentifierForm = ({ fieldPath, types, assigners }: Props) => {
  const {
    values: { type },
  } = useFormikContext<Identifier>()

  const template = (code: Coding) => (
    <div className="flex flex-col">
      <div>{`${code.display} (${code.code?.toUpperCase()})`}</div>
      <div className="text-sm text-gray-400">{code.system}</div>
    </div>
  )

  return (
    <div className="relative p-fluid space-y-5 w-full">
      <InputField
        field={fieldPath ? `${fieldPath}.value` : "value"}
        label="Value"
        className="flex-1"
        validation={(value) => {
          return !value
            ? "Value is required"
            : type?.coding?.[0]?.code === IDENTIFIER_CODE.NPI && !isValidNPIValue(value)
              ? "Invalid NPI value"
              : undefined
        }}
      />
      {types?.length ? (
        <CodeableConceptField
          codes={types}
          field={fieldPath ? `${fieldPath}.type` : "type"}
          label="Type"
          className="flex-1"
          itemTemplate={template}
          validation={(value) => (!value.coding?.[0].code ? "Type is required" : undefined)}
        />
      ) : (
        <InputField
          field={fieldPath ? `${fieldPath}.system` : "system"}
          label="System"
          className="flex-1"
          validation={(system) => (!system ? "System is required" : undefined)}
        />
      )}
      {type?.coding?.[0]?.code === IDENTIFIER_CODE.AN && (
        <ReferenceDropdownField
          field={fieldPath ? `${fieldPath}.assigner` : "assigner"}
          label="Assigner"
          options={assigners}
          validation={(value) => (!value.display ? "Assigner is required" : undefined)}
        />
      )}

      <PeriodField
        field={fieldPath ? `${fieldPath}.period` : "period"}
        stringFormatType="ISO_8601_DATE"
        className="flex-1"
      />
    </div>
  )
}

type Props = {
  fieldPath?: string
  types?: Coding[]
  assigners?: Reference[]
}
export { IdentifierForm }
