import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { DateField, DropdownField } from "commons"
import { FilterProps } from "../types"
import { classNames } from "primereact/utils"

const UsersFilters = ({ initialValues, onSearch, onClearFilters }: Props) => {
  const statusTypes = [
    { code: "true", display: "Active" },
    { code: "false", display: "Deactivated" },
  ]
  const renderForm = ({ isSubmitting }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        field="status"
        label="Status"
        optionLabel="display"
        options={statusTypes}
        horizontal
        inputClassName="slashed"
      />
      <div className={classNames("field space-y-2 relative inline-flex justify-between items-center")}>
        <label className="text-sm font-medium text-gray-700">Created on</label>

        <div className=" flex w-3/4 items-center">
          <DateField field="after" stringFormatType="ISO_8601_DATE" className="mr-1" />
          <label className="text-sm font-black text-gray-700 ">-</label>
          <DateField field="before" stringFormatType="ISO_8601_DATE" className="ml-1" />
        </div>
      </div>

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || (!initialValues.status && !initialValues.after && !initialValues.before)}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch}>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { UsersFilters }
