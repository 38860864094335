import { Parameters } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient, IClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationPaymentSetup = (onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient(IClient.AIDBOX)

  const paymentSetup = async (orgId: string) => {
    const result = await operationRequest<Parameters>("Organization", "POST", "payment-setup", orgId)
    const resp = result.parameter?.find((param) => param.name === "link")
    return resp?.value?.string
  }

  const {
    mutate: sendPaymentSetup,
    isPending: isSending,
    data,
  } = useMutation({
    mutationFn: paymentSetup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      displayNotificationSuccess(`The payment setup has been sent successfully.`)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      onSettled?.()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { link: data, sendPaymentSetup, isSending }
}

export { useOrganizationPaymentSetup }
