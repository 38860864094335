import { faBars } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useScreenContext } from "commons"
import banner from "images/banner.webp"
import { classNames } from "primereact/utils"

import { BasicInformation } from "./BasicInformation"
import { ContactInformation } from "./ContactInformation"
import { AddressInformation } from "./AddressInformation"
import { IdentifiersCard } from "./IdentifierCard"
import { OrganizationsCard } from "./OrganizationCard"

const UserInformation = () => {
  const defaultBanner = <img src={banner} className="absolute object-cover top-0 left-0 w-full h-full" alt="" />
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  return (
    <>
      <div className="flex relative h-12 banner items-center px-4">
        {defaultBanner}
        <div className={classNames("flex z-50 w-full", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <h1 className="flex font-semibold text-xl text-gray-900 ">Practitioner profile</h1>
        </div>
      </div>
      <div className="h-full p-3 overflow-auto">
        <div className="grid h-max gap-4 max-w-5xl m-auto pb-6 mb-10">
          <BasicInformation />
          <ContactInformation />
          <AddressInformation />
          <IdentifiersCard />
          <OrganizationsCard />
        </div>
      </div>
    </>
  )
}

export { UserInformation }
