import { useMutation } from "@tanstack/react-query"
import { Account } from "fhir"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useDecryptAccount = () => {
  const { read } = useClient(IClient.AIDBOX)

  const decryptAccount = async ({ accountId }: { accountId: string }): Promise<Account> => {
    const response = await read(`$decrypt/Account/${accountId}`)

    return response as Account
  }

  const { mutateAsync: decrypt, isPending: isDecrypting } = useMutation({
    mutationFn: decryptAccount,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { decrypt, isDecrypting }
}

export { useDecryptAccount }
