import { useMutation, useQueryClient } from "@tanstack/react-query"
import { User } from "fhir/fhir-extended"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { usersQueryKeys } from "../query-keys"

const useActiveDeactiveUser = (onClose: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const activeDeactive = async (user: User) => {
    return operationRequest(`users/${user.accountEnabled ? "deactivate" : "activate"}`, "POST", "", undefined, {
      id: user.id,
    })
  }

  const { mutate: activeDeactiveUser, isPending: isChangingStatus } = useMutation({
    mutationFn: activeDeactive,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      displayNotificationSuccess(`User status changed succesfully`)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      onClose()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { activeDeactiveUser, isChangingStatus }
}

export { useActiveDeactiveUser }
