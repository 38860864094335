import { ResourceObject } from "fhir"
import { FormikValues } from "formik"

import { CommonProps, StackedListItem, StackedListItemProps } from "./StackedListItem"

const StackedListContainer = <T extends ResourceObject | FormikValues>({
  data,
  itemModelBuilder,
  rowHover = true,
  itemPadding,
  itemsClassName,
}: Props<T>) => {
  return (
    <ul className="@container divide-y divide-gray-200">
      {data?.map((item, index) => (
        <StackedListItem
          key={item.id ?? index}
          modelData={itemModelBuilder(item, index)}
          rowHover={rowHover}
          itemPadding={itemPadding}
          className={itemsClassName}
        />
      ))}
    </ul>
  )
}

type Props<T> = CommonProps & {
  data: T[]
  itemsClassName?: string
  itemModelBuilder(item: T, itemIndex: number): StackedListItemProps
}

export { StackedListContainer }
