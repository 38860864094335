import { IDENTIFIER_CODE } from "data"
import { Identifier } from "fhir"

const identifierInitialValues = {
  value: "",
  system: "",
  type: {},
  assigner: {},
}

const sanitize = (identifier: Identifier) => {
  return {
    ...identifier,
    value:
      identifier.type?.coding?.[0].code === IDENTIFIER_CODE.HEALTH_GORILLA
        ? `${IDENTIFIER_CODE.HEALTH_GORILLA}: ${identifier.value}`
        : identifier.value,
  }
}

export { identifierInitialValues, sanitize }
