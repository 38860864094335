import { Organization } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useActiveDeactiveOrganization = (onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { update } = useClient()

  const updatedOrganization = async (organization: Organization) => {
    if (!organization.id) {
      throw new Error(`No organization id provided for ${organization.name ?? "No name"}`)
    }

    const orgUpdated: Organization = {
      ...organization,
      active: !organization.active,
    }

    return update("organizations", organization.id, orgUpdated)
  }

  const { mutate: updateOrganizationStatus, isPending: isUpdating } = useMutation({
    mutationFn: updatedOrganization,

    onSuccess: async (_, organization) => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      displayNotificationSuccess(
        `Organization has been ${!organization.active ? "activated" : "deactivated"} successfully!`,
      )
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      onSettled?.()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateOrganizationStatus, isUpdating }
}

export { useActiveDeactiveOrganization }
