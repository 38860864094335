import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons"

const PatientEmptyState = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faUserPlus} size="3x" className="text-gray-400" />
      <h6 className="font-semibold text-xl pt-4 pb-2">No patient</h6>
      <p className="text-gray-400 pb-6">Get started by creating a new patient</p>
    </div>
  </div>
)

export { PatientEmptyState }
