import { useQuery } from "@tanstack/react-query"
import { Composition, getResources, getResourcesByTypeAsIndex, Organization } from "fhir"

import { useVisibleMedicationCatalogs } from "administration"
import { IClient, useClient } from "api"

import { medsQueryKeys } from "../meds_query_keys"

const useMedicationCatalogs = (currentOrganizationId: string) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = medsQueryKeys.catalogs
  const getVisibleCatalogs = useVisibleMedicationCatalogs(currentOrganizationId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        type: "catalog",
        "author:Organization.type": "pharmacy,medication-distributor",
        category: "medication",
        _include: "author",
      })
      filters.append("category", "valid-for-individual-use")

      const bundle = await search(`Composition`, filters, undefined, signal)

      const catalogs = getResources<Composition>(bundle, "Composition")
      const authors = getResourcesByTypeAsIndex<Organization>(bundle, "Organization")

      const catalogVisibility = await getVisibleCatalogs()

      const { nutraCatalogs, rxCatalogs } = catalogs.reduce<{
        nutraCatalogs: Composition[]
        rxCatalogs: Composition[]
      }>(
        (acc, catalog) => {
          if (catalogVisibility.includes(catalog.id as string)) {
            const authorId = catalog.author?.[0]?.id as string
            const type = authors[authorId]?.type?.[0]?.coding?.[0]?.code

            switch (type) {
              case "pharmacy":
                return { ...acc, rxCatalogs: [...acc.rxCatalogs, catalog] }
              case "medication-distributor":
                return { ...acc, nutraCatalogs: [...acc.nutraCatalogs, catalog] }
            }
          }

          return acc
        },
        { nutraCatalogs: [], rxCatalogs: [] },
      )

      return { nutraCatalogs, rxCatalogs }
    },

    meta: { context: { queryKey } },
  })

  return {
    nutraCatalogs: data?.nutraCatalogs ?? [],
    rxCatalogs: data?.rxCatalogs ?? [],
    isLoading,
  }
}

export { useMedicationCatalogs }
