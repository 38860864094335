import { Field, FieldProps, ErrorMessage } from "formik"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { MultiSelect } from "primereact/multiselect"
import { Coding } from "fhir"

const MultiSelectField: FC<Props> = ({
  field,
  options,
  label,
  className,
  optionLabel = "label",
  optionValue = "code",
  horizontal,
  showClear,
  inputClassName,
}) => (
  <Field name={field}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div
        className={classNames(
          "field relative",
          horizontal ? "inline-flex justify-between" : "flex flex-col",
          className,
        )}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}
          >
            {label}
          </label>
        )}
        <div className="flex flex-col w-full">
          <MultiSelect
            id={name}
            name={name}
            optionLabel={optionLabel}
            optionValue={optionValue}
            options={options}
            onChange={onChange}
            value={value}
            showClear={showClear}
            className={classNames(
              "p-inputtext-sm",
              {
                "p-invalid": touched && error,
                horizontal: horizontal,
              },
              inputClassName,
            )}
          />
          <div className="flex items-start p-error h-2 mt-1">
            <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
          </div>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  options: Record<string, string | number>[] | Coding[]
  label?: string
  optionLabel?: string
  optionValue?: string
  className?: string
  horizontal?: boolean
  showClear?: boolean
  inputClassName?: string
}

export { MultiSelectField }
