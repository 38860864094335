import { useEffect } from "react"
import { asReference, getResources, Organization, Reference } from "fhir"
import { useQuery } from "@tanstack/react-query"

import { IClient, useClient } from "api"

import { organizationQueryKeys } from "..//query-keys"

const useDiagnosticLaboratories = (
  currentOrganizationId: string,
  filter?: string,
  onSettled?: (data?: Reference[], error?: Error | null) => void,
) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = organizationQueryKeys.diagnosticLabs(filter)

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { _content: filter.includes(" ") ? `"${filter}"` : filter } : {}),
        _count: "100",
        _sort: "name",
        _page: "1",
        type: "facility-provider",
        active: "true",
      })

      const bundle = await search("Organization", filters, undefined, signal)

      const diagnosticLabs = getResources(bundle) as Organization[]

      /* TODO: Remove filter hack when testing AM lab ends */
      return diagnosticLabs.reduce<Reference[]>((prev, lab) => {
        const identifiersByOrg = lab.identifier?.filter(
          ({ system }) => system === "http://chartedhealth.com/system/hack/remember/removeme",
        )

        if (!identifiersByOrg?.length || identifiersByOrg.some(({ value }) => value === currentOrganizationId)) {
          return [...prev, asReference(lab)]
        }

        return prev
      }, [])
    },
    meta: { context: { queryKey, filter } },
  })

  useEffect(() => {
    onSettled?.(data, error)
  }, [data, error])

  return { diagnosticLabs: data, isLoading, isError }
}

export { useDiagnosticLaboratories }
