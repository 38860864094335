import { getResources, Organization, Location, Reference, asReference, Device } from "fhir"
import { useSuspenseQuery } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { datadogLogs } from "logger"
import { organizationQueryKeys } from "organizations"
import { READER_DEVICE_IDENTIFIER_SYSTEM } from "data"
import { isDevice, isLocation } from "utils"

const useOrganizationAdministrationData = (organizationId: string) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = organizationQueryKeys.currentOrganization.current(organizationId)

  const { data, isLoading, isPending } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _include: "Location:organization:Organization",
      })

      const deviceFilters = new URLSearchParams({
        "identifier:not": `${READER_DEVICE_IDENTIFIER_SYSTEM}|`,
      })

      const bundleLocations = await search(`Organization/${organizationId}/Location`, filters, undefined, signal)

      const bundleDevices = await search(`Organization/${organizationId}/Device`, deviceFilters, undefined, signal)

      const organization = getResources<Organization>(bundleLocations, "Organization").find(
        ({ id }) => id === organizationId,
      )

      const { location, rooms, roomsRefs } =
        bundleLocations.entry?.reduce<{ location: Location; rooms: Location[]; roomsRefs: Reference[] }>(
          (prev, { resource }) => {
            if (isLocation(resource)) {
              if (resource.physicalType?.coding?.[0]?.code === "ro") {
                return {
                  ...prev,
                  rooms: [...prev.rooms, resource],
                  roomsRefs: [...prev.roomsRefs, asReference(resource)],
                }
              } else {
                return { ...prev, location: resource }
              }
            }

            return prev
          },
          { location: {}, rooms: [], roomsRefs: [] },
        ) ?? {}

      const { devices, devicesRefs } =
        bundleDevices.entry?.reduce<{ devices: Device[]; devicesRefs: Reference[] }>(
          (prev, { resource }) => {
            if (isDevice(resource)) {
              return { devices: [...prev.devices, resource], devicesRefs: [...prev.devicesRefs, asReference(resource)] }
            }

            return prev
          },
          { devices: [], devicesRefs: [] },
        ) ?? {}

      if (!organization) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: "Organization not found",
          },
        })
      }

      if (!location) {
        datadogLogs.logger.warn(`Organization ${organization.name} does not have an associated location`, { queryKey })
      }

      return {
        organization,
        location,
        rooms,
        roomsRefs,
        devices,
        devicesRefs,
      }
    },
    meta: { context: { queryKey } },
  })

  return {
    organization: data.organization,
    location: data.location as Location,
    rooms: data.rooms ?? [],
    roomsRefs: data.roomsRefs ?? [],
    devices: data.devices ?? [],
    devicesRefs: data.devicesRefs ?? [],
    isLoading: isLoading || isPending,
  }
}

export { useOrganizationAdministrationData }
