import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"
import { User } from "fhir/fhir-extended"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { usersQueryKeys } from "../query-keys"

const useRemove2faSoftwareMethod = (onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const remove2faMethod = async (user: User) =>
    operationRequest("users/delete-software-method", "POST", "", undefined, {
      id: user.id,
      displayName: user.displayName,
    } as Parameters)

  const { mutate: remove2faSoftware, isPending: isRemoving2faSoftware } = useMutation({
    mutationFn: remove2faMethod,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      displayNotificationSuccess("The 2FA App authenticator method has been removed successfully")
      onSuccess?.()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { remove2faSoftware, isRemoving2faSoftware }
}

export { useRemove2faSoftwareMethod }
