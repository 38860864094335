import { Coding, Reference } from "fhir"
import { Button } from "primereact/button"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { OverlayPanel } from "primereact/overlaypanel"
import { useMemo, useRef, useState } from "react"

import { SearchWithFilters } from "commons"
import { getEnabledLabFacilities } from "commons/labs"
import { useDiagnosticLaboratories, useOrganizationContext } from "organizations"

import { AdvanceFilter, LABS_SECTION_TYPE } from "../../types"

const LabsFilters = ({ activeTab, filters, onFilter, onSearch, onReset }: Props) => {
  const { enabledLabs, currentOrganizationId } = useOrganizationContext()
  const { diagnosticLabs } = useDiagnosticLaboratories(currentOrganizationId)
  const performerLabsOptions = useMemo(
    () => getEnabledLabFacilities(diagnosticLabs ?? [], enabledLabs),
    [diagnosticLabs, enabledLabs],
  )
  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const { orgs, archived, performerLabs, hidden } = filters

  const hasFilter = Object.entries(filters).some(([, value]) => (Array.isArray(value) ? !!value.length : !!value))

  return (
    <div className="flex w-full gap-2 p-1 h-12">
      <SearchWithFilters
        showOverlaypanel={overlayFilter}
        showSidebar={overlaySidebarFilter}
        setShowSidebar={setOverlaySidebarFilter}
        onTextFilter={onSearch}
        isLoading={false}
        filterNone={!hasFilter}
        formContent={
          <AdvanceLabsFilters
            initialValues={{ orgs, archived, performerLabs, hidden }}
            orgs={[
              { code: "master", display: "Master Recorder Whatever" },
              { code: "miora", display: "Miora Test Center" },
              { code: "mc", display: "Metabolic Code" },
              { code: "evexias", display: "Evexias Health Center" },
            ]}
            performerLabs={performerLabsOptions}
            onClearFilters={() => {
              onReset()
              overlayFilter?.current?.hide()
              setOverlaySidebarFilter(false)
            }}
            onChangeFilters={(field: string, value: boolean | string[]) =>
              field === "orgs"
                ? onFilter({ orgs: value as string[] })
                : field === "performerLabs"
                  ? onFilter({ performerLabs: value as string[] })
                  : onFilter({ [field]: value })
            }
            isComboFilter={activeTab === LABS_SECTION_TYPE.LABS_COMBOS_SECTION}
            disableClearAll={!hasFilter}
          />
        }
      />
    </div>
  )
}
// <SearchInput
//   search={onSearch}
//   autoFocus={false}
//   placeholder={activeTab === LABS_SECTION_TYPE.LABS_COMBOS_SECTION ? "Search Combo" : "Search Test"}
//   isLoading={false}
//   className="flex w-full"
// />
const AdvanceLabsFilters = ({
  initialValues,
  performerLabs,
  isComboFilter,
  onChangeFilters,
  onClearFilters,
  disableClearAll,
}: AdvanceLabsFilterProps) => {
  /*  const [selectedOrgs, setSelectedOrgs] = useState(initialValues?.orgs ?? []) */
  const [selectedPerformerLabs, setSelectedPerformerLabs] = useState(initialValues?.performerLabs ?? [])

  /* const onSelectedOrgsChange = (e: CheckboxChangeParams) => {
    const currentSelectedOrgs = [...selectedOrgs]
    if (e.checked) currentSelectedOrgs.push(e.value)
    else currentSelectedOrgs.splice(currentSelectedOrgs.indexOf(e.value), 1)

    setSelectedOrgs(currentSelectedOrgs)
  }
 */
  const onSelectedPerformerLabsChange = (e: CheckboxChangeEvent) => {
    const currentSelectedPerformerLabs = [...selectedPerformerLabs]
    if (e.checked) currentSelectedPerformerLabs.push(e.value)
    else currentSelectedPerformerLabs.splice(currentSelectedPerformerLabs.indexOf(e.value), 1)

    setSelectedPerformerLabs(currentSelectedPerformerLabs)
    onChangeFilters("performerLabs", currentSelectedPerformerLabs)
  }

  return (
    <div className="flex flex-col h- bg-white gap-2 ml-3 mr-5 divide-y divide-text-gray-400">
      <div className="flex w-full justify-end  bg-white">
        <Button
          type="button"
          label="Clear all"
          disabled={disableClearAll}
          // disabled={!initialValues.orgs && !initialValues.archived}
          onClick={onClearFilters}
          className="p-button-sm button-default p-button-text border-none bg-transparent text-gray-400"
        />
      </div>
      <div className="pt-6 pb-6 flex flex-col gap-3">
        <label className="text-sm text-gray-400">Laboratories</label>
        {initialValues.performerLabs &&
          performerLabs?.map((item) => (
            <div key={item.id} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedPerformerLabsChange(e)
                }}
                checked={selectedPerformerLabs?.includes(item.id!)}
                value={item.id}
              />
              <label className="p-checkbox-label">{item.display}</label>
            </div>
          ))}
      </div>
      {/* <div className="pt-6 pb-6 flex flex-col gap-3">
        <label className="text-sm text-gray-400">Organizations</label>
        {initialValues.orgs &&
          orgs?.map((item) => (
            <div key={item.code} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedOrgsChange(e)
                }}
                checked={selectedOrgs?.includes(item.code!)}
                value={item.code}
              />
              <label className="p-checkbox-label">{item.display}</label>
            </div>
          ))}
      </div> */}
      <div className="flex flex-row gap-4 pt-6">
        <Checkbox
          onChange={(e) => onChangeFilters("archived", e.checked ?? false)}
          checked={initialValues.archived ?? false}
          value="Show Archived"
        />
        <label className="p-checkbox-label">Show Archived</label>
      </div>
      {isComboFilter && (
        <div className="flex flex-row gap-4 pt-6">
          <Checkbox
            onChange={(e) => onChangeFilters("hidden", e.checked ?? false)}
            checked={initialValues.hidden ?? false}
            value="Show Hidden"
          />
          <label className="p-checkbox-label">Show Hidden</label>
        </div>
      )}
    </div>
  )
}

type AdvanceLabsFilterProps = {
  initialValues: AdvanceFilter
  orgs?: Coding[]
  performerLabs?: Reference[]
  onChangeFilters(field: string, value: boolean | string[]): void
  onClearFilters(): void
  isComboFilter?: boolean
  disableClearAll: boolean
}

type Props = {
  filters: AdvanceFilter
  activeTab: LABS_SECTION_TYPE
  onFilter: (filters: AdvanceFilter) => void
  onSearch: (searchText: string) => void
  onReset: () => void
}

export { LabsFilters }
