import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons"

import { DropdownField, InputField } from "commons"
import { genders } from "data"

import { FilterProps } from "../types"

const PatientFilters = ({ initialValues, onSearch, onClearFilters }: Props) => {
  const renderForm = ({ isSubmitting, setFieldValue, values }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <div className="flex justify-end items-center p-input-icon-right">
        {values.email && <ClearInputAction onClick={() => setFieldValue("email", "")} />}
        <InputField
          type="email"
          field="email"
          label="Email"
          horizontal
          className="items-center w-full"
          inputClassName="slashed"
        />
      </div>

      <div className="flex justify-end items-center p-input-icon-right">
        {values.gender && <ClearInputAction className="right-8" onClick={() => setFieldValue("gender", "")} />}
        <DropdownField
          field="gender"
          label="Biological Sex"
          options={genders}
          horizontal
          className="w-full"
          inputClassName="slashed "
        />
      </div>

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || (!initialValues.email && !initialValues.gender)}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch}>
      {renderForm}
    </Formik>
  )
}

const ClearInputAction: React.FC<{ className?: string; onClick: () => void }> = ({
  className = "right-2",
  onClick,
}: {
  className?: string
  onClick: () => void
}) => (
  <div title="Clear filter" className={`absolute cursor-pointer h-2/4 mb-8 flex items-end z-10 ${className}`}>
    <FontAwesomeIcon icon={faTimesCircle} className="text-md" onClick={onClick} />
  </div>
)

type Props = {
  initialValues: FilterProps
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { PatientFilters }
