import { FC } from "react"

import { AutocompleteCodeableConceptField } from "../../form/AutocompleteCodeableConceptField"
import { InputField } from "../../form/InputField"
import { ValueSetIds } from "commons/types"

const DeviceForm: FC<Props> = ({ isReaderForm, isEditing }) => (
  <div className="flex flex-col space-y-4">
    <InputField
      field="deviceName[0].name"
      label="Name"
      validation={(value) => (!value ? "Name is required" : undefined)}
    />
    {isReaderForm ? (
      !isEditing && (
        <InputField
          field="registrationCode"
          label="Registration Code"
          validation={(value) => (!value ? "Provide a code to register the reader" : undefined)}
        />
      )
    ) : (
      <>
        <InputField field="manufacturer" label="Manufacturer" />
        <AutocompleteCodeableConceptField
          field="type"
          label="Type"
          valueSetId={ValueSetIds.DEVICE_TYPE}
          className="p-fluid"
        />
      </>
    )}
  </div>
)

type Props = {
  isReaderForm?: boolean
  isEditing?: boolean
}

export { DeviceForm }
