import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient, IClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { practitionerQueryKeys } from "practitioners/query-keys"
import { CustomError } from "commons"

const useInvitePractitioner = (practId: string, practName: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient(IClient.AIDBOX)

  const invite = async () => {
    return operationRequest("Practitioner", "POST", "invite", practId)
  }

  const { mutate: invitePractitioner, isPending: isInviting } = useMutation({
    mutationFn: invite,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.invitationWhithId(practId) })
      displayNotificationSuccess(`In order to invite ${practName}, we are trying to stablish communication via email.`)
      onSuccess?.()
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.invitationWhithId(practId) })
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { invitePractitioner, isInviting }
}

export { useInvitePractitioner }
