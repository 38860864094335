import { useContext } from "react"

import { ScreenCtx } from "../components/ScreenContextProvider"

const useScreenContext = () => {
  const context = useContext(ScreenCtx)

  if (context === undefined) {
    throw new Error("Couldn't create Screen Context")
  }

  return context
}

export { useScreenContext }
