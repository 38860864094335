import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSitemap } from "@fortawesome/pro-regular-svg-icons"
import { Button } from "primereact/button"

const OrganizationEmptyState = ({ onAdd }: Props) => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faSitemap} size="3x" className="text-gray-400" />
      <h6 className="font-semibold text-xl pt-4 pb-2">No organization</h6>
      <p className="text-gray-400 pb-6">Get started by creating a new organization</p>

      <Button
        label="New Organization"
        icon={<FontAwesomeIcon icon={faPlus} className="mr-3" />}
        className="block outline-none ring-0 text-white p-3 button-primary"
        onClick={onAdd}
      />
    </div>
  </div>
)

type Props = {
  onAdd(): void
}

export { OrganizationEmptyState }
