import { useMutation, useQueryClient } from "@tanstack/react-query"
import { PlanDefinition } from "fhir"

import { IClient, useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"

const usePatchCombo = (organizationId: string, onSettled?: () => void) => {
  const { patch } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const update = async ({ planDefinition }: { planDefinition: Partial<PlanDefinition>; message?: string }) => {
    return patch(
      "PlanDefinition",
      planDefinition.id as string,
      { ...planDefinition, resourceType: "PlanDefinition" } as PlanDefinition,
    )
  }

  const { mutate: patchCombo, isPending } = useMutation({
    mutationFn: update,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { message }) => {
      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.labCombos(organizationId),
        refetchType: "all",
      })
      displayNotificationSuccess(message ?? "Updated successfully!")
    },
    onSettled,
  })

  return { patchCombo, isPending }
}

export { usePatchCombo }
