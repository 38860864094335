import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useFormikContext } from "formik"
import { Button } from "primereact/button"
import { humanNameAsString } from "fhir"

import { AvatarCrop, AvatarImage, PractitionerApi } from "commons"
import { faImage, faTrash } from "@fortawesome/pro-regular-svg-icons"

const ProfileImageField = () => {
  const [showAvatarCrop, setShowAvatarCrop] = useState(false)
  const {
    values: { practitioner },
    setFieldValue,
  } = useFormikContext<PractitionerApi>()

  const updatePatientPhoto = (url: string) => {
    setFieldValue("practitioner.photo[0].url", url)
  }

  return (
    <>
      <div className="flex flex-col flex-1 gap-2 items-center justify-center">
        <AvatarImage
          photoUrl={practitioner.photo?.[0].url}
          name={humanNameAsString(practitioner.name?.[0])}
          className="w-48 h-48 rounded"
        />
        <div className="flex flex-col gap-2 justify-center">
          <Button
            type="button"
            label="Change avatar"
            className="p-button-sm button-primary h-8"
            icon={<FontAwesomeIcon icon={faImage} className="mr-1" />}
            onClick={() => setShowAvatarCrop(true)}
          />
          <Button
            type="button"
            label="Delete avatar"
            className="p-button-sm p-button-outlined h-8"
            icon={<FontAwesomeIcon icon={faTrash} className="mr-1" />}
            onClick={() => updatePatientPhoto("")}
          />
        </div>
      </div>
      <AvatarCrop
        visible={showAvatarCrop}
        onHide={() => setShowAvatarCrop(false)}
        container="practitioner-photos"
        userId={practitioner.id as string}
        onUpload={updatePatientPhoto}
      />
    </>
  )
}

export { ProfileImageField }
