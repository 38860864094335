const DetailsRow = ({ title, content }: Props) => (
  <div className=" border-b border-gray-200 w-full flex items-center last:border-0 first:pt-0 ">
    <div className="w-full divide-y divide-gray-200">
      <div className="flex items-center w-full px-4 py-2 ">
        <span className="w-2/4  font-semibold text-gray-900 ">{title}</span>
        <span className="mt-1  text-gray-600 w-2/3">{content}</span>
      </div>
    </div>
  </div>
)

type Props = {
  title?: string
  content?: string
}

export { DetailsRow }
