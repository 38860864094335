import { asReference, Organization, Reference } from "fhir"
import { useFormikContext } from "formik"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { useCallback, useEffect, useState } from "react"

import { AddressField, EmailField, InputField, ReferenceDropdownField, ValueSetIds } from "commons"
import { organizationExtraTypes } from "data"
import { IdentifierConatiner } from "identifier"
import { useValueSet } from "value-set"

const OrganizationForm = ({ organizationsRoots }: Props) => {
  const {
    values: { type, partOf },
    setFieldValue,
  } = useFormikContext<Organization>()
  const [autoShippingFree, setAutoShipping] = useState(false)

  useEffect(() => {
    partOf && setFieldValue("partOf", asReference(partOf))
  }, [])

  const { codes } = useValueSet({ valueSetId: ValueSetIds.ORGANIZATION_IDENTIFIERS, enabled: true })

  const setAutoShippingFree = useCallback(
    (e: CheckboxChangeEvent) => {
      const types = !e.checked
        ? type?.filter(
            ({ coding }) =>
              coding?.[0]?.code !== organizationExtraTypes["support-auto-shipping-free"].coding?.[0]?.code,
          ) ?? []
        : [...(type ?? []), organizationExtraTypes["support-auto-shipping-free"]]

      setAutoShipping(e.checked ?? false)

      setFieldValue("type", types)
    },
    [type, setFieldValue],
  )

  // const typeValidation = (value: CodeableConcept) => (!value?.coding?.[0]?.code ? "Type is required" : null)

  return (
    <>
      <fieldset className="grid gap-5 p-3 mb-5">
        <legend>Basic Information</legend>
        <InputField field="name" label="Name" />
        {/* <CodeableConceptField field="type[0]" label="Type" codes={[]} validation={typeValidation} /> */}
        <ReferenceDropdownField label="Part of" field="partOf" options={organizationsRoots} allowClear />
        <div className="flex items-center text-sm gap-1 w-full mt-3">
          <Checkbox inputId="auto-shipping" onChange={setAutoShippingFree} checked={autoShippingFree} />
          <label htmlFor="auto-shipping" className="text-gray-500 text-sm cursor-pointer">
            {organizationExtraTypes["support-auto-shipping-free"].text}
          </label>
        </div>
      </fieldset>
      <fieldset className="relative p-fluid grid grid-cols-2 gap-5 p-3 mb-5">
        <legend>Address</legend>
        <AddressField context="Organization" parentFieldName="address[0]" />
      </fieldset>

      <fieldset className="grid gap-5 p-3 mb-5">
        <legend>Telecom</legend>
        <EmailField field="telecom[0].value" label="Email" initialValue={""} />
        <InputField type="tel" mask="+1 (999) 999-9999" unmask={true} field="telecom[1].value" label="Phone" />
      </fieldset>
      <fieldset className="grid gap-5 p-3 mb-5">
        <IdentifierConatiner field="identifier" label="Identifiers" types={codes} />
      </fieldset>
    </>
  )
}
type Props = {
  organizationsRoots?: Reference[] | undefined
}

export { OrganizationForm }
