import { Bundle, getResources, Patient, TransactionResponse } from "fhir"
import { useEffect, useState } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { formatDate } from "utils"

import { getCategory, getOrderDetail } from "../utils"

const useOrganizationOrdersAndSettings = (
  organizationId?: string,
  category?: string,
  patientId?: string,
  performer?: string,
  status?: string,
  authored?: Date,
  occurrence?: Date,
  searchText?: string,
) => {
  const { search, transaction } = useClient(IClient.AIDBOX)
  const queryKey = [
    "org-orders",
    organizationId,
    category,
    patientId,
    performer,
    status,
    authored,
    occurrence,
    searchText,
  ]

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<OrdersQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(organizationId ? { "subject:Patient.organization": organizationId } : {}),
        ...(searchText
          ? {
              _filter: `subject:Patient.termsearch eq ${searchText} or identifier eq ${searchText}`,
            }
          : {}),
        ...(performer ? { "performer:text": performer } : {}),
        category: getCategory(category),
        ...(getOrderDetail(category) ? { orderDetail: getOrderDetail(category) } : {}),
        ...(status ? { status } : {}),
        ...(authored ? { authored: formatDate(authored) } : {}),
        ...(occurrence ? { occurrence: formatDate(occurrence) } : {}),
        ...(patientId ? { subject: patientId } : {}),
        _include: "subject",
        _count: "20",
        _page: `${pageParam}`,
      })

      const bundle = await search("ServiceRequest", filters, undefined, signal)

      const patients = getResources<Patient>(bundle, "Patient")
      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        patients,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey } },
  })

  const [orgUrlHash, setOrgUrlHash] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    const fetchOrgUrlHash = async () => {
      const patients = data?.pages?.flatMap((page) => page.patients) ?? []
      const uniqueOrgsIds = patients
        .map((p) => p?.managingOrganization?.id)
        .filter((value, index, self) => self.indexOf(value) === index)

      const bundleEntry: {
        request: { method: string; url: string }
        resource: {
          resourceType: string
          parameter: (
            | { name: string; value: { Reference: { id: string | undefined; resourceType: string }; code?: undefined } }
            | { name: string; value: { code: string; Reference?: undefined } }
          )[]
        }
      }[] = []

      uniqueOrgsIds.forEach((orgId) => {
        bundleEntry.push({
          request: {
            method: "POST",
            url: "/Setting/$resolve",
          },
          resource: {
            resourceType: "Parameters",
            parameter: [
              {
                name: "owner",
                value: {
                  Reference: {
                    id: orgId,
                    resourceType: "Organization",
                  },
                },
              },
              {
                name: "code",
                value: {
                  code: "branding",
                },
              },
            ],
          },
        })
      })

      const bundle: Bundle = {
        resourceType: "Bundle",
        type: "transaction",
        entry: bundleEntry,
      }

      const res = (await transaction(bundle)) as TransactionResponse

      function getEhrUrls(bundle: TransactionResponse): string[] {
        const ehrUrls: string[] = []

        bundle?.entry?.forEach((entry) => {
          entry.resource?.entry.forEach((element) => {
            const items = element.resource.item
            items?.forEach((item) => {
              if (item?.code?.code === "ehr-url") {
                // if (item?.value && item?.value?.string) {
                ehrUrls.push(item.code.display ?? "")
                // }
                if (item.default && item.default.string) {
                  ehrUrls.push(item.default.string)
                }
              }
            })
          })
        })

        return ehrUrls
      }

      const urls = getEhrUrls(res)

      const orgUrlHashTemp: { [key: string]: string } = {}

      uniqueOrgsIds.forEach((orgId, index) => {
        orgUrlHashTemp[orgId as string] = urls[index]
      })

      setOrgUrlHash(orgUrlHashTemp)
    }

    fetchOrgUrlHash()
  }, [data?.pages])

  return {
    isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    orgUrlHash,
  }
}

type OrdersQueryData = {
  patients: Patient[]
  next: number | undefined
  total: number
}

export { useOrganizationOrdersAndSettings }
