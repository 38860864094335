import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { usersQueryKeys } from "../query-keys"

const useChangePassword = (onSettled: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const changePass = async (data: PasswordData) => {
    return operationRequest("users/reset-password", "POST", "", undefined, data)
  }

  const { mutate: changePassword, isPending: isChangingPassword } = useMutation({
    mutationFn: changePass,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      displayNotificationSuccess("Changed password successfully")
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      onSettled()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { changePassword, isChangingPassword }
}

export type PasswordData = {
  id?: string
  temporalPassword?: string
}

export { useChangePassword }
