import {
  faCalendar,
  faCancel,
  faCheck,
  faEnvelope,
  faEye,
  faGrid2,
  faMobile,
  faUserLock,
  faUserUnlock,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { User } from "fhir/fhir-extended"
import { MenuItem } from "primereact/menuitem"

import { AvatarImage, BadgeProps, StackedListItemProps } from "commons"

import { Auth2faMethods } from "../types"

const userModelBuilder = (
  user: User,
  showUserDetails: (user: User) => void,
  handleShowStatusDialog: (user: User) => void,
  handleShowRequestResetPasswordDialog: (user: User) => void,
  handleShowResetPasswordDialog: (user: User) => void,
  handleShow2faMethodDialog: (user: User, method: Auth2faMethods) => void,
  handleShowEditEmailDialog: (user: User) => void,
): StackedListItemProps => {
  const getImage = () => {
    return <AvatarImage name={user.displayName ?? ""} photoUrl={""} className="h-10 w-10 rounded-full" />
  }
  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        label: "Show Details",
        icon: <FontAwesomeIcon icon={faEye} size="sm" className="mr-2" />,
        command: () => showUserDetails(user),
      },
      {
        label: user.accountEnabled === false ? "Activate" : "Deactivate",
        icon: <FontAwesomeIcon icon={user.accountEnabled ? faCancel : faCheck} size="sm" className="mr-2" />,
        command: () => handleShowStatusDialog(user),
      },
      {
        label: "Change password",
        icon: <FontAwesomeIcon icon={faUserLock} size="sm" className="mr-2" />,
        command: () => handleShowResetPasswordDialog(user),
      },
      {
        label: "Request reset password",
        icon: <FontAwesomeIcon icon={faUserUnlock} size="sm" className="mr-2" />,
        command: () => handleShowRequestResetPasswordDialog(user),
      },
      {
        label: "Remove 2FA phone",
        icon: <FontAwesomeIcon icon={faMobile} size="sm" className="mr-2" />,
        command: () => handleShow2faMethodDialog(user, Auth2faMethods.PHONE),
      },
      {
        label: "Remove 2FA App Authenticator",
        icon: <FontAwesomeIcon icon={faGrid2} size="sm" className="mr-2" />,
        command: () => handleShow2faMethodDialog(user, Auth2faMethods.SOFTWARE),
      },
      {
        label: "Edit email",
        icon: <FontAwesomeIcon icon={faEnvelope} size="sm" className="mr-2" />,
        command: () => handleShowEditEmailDialog(user),
      },
    ]

    return menuItems
  }

  const getBadgeProps = (): BadgeProps => ({
    text: user.accountEnabled === false ? "deactivated" : " active",
    colorStyle: user.accountEnabled === false ? "red" : "green",
  })

  return {
    leftData: [
      { lineItems: [{ name: "Name", value: user.displayName }] },
      {
        lineItems: [{ name: "Create at", value: user.createdDateTime, icon: faCalendar }],
      },
    ],
    badge: getBadgeProps(),
    menu: getMenuItems(),
    image: getImage(),
  }
}

export { userModelBuilder }
