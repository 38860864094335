import { onlineManager } from "@tanstack/react-query"
import { createContext, FC, PropsWithChildren, useContext } from "react"

import { useNetworkStatus } from "../hooks"

const NetworkContex = createContext<Props | undefined>(undefined)
NetworkContex.displayName = "NetworkContex"

const NetworkContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isOnline, setIsOnline, retry } = useNetworkStatus()

  onlineManager.setEventListener((setOnline) => () => {
    return setOnline(isOnline)
  })

  return (
    <NetworkContex.Provider
      value={{
        isOnline,
        setIsOnline,
        retry,
      }}
    >
      {children}
    </NetworkContex.Provider>
  )
}

const useCheckNetworkContext = () => {
  const context = useContext(NetworkContex)

  if (context === undefined) {
    throw new Error("useCheckNetworkContext must be used within a NetworkContextProvider")
  }

  return context
}

type Props = {
  isOnline: boolean
  setIsOnline(status: boolean): void
  retry(): void
}

// eslint-disable-next-line react-refresh/only-export-components
export { NetworkContextProvider, useCheckNetworkContext }
