import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PropsWithChildren } from "react"
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom"
import { classNames } from "primereact/utils"
import { Badge } from "primereact/badge"
import { faSitemap, faUsers, faUsersCog, IconDefinition } from "@fortawesome/pro-light-svg-icons"

const BottomNav = ({ className }: Props) => {
  return (
    <div className={classNames("bottom-nav w-full flex flex-row space-around items-center z-10", className)}>
      <NavLink to="/organizations" icon={faSitemap}>
        Organizations
      </NavLink>

      <NavLink to="/practitioners" icon={faUsers}>
        Practitioners
      </NavLink>

      <NavLink to="/patients" icon={faUsers}>
        Patients
      </NavLink>

      <NavLink icon={faUsersCog} to="/users">
        Users
      </NavLink>
    </div>
  )
}

const NavLink = ({
  to,
  icon,
  children,
  showBadge,
}: PropsWithChildren<LinkProps & { icon: IconDefinition; showBadge?: boolean }>) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname })

  return (
    <Link
      to={to}
      className={classNames("grow flex flex-col items-center hover:text-blue-400 relative", {
        "text-blue-500": match,
      })}
    >
      {showBadge && !match && <Badge className="nav-badge text-blue-400/80" />}
      <FontAwesomeIcon icon={icon} size="lg" />
      <small className="font-semibold">{children}</small>
    </Link>
  )
}

type Props = {
  className?: string
}

export { BottomNav }
