import { Practitioner, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { ContactPointSystem } from "data"
import { registerErrorTrace } from "logger"
import { PractitionerApi, CustomError } from "commons"

import { practitionerQueryKeys } from "../query-keys"

const useUpdatePractitionerEmail = (onSuccess?: () => void, onSettled?: (error: Error | null) => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updatePractitioner = async ({ practitioner, email }: { practitioner: Practitioner; email: string }) => {
    const newMailTelecom = [{ system: ContactPointSystem.email, use: "home", value: email }]
    const newTelecom = practitioner.telecom ?? newMailTelecom

    if (practitioner.telecom) {
      const index = newTelecom?.findIndex((t) => t.system === ContactPointSystem.email)
      newTelecom.splice(index, index >= 0 ? 1 : 0, ...newMailTelecom)
    }

    return update<PractitionerApi & ResourceObject>("practitioners", practitioner.id!, {
      practitioner: { ...practitioner, telecom: newTelecom },
      roles: [],
    })
  }

  const { mutate: updatePractitionerEmail, isPending: isUpdating } = useMutation({
    mutationFn: updatePractitioner,

    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.all })
      onSuccess?.()
    },
    onSettled: async (_, error) => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.all })
      onSettled?.(error)
    },
  })

  return { updatePractitionerEmail, isUpdating }
}

export { useUpdatePractitionerEmail }
