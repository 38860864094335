import { useMemo } from "react"

import { useDiagnosticLaboratories, useOrganizationContext } from "organizations"
import { DialogFormContainer } from "commons"
import { getEnabledLabFacilities } from "commons/labs"

import { PDParams, LabPanel } from "../../../types"
import { getPanelInitialValues } from "../validations"
import { useEditPanel } from "../../../hooks"
import { LabPanelForm } from "./LabPanelForm"

const LabPanelFormContainer = ({ labPanel, onHide }: Props) => {
  const { currentOrganization, currentOrganizationId, enabledLabs } = useOrganizationContext()
  const { diagnosticLabs, isLoading: isLoadingLabs } = useDiagnosticLaboratories(currentOrganizationId)

  const initialValue = getPanelInitialValues(currentOrganization, labPanel)

  const performerLabsOptions = useMemo(
    () => getEnabledLabFacilities(diagnosticLabs ?? [], enabledLabs),
    [diagnosticLabs],
  )

  const { editPanel } = useEditPanel(() => {
    onHide()
  })

  const onSubmit = (data: PDParams) => {
    editPanel(data)
  }

  const isEditing = !!initialValue.id

  return (
    <>
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={onHide}
        showForm={true}
        title={"Edit test"}
        saveLabel={"Update"}
        initialValue={{
          ...initialValue,
        }}
        useFormik
        isLoadingData={isLoadingLabs}
      >
        <LabPanelForm performerLabsOptions={performerLabsOptions} isEditing={isEditing} />
      </DialogFormContainer>
    </>
  )
}

type Props = {
  labPanel?: LabPanel
  onHide(): void
}

export { LabPanelFormContainer }
