import { Coding, ParametersParameterArrayValue, Patient, Practitioner, PractitionerRole, Reference } from "fhir"

import { ResourceStatusCode } from "data"

export enum MenuStyles {
  /** Show menu as in a dropdown */
  Dropdown,
  /** Show menu items inline */
  ActionItems,
  /** Only show first menu item with faExternalLink icon */
  ExternalAction,
}

export type PractitionerApi = {
  practitioner: Practitioner
  status?: ResourceStatusCode
  roles?: PractitionerRole[]
}

export type PatientApi = {
  patient: Patient
  status: ResourceStatusCode
}

export type PractitionerInfo = {
  practitioner: Practitioner
  practitionerRole?: PractitionerRole
  practitionerRoleRef?: Reference
  practitionerRoles?: PractitionerRole[]
}

export enum RoundedStyles {
  None = 0,
  Left = 1,
  Right = 2,
  Both = Left | Right,
  Full_Left = 4,
  Full_Right = 8,
  Full = Full_Left | Full_Right,
}

export type FormatTypes =
  | "ISO_8601_DATE"
  | "ISO_8601_DATETIME"
  | "SHORT_DATE"
  | "SHORT_DATETIME"
  | "LONG_DATE"
  | "LONG_DATETIME"
  | "FULL_DATETIME"
  | "TIME"
  | "SHORT_DATETIME_W_SEC"
  | "LONG_MONTH_YEAR"
  | "SHORT_MONTH_YEAR"
  | "LONG_DATETIME_WITH_TIMEZONE"
  | "SHORT_DATETIME_WITH_TIMEZONE"

export type CustomError = Error & { cause: { name: string; message: string; traceId?: string } }

export type GroupedChargeItemDefinitionsCodes = {
  billToPracticeOrInsuranceCIDs?: (ParametersParameterArrayValue | Coding)[]
  billToPatientCIDs?: (ParametersParameterArrayValue | Coding)[]
}

export enum ValueSetIds {
  ICD10 = "icd-10",
  ALLERGY_CODE = "ch-allergy-intolerance-code",
  FAMILY_MEMBER = "v3-FamilyMember",
  RXNORM = "rxnorm",
  USPS_STATES = "usps-state",
  RELATIONSSHIP_TYPE = "relatedperson-relationshiptype",
  SERVICE_DELIVERY_LOCATION_TYPE = "v3-ServiceDeliveryLocationRoleType",
  DEVICE_TYPE = "device-type",
  MEDICATION_FORM_CODES = "medication-form-codes",
  REFERENCED_ITEM_CATEGORY = "referenced-item-category",
  AE_SUBTYPE = "ch-audit-event-subtype",
  CONTINENTAL_USA_STATES = "continental-usa-states",
  BODY_SITES = "body-sites",
  TASK_CODE = "ch-task-code",
  PACKAGING_QTY_UNIT = "packaging-quantity-unit",
  ORGANIZATION_IDENTIFIERS = "organization-identifiers",
  PRACTITIONER_ROLE = "practitioner-role",
  PRACTITIONER_IDENTIFIERS = "practitioner-identifiers",
}
