import { Reference } from "fhir"
import { FieldArrayRenderProps, useFormikContext } from "formik"

import { DropdownField, InputField, NumberField } from "commons"
import { genders } from "data"
import { useOrganizationContext } from "organizations"
import { substractPrice, sumPrice } from "utils"

import { LabPanel, PDParams } from "../../../types"
import { LabComboTestFieldArray } from "./LabComboTestFieldArray"
import { labPanelModelBuilder } from "./labPanelModelBuilder"
import TestAutocomplete from "./TestAutocomplete"

const LabComboForm = ({ performerLabsOptions, isEditing, isLoadingData, editFee }: Props) => {
  const { currentOrganizationId } = useOrganizationContext()

  const {
    values: { fee, basePrice, laboratory },
    setFieldValue,
  } = useFormikContext<PDParams>()

  return (
    <div className="divide-gray-200 divide-y pt-1.5">
      <div className="p-fluid grid gap-2">
        <InputField field="title" placeholder="Add a name" disabled={editFee} />
        <div className="p-fluid grid grid-cols-2 gap-2">
          <DropdownField
            optionLabel="display"
            optionValue=""
            field="laboratory"
            placeholder="Select Laboratory"
            options={performerLabsOptions}
            dataKey="id"
            disabled={isEditing || editFee}
          />
          <DropdownField
            placeholder="Select a gender"
            options={genders}
            optionLabel="label"
            optionValue="code"
            showClear
            field="gender"
            dataKey="code"
            disabled={isEditing || editFee}
          />
        </div>
        <LabComboTestFieldArray
          field="tests"
          emptyDataMessage="Get started by adding a new test"
          itemModelBuilder={labPanelModelBuilder}
          onRemoveItem={({ price }) => setFieldValue("basePrice", substractPrice(basePrice, price).sub.toNumber())}
          askDeleteConfirmation={isEditing}
          disabled={isLoadingData || editFee || isEditing}
          isLoading={isLoadingData}
        >
          {({
            push,
            form: {
              values: { tests },
            },
          }: FieldArrayRenderProps) => (
            <TestAutocomplete
              placeholder="Add new lab test"
              className="mb-10"
              disabled={!laboratory?.id || editFee || isEditing}
              handleSelect={(item) => {
                if (
                  !(tests as LabPanel[] | undefined)?.some(
                    ({ planDefinition }) => planDefinition.id === item?.planDefinition?.id,
                  )
                ) {
                  setFieldValue("basePrice", sumPrice(basePrice, item?.price ?? 0).sum.toNumber())
                  push(item)
                }
              }}
              organizationId={currentOrganizationId}
              performerLab={laboratory?.id}
              unselectablePanels={tests}
            />
          )}
        </LabComboTestFieldArray>
      </div>
      <div className="flex justify-center flex-col flex-1 space-y-3 mt-6">
        <div className="pt-2 flex justify-between items-center text-sm mb-2">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice.toFixed(2)}</p>
        </div>
        <div className="divide-gray-200 divide-y flex justify-center flex-col flex-1 space-y-3">
          <div className="pb-1 flex justify-between items-center">
            <span className="font-semibold text-sm">Fee</span>
            <NumberField field="fee" horizontal min={0} placeholder="$0.00" maxFractionDigits={2} showButtons={false} />
          </div>
          <div className="pt-2 flex justify-between">
            <span className="font-semibold text-sm">Total</span>
            <span className="font-semibold text-sm">${sumPrice(basePrice, fee ?? 0).sum.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

type Props = {
  performerLabsOptions: Reference[]
  isEditing?: boolean
  isLoadingData?: boolean
  editFee?: boolean
}

export { LabComboForm }
