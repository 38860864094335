import { useMemo } from "react"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-light-svg-icons"
import { useParams } from "react-router-dom"

import { AuditEventsView } from "audit-events"
import { ModulesId, useScreenContext } from "commons"
import { useOrganization } from "organizations"
import { useAppModuleContext } from "internals"

import { Devices } from "./Devices"
import { LabsContainer } from "./labs/LabsContainer"
import { MasterDetailSection, SectionItem } from "./MasterDetailSection"
import { MedicationsContainer } from "./meds/MedicationsContainer"
import { OperatingHours } from "./OperatingHours"
import { PaymentContainer } from "./PaymentContainer"
import { Rooms } from "./Rooms"
import { SettingsView } from "./SettingsView"

const AdministrationView = () => {
  const { isModuleActive } = useAppModuleContext()
  const { organizationId } = useParams()
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const { organization, isLoading } = useOrganization(organizationId)

  const AdministrationSections = useMemo(() => {
    const sections = [
      {
        id: "settings",
        title: "Settings",
        className: "flex-1 overflow-auto",
        element: <SettingsView />,
      },
      {
        id: "hours-op",
        title: "Hours of operation",
        className: "overflow-hidden",
        element: <OperatingHours />,
      },
      {
        id: "rooms",
        title: "Rooms",
        className: "flex-1",
        element: <Rooms />,
      },
      {
        id: "devices",
        title: "Devices",
        className: "flex-1",
        element: <Devices />,
      },
      {
        id: "aes",
        title: "Audit Log",
        className: "flex-col overflow-auto",
        element: <AuditEventsView />,
      },
      {
        id: "payment",
        title: "Payment",
        className: "overflow-auto",
        element: <PaymentContainer />,
      },
    ]

    if (isModuleActive(ModulesId.LABSORDER)) {
      sections.push({
        id: "labs",
        title: "Lab Configuration",
        className: "overflow-auto",
        element: <LabsContainer />,
      })
    }

    if (isModuleActive(ModulesId.MEDICATIONR) || isModuleActive(ModulesId.EPRESCRIBE)) {
      sections.push({
        id: "medications",
        title: "Medication Configuration",
        className: "overflow-auto",
        element: (
          <MedicationsContainer
            isRxActive={isModuleActive(ModulesId.EPRESCRIBE)}
            isNutrasActive={isModuleActive(ModulesId.MEDICATIONR)}
          />
        ),
      })
    }

    return sections
  }, [isModuleActive])

  return (
    <>
      <div className={classNames("bg-white", { "pb-3 pt-6": isSmallScreen })}>
        <div className="border-b shadow-sm">
          <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
            {isSmallScreen && (
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1 ml-4"
                onClick={() => setSidebarCollapsed(false)}
              />
            )}
            <div className={classNames("mx-6", { "flex flex-col items-end": isSmallScreen, "pb-5": !isSmallScreen })}>
              <h6 className="font-medium text-lg">Administration</h6>
              {!isLoading && <p className="text-sm text-gray-500 mb-2">{organization.organization.name}</p>}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classNames({ "pb-16": isSmallScreen })}> */}
      <MasterDetailSection>
        {AdministrationSections.map(({ element, id, ...sectionItemProps }) => (
          <SectionItem key={id} id={id} {...sectionItemProps}>
            {element}
          </SectionItem>
        ))}
      </MasterDetailSection>
      {/* </div> */}
    </>
  )
}

export { AdministrationView }
