import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { Button } from "primereact/button"

const PractitionerEmptyState = ({ onAdd }: Props) => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faUserPlus} size="3x" className="text-gray-400" />
      <h6 className="font-semibold text-xl pt-4 pb-2">No practitioners</h6>
      <p className="text-gray-400 pb-6">Get started by creating a new practitioner</p>

      <Button
        label="New Practitioner"
        icon={<FontAwesomeIcon icon={faPlus} className="mr-3" />}
        className="block outline-none ring-0 text-white p-3 button-primary"
        onClick={onAdd}
      />
    </div>
  </div>
)

type Props = {
  onAdd(): void
}

export { PractitionerEmptyState }
