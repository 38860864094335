import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { organizationQueryKeys } from "organizations"
import { datadogLogs, registerErrorTrace } from "logger"

const useDeleteRoom = (onSuccess?: () => void, onSettled?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const deleteRoom = async (roomId: string) => remove("Location", roomId)

  const { mutate: removeRoom, isPending: isDeleting } = useMutation({
    mutationFn: deleteRoom,
    onError: (error: CustomError, roomId) => {
      displayNotificationError(registerErrorTrace(error, { roomId }))
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.currentOrganization.all })
      displayNotificationSuccess("Room deleted successfully!")
      datadogLogs.logger.info("Room deleted successfully!", { data })
      onSuccess?.()
    },
    onSettled,
  })

  return { removeRoom, isDeleting }
}

export { useDeleteRoom }
