// import { SettingItemArray } from "fhir"
import { FC, PropsWithChildren, createContext, useCallback } from "react"

import { useModulesVisibility } from "administration"

const AppModuleContext = createContext<State | undefined>(undefined)
AppModuleContext.displayName = "AppModuleContext"

const AppModuleProvider: FC<PropsWithChildren<Props>> = ({ children, orgId }) => {
  const { modulesSettings } = useModulesVisibility(orgId)

  const isModuleActive = useCallback(
    (module: string) =>
      !modulesSettings?.[`${module}-enabled`] || modulesSettings?.[`${module}-enabled`]?.value?.boolean === true,
    [modulesSettings],
  )

  const values = {
    isModuleActive,
  }
  return <AppModuleContext.Provider value={values}>{children}</AppModuleContext.Provider>
}

type State = {
  isModuleActive(module: string): boolean
}

type Props = {
  orgId: string
}

export { AppModuleContext, AppModuleProvider }
