import { Account, Bundle, Device, Parameters, getResource } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organizations/query-keys"

import { readerQueryKeys } from "../query-keys"
import { CustomError } from "../../types"

const useRegisterReader = (
  orgId: string,
  onSuccess?: (data: { readerAccount: Account; readerDevice: Device }) => void,
  onSettled?: () => void,
) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient(IClient.AIDBOX)
  /* TODO: Delete default wpe-simulated code  when real values available*/
  const readerSetup = async ({
    readerLabel,
    registrationCode = "simulated-wpe",
  }: {
    readerLabel: string
    registrationCode: string
  }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "label",
          value: {
            string: readerLabel,
          },
        },
        {
          name: "registration-code",
          value: {
            string: registrationCode,
          },
        },
      ],
    }

    const resBundle = await operationRequest<Bundle>("Organization", "POST", "register-reader", orgId, parameters)
    const readerAccount = getResource<Account>(resBundle, "Account")
    const readerDevice = getResource<Device>(resBundle, "Device")

    return { readerAccount, readerDevice }
  }

  const {
    mutate: registerReader,
    isPending: isRegisteringReader,
    data,
  } = useMutation({
    mutationFn: readerSetup,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: readerQueryKeys.all(orgId) })
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.account.current(orgId) })
      displayNotificationSuccess(`Reader has been registered successfully.`)
      onSuccess?.(data)
    },
    onError: (error: CustomError, variables) => {
      displayNotificationError(registerErrorTrace(error, { variables }))
    },
    onSettled,
  })

  return { link: data, registerReader, isRegisteringReader }
}

export { useRegisterReader }
