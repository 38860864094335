import { Organization } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useUpdateOrganization = (onSettled: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updatedOrganization = async (organization: Organization) => {
    if (!organization.id) {
      throw new Error(`No organization id provided for ${organization.name ?? "No name"}`)
    }

    return update("organizations", organization.id, organization)
  }

  const { mutate: updateOrganization, isPending: isUpdating } = useMutation({
    mutationFn: updatedOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      displayNotificationSuccess("Organization updated successfully!")
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateOrganization, isUpdating }
}

export { useUpdateOrganization }
