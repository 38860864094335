import * as Yup from "yup"
import { AccountCreditCardArray, Organization } from "fhir"

import { CreditCardFormData } from "../types"

const getInitialValues = (org?: Organization, creditCard?: AccountCreditCardArray): CreditCardFormData => ({
  cardHolderName: creditCard?.cardHolderName ?? org?.name,
  billingAddress: creditCard?.billingAddress ?? org?.address?.[0],
  expirationYear: creditCard?.expirationYear,
  expirationMonth: creditCard?.expirationMonth,
  last4Digits: creditCard?.last4Digits,
  number: creditCard?.number,
  type: creditCard?.type,
  cvv: creditCard?.cvv,
})

const sanitize = ({ ...creditCard }: CreditCardFormData, isNew = false): AccountCreditCardArray => {
  if (isNew) {
    delete creditCard.expirationDate
    delete creditCard.number
    delete creditCard.cvv
  }
  return creditCard
}

const creditCardValidationSchema = Yup.object({
  cardHolderName: Yup.string().required("Holder name is required"),
})

export { creditCardValidationSchema, getInitialValues, sanitize }
