import { CodeableConcept, Coding } from "fhir"
import { ErrorMessage, Field, FieldProps } from "formik"
import { Dropdown, DropdownProps } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { FC, ReactNode } from "react"

const CodeableConceptField: FC<Props> = ({
  field,
  codes,
  label,
  className,
  autoSelect,
  horizontal,
  inputClassName,
  itemTemplate,
  validation,
  onChange,
  ...dropdownProps
}) => {
  const firstOption = autoSelect ? codes[0] : undefined

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps<CodeableConcept>) => (
        <div
          className={classNames(
            "field space-y-2 relative",
            horizontal ? "inline-flex justify-between" : "flex flex-col",
            className,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700 mt-3", { "mr-3 mb-0 mt-2": horizontal })}
            >
              {label}
            </label>
          )}
          <Dropdown
            id={name}
            name={name}
            optionLabel="display"
            options={codes}
            itemTemplate={itemTemplate}
            onChange={({ value }) => {
              setFieldValue(name, { coding: [value], text: value.display } as CodeableConcept)
              onChange?.({ coding: [value], text: value.display } as CodeableConcept)
            }}
            value={value?.coding?.[0] ?? firstOption}
            className={classNames(
              "p-inputtext-sm",
              {
                "p-invalid": touched && error,
                horizontal: horizontal,
              },
              inputClassName,
            )}
            {...dropdownProps}
          />
          <ErrorMessage name={name}>
            {(msg) => <small className="p-error absolute -bottom-6">{msg}</small>}
          </ErrorMessage>
        </div>
      )}
    </Field>
  )
}

type Props = {
  field: string
  codes: Coding[]
  label?: string
  className?: string
  autoSelect?: boolean
  horizontal?: boolean
  inputClassName?: string
  itemTemplate?: ReactNode | ((option: Coding) => ReactNode)
  validation?(code: CodeableConcept): void
  onChange?(code?: CodeableConcept): void
} & DropdownProps

export { CodeableConceptField }
