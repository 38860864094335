import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faSearch } from "@fortawesome/pro-light-svg-icons"

const UsersEmptyState = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faSearch} size="3x" className="text-gray-400" />
      <h6 className="font-semibold text-xl pt-4 pb-2">No users found</h6>
    </div>
  </div>
)

export { UsersEmptyState }
