import { codeableConceptAsString, PractitionerRole } from "fhir"
import { faBuilding } from "@fortawesome/pro-solid-svg-icons"

import { InformationCardContainer, StackedListItem, StackedListItemProps } from "commons"
import { useLoginContext } from "security"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSitemap } from "@fortawesome/pro-light-svg-icons"

const OrganizationsCard = () => {
  const { logedInPractitioner } = useLoginContext()
  const { roles } = logedInPractitioner

  return (
    <InformationCardContainer title="Organizations">
      <>
        {!roles?.length ? (
          <div className="flex items-center justify-center w-full  mb-2">
            <div className="flex flex-col items-center">
              <FontAwesomeIcon icon={faSitemap} size="2x" className="text-gray-400" />
              <h6 className="font-semibold text-sm pt-4 pb-2">No organization</h6>
            </div>
          </div>
        ) : (
          roles.map((role, index) => {
            return <StackedListItem key={role.id ?? index} modelData={organizationModel(role)} />
          })
        )}
      </>
    </InformationCardContainer>
  )
}

const organizationModel = (pr: PractitionerRole): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Organization", value: pr.organization?.display, icon: faBuilding }],
    },
    {
      lineItems: [
        {
          name: "Role",
          value: `Role: ${codeableConceptAsString(pr.code?.[0])}`,
        },
      ],
    },
  ],
})

export { OrganizationsCard }
