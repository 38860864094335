import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { InputText } from "primereact/inputtext"
import { getFirstEmail, Organization } from "fhir"

import { useOrganizationOnboard, useUpdateOrganizationEmail } from "../hooks"

const OrganizationOnboardModal = ({ organization, show, onCancel }: Props) => {
  const defaultEmail = useMemo(() => getFirstEmail(organization?.telecom) ?? "", [organization?.telecom])
  const { onboard, isOnboarding } = useOrganizationOnboard(onCancel)

  const [email, setEmail] = useState(defaultEmail)

  const handleOnboard = useCallback(() => {
    if (organization?.id) {
      onboard({ orgId: organization.id, email })
    }
  }, [email, onboard, organization?.id])

  const { updateOrganizationEmail, isUpdating } = useUpdateOrganizationEmail(handleOnboard)

  const defaultEmailRef = useRef(defaultEmail)
  const isEmailValid = emailValidation(email)
  const isLoading = isUpdating || isOnboarding

  const handleSubmit = useCallback(() => {
    if (email !== defaultEmail) {
      updateOrganizationEmail({ email, organization })
    } else {
      handleOnboard()
    }
  }, [defaultEmail, email, handleOnboard, organization, updateOrganizationEmail])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value

      setEmail((prev) => {
        if (text === defaultEmail) {
          defaultEmailRef.current = defaultEmail
        } else {
          defaultEmailRef.current = prev
        }

        return text
      })
    },
    [defaultEmail],
  )

  return (
    <Dialog
      header={`Onboarding to ${organization?.name}`}
      visible={show}
      className="w-full md:w-[70%] lg:w-[30%] m-2 "
      onHide={onCancel}
      footer={
        <div className="mt-2">
          <Button label="Cancel" disabled={isOnboarding} className="button-default" onClick={onCancel} />
          <Button
            label="Accept"
            className="button-primary"
            loading={isLoading}
            disabled={isLoading || !isEmailValid || isOnboarding}
            onClick={handleSubmit}
          />
        </div>
      }
    >
      <div className="p-inputgroup mt-5">
        <span className="p-inputgroup-addon">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
        <InputText type="email" placeholder="Type an email" required value={email} onChange={onChange} />
      </div>
      <div className="flex flex-row items-start h-2 mt-1">
        <small>{!isEmailValid && <p className="p-error absolute">A valid email address is required</p>}</small>
      </div>
      <p className="mt-5 space-x-1">
        <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500" />
        <span className="text-slate-500">
          We will use this email only to send you the link required to start the onboarding process
        </span>
      </p>
    </Dialog>
  )
}
const emailValidation = (text: string) => text && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(text)

type Props = {
  organization?: Organization
  show: boolean
  onCancel: () => void
}

export { OrganizationOnboardModal }
