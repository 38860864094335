import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { MultiSelectField } from "commons"
import { subsNotificationType } from "data"

import { FilterProps } from "../types"

const SubsNotificationFilters = ({ initialValues, onSearch, onClearFilters }: Props) => {
  const renderForm = ({ isSubmitting }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <div className="flex justify-end items-center p-input-icon-right ">
        <MultiSelectField
          field="status"
          label="Status"
          optionLabel="display"
          options={subsNotificationType}
          optionValue="code"
          horizontal
          className="grid-cols-10 w-full"
          inputClassName="slashed text-sm"
        />
      </div>

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !initialValues?.status}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues ?? {}} onSubmit={onSearch}>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues?: FilterProps
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { SubsNotificationFilters }
