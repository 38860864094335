import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { ReactNode } from "react"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"

import { Button } from "commons"
import { strCapitalize } from "utils"

const EmptyMessage = ({
  icon,
  itemTitle,
  message,
  subMessage = true,
  customEmptyAddButton,
  action,
  actionText,
  actionIcon,
  isLoading,
  disabled,
  className,
  addButtonClassName,
}: Props) => (
  <div className={classNames("text-center m-auto", className)}>
    <FontAwesomeIcon icon={icon} size="2x" className="text-slate-400 mb-2" />
    <h3 className="text-sm font-semibold text-gray-900">
      {message ?? `No ${strCapitalize(pluralize(itemTitle ?? "item", 2))} Found`}
    </h3>
    {!!subMessage && (
      <p className="mt-1 text-sm text-gray-500">
        {typeof subMessage === "string"
          ? subMessage
          : `Get Started by Creating a New ${strCapitalize(itemTitle ?? "item")}`}
      </p>
    )}
    {(customEmptyAddButton || ((actionText || itemTitle) && action)) && (
      <div className="mt-6">
        {customEmptyAddButton ?? (
          <Button
            icon={actionIcon ?? faPlus}
            label={actionText ?? `Create ${itemTitle?.toLowerCase() ?? "item"}`}
            onClick={action}
            disabled={disabled}
            loading={isLoading}
            className={addButtonClassName}
          />
        )}
      </div>
    )}
  </div>
)

type Props = {
  icon: IconDefinition
  itemTitle?: string
  message?: string
  subMessage?: boolean | string
  customEmptyAddButton?: ReactNode
  action?(event?: React.SyntheticEvent<Element, Event> | null | undefined): void
  actionText?: string
  actionIcon?: IconDefinition
  isLoading?: boolean
  disabled?: boolean
  className?: string
  addButtonClassName?: string
}

export { EmptyMessage }
