import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient, IClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { patientQueryKey } from "../query-keys"

const useInvitePatient = (patId: string, patName: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient(IClient.AIDBOX)

  const invite = async () => {
    return operationRequest("Patient", "POST", "invite", patId)
  }

  const { mutate: invitePatient, isPending: isInviting } = useMutation({
    mutationFn: invite,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: patientQueryKey.invite })
      onSuccess?.()
      displayNotificationSuccess(`In order to invite ${patName}, we are trying to stablish communication via email.`)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: patientQueryKey.invite })
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { invitePatient, isInviting }
}

export { useInvitePatient }
