import { AppModuleProvider } from "modules"
import { useParams } from "react-router-dom"

import { AdministrationView } from "./components/AdministrationView"
import { OrganizationProvider } from "organizations"

const AdministrationContainer = () => {
  const { organizationId } = useParams()
  return (
    <AppModuleProvider orgId={organizationId as string}>
      <OrganizationProvider organizationId={organizationId as string}>
        <AdministrationView />
      </OrganizationProvider>
    </AppModuleProvider>
  )
}

export { AdministrationContainer }
