import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { OrganizationApi } from "../types"
import { organizationQueryKeys } from "../query-keys"

const COUNT_PER_PAGE = 20

const useOrganizations = (filter = "", active?: string) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.list(filter, active)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    OrganizationQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filters = new URLSearchParams({
        ...(filter ? { name: filter } : {}),
        ...(active ? { active: active } : {}),
        _count: COUNT_PER_PAGE.toString(),
        _sort: "name",
        _page: `${pageParam}`,
      })

      const bundle = await search("organizations", filters)

      const organizations = bundle.entry as OrganizationApi[]

      const next = (pageParam as number) * COUNT_PER_PAGE < (bundle?.total ?? 0) ? (pageParam as number) + 1 : undefined

      return { organizations, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, filter, active } },
  })

  const { organizationGroups, count } = useMemo(() => {
    let count = 0

    const groupMap = data?.pages?.reduce((map, { organizations }) => {
      count += organizations.length

      organizations.forEach((org) => {
        const key = org?.organization.name?.[0] ?? "other"
        const value = map.get(key)

        if (value) {
          value.push(org)
        } else {
          map.set(key, [org])
        }
      })

      return map
    }, new Map<string, OrganizationApi[]>())

    return {
      organizationGroups: groupMap
        ? Array.from(groupMap.entries()).map(([key, items]) => ({
            key,
            name: key,
            items,
          }))
        : [],
      count,
    }
  }, [data?.pages])

  return {
    organizationGroups,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type OrganizationQueryData = { organizations: OrganizationApi[]; next: number | undefined; total: number }

export { useOrganizations }
