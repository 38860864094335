import { User } from "fhir/fhir-extended"
import { ObjectSchema } from "yup"
import { AnyObject } from "yup/lib/types"
import { AssertsShape, TypeOfShape } from "yup/lib/object"

export type FilterProps = {
  searchText?: string
  status: string
  after: string
  before: string
}

export type UserActionsProps = {
  showDialog?: boolean
  user?: User
  dialogTitle?: DialogTitle
  content?: JSX.Element
  initialValue?: object
  validateSchema?: ObjectSchema<AnyObject, AnyObject, TypeOfShape<AnyObject>, AssertsShape<AnyObject>>
}
export enum DialogTitle {
  editEmail = "Edit email",
  inviteUser = "Invite user",
  changePassword = "Change passsword",
}

export enum Auth2faMethods {
  NONE,
  PHONE,
  SOFTWARE,
}
