import { useState, useRef } from "react"
import { Field, FieldProps, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { InputMask } from "primereact/inputmask"
import Cleave from "cleave.js/react"

import visa from "images/visasm.png"
import masterCard from "images/mastercard.png"
import americanExpress from "images/amex.png"
import genericCard from "images/gcard.png"
import dinersClub from "images/dinersclub.png"
import discover from "images/discover.png"
import jcb from "images/jcb.png"
import invalidCard from "images/invalidcc.png"

import { CreditCardFormData } from "../types"
import "./CreditCardNumberField.css"

const CreditcardNumberField = ({ field, label, disabled = false, className, horizontal = false }: Props) => {
  const [focused, setFocused] = useState(false)
  const [type, setType] = useState<CardType>(genericType)
  const [validNumber, setValidNumber] = useState(true)
  const [validExpDate, setValidExpDate] = useState(true)
  const [validCvv, setValidCvv] = useState(true)
  const { errors, setFieldValue, touched } = useFormikContext<CreditCardFormData>()

  const numberRef = useRef<string>("")
  const expRef = useRef<InputMask>(null)
  const cvvRef = useRef<InputMask>(null)

  const handleNumberChange = (value: string) => {
    setFieldValue("last4Digits", value?.slice(-4))
    if (value.length && value === numberRef.current) {
      expRef.current?.focus()
      return
    }
    numberRef.current = value
    const type = getType(value)
    setValidNumber(true)
    if (type === genericType) {
      setValidNumber(true)
    } else {
      setFieldValue("type", type.code)
    }
    setType(type)
  }

  const handleExpirationDateChange = (value: string) => {
    const [month, year] = value?.split("/") ?? []
    setFieldValue("expirationMonth", month)
    setFieldValue("expirationYear", `20${year}`)
  }

  const validateExpDate = (date: string) => {
    if (!date) return false
    let result = true
    const currentDate = new Date()
    const [month, year] = date.split("/")

    if (parseInt(month) > 12) result = false
    if (
      parseInt("20" + year) < currentDate.getFullYear() ||
      (parseInt("20" + year) === currentDate.getFullYear() && parseInt(month) < currentDate.getMonth() + 1)
    ) {
      result = false
    }

    return result
  }

  const getError = () => {
    if (!touched.number && !touched.expirationMonth && !touched.cvv) return ""
    if (!errors.number && !errors.expirationMonth && !errors.cvv) return ""
    return (
      "Invalid " + [errors.number, errors.expirationMonth, errors.cvv].flatMap((item) => (item ? item : [])).join(", ")
    )
  }

  return (
    <>
      {label && (
        <label
          htmlFor={field}
          className={classNames("text-sm font-medium text-gray-700 h-3", { "mr-3 mb-0 mt-2": horizontal })}
        >
          {label}
        </label>
      )}
      <div
        className={classNames("p-inputtext h-[44px] flex justify-between pr-1 min-w-[310px] cc-input", {
          invalid: getError(),
          focusedgroup: focused,
        })}
      >
        <div className="flex sm:min-w-fit">
          <img
            src={validNumber ? type.icon : invalidCard}
            alt="creditCard.type"
            className="h-8 w-auto my-auto"
            title="Type"
          />

          <Field name={field} validate={(number: string) => (!checkCreditCard(number).success ? "number" : undefined)}>
            {({ field: { name, value, onChange } }: FieldProps) => (
              <div
                className={classNames(
                  "relative",
                  horizontal && "inline-flex justify-between",
                  !horizontal && "flex flex-col",
                  className,
                )}
              >
                <Cleave
                  options={{
                    creditCard: true,
                    delimiter: "-",
                  }}
                  name={name}
                  value={value}
                  placeholder="CARD NUMBER"
                  autoComplete="off"
                  disabled={disabled}
                  style={{ width: "180px" }}
                  className={classNames("custommask p-inputmask p-inputtext p-inputtext-sm m-0 mr-1 border-0 h-full", {
                    horizontal: horizontal,
                    invalidvalue: !validNumber,
                  })}
                  onChange={(e) => {
                    handleNumberChange(e.target.rawValue)
                    onChange(e)
                  }}
                  onBlur={(e) => {
                    const result = checkCreditCard(e.target.value)
                    setValidNumber(result.success)
                    setFocused(false)
                  }}
                  onFocus={() => {
                    setFocused(true)
                  }}
                />
              </div>
            )}
          </Field>
        </div>
        <Field
          name="expirationDate"
          validate={(expDate: string) => (!validateExpDate(expDate) ? "expiration date" : undefined)}
        >
          {({ field: { name, value, onChange } }: FieldProps) => (
            <div
              className={classNames(
                "field relative h-full ",
                horizontal && "inline-flex justify-between",
                !horizontal && "flex flex-col",
                className,
              )}
            >
              <InputMask
                ref={expRef}
                type="text"
                mask="99/99"
                autoClear={false}
                id={name}
                name={name}
                slotChar="_"
                placeholder="MM/YY"
                onChange={(e) => {
                  handleExpirationDateChange(e.target.value ?? "")
                  onChange(e)
                }}
                onComplete={() => cvvRef.current?.focus()}
                onBlur={(e) => {
                  const result = validateExpDate(e.target.value)
                  setValidExpDate(result)
                  setFocused(false)
                }}
                onFocus={() => {
                  setValidExpDate(true)
                  setFocused(true)
                }}
                value={value}
                disabled={disabled}
                className={classNames("p-inputtext-sm custommask border-0 m-0 mr-1 h-full w-20", {
                  horizontal: horizontal,
                  invalidvalue: !validExpDate,
                })}
              />
            </div>
          )}
        </Field>
        <Field name="cvv" validate={(cvv: string) => (!cvv || cvv.length !== type.cvvlength ? "CVV" : undefined)}>
          {({ field: { name, value, onChange } }: FieldProps) => (
            <div
              className={classNames(
                "field relative h-full",
                horizontal && "inline-flex justify-between",
                !horizontal && "flex flex-col",
                className,
              )}
            >
              <InputMask
                type="text"
                mask={"9".repeat(type.cvvlength)}
                id={name}
                name={name}
                autoClear={false}
                unmask={true}
                slotChar="_"
                placeholder="CVV"
                ref={cvvRef}
                onChange={onChange}
                onComplete={(e) => setValidCvv((e.value as string).length === type.cvvlength)}
                onBlur={(e) => {
                  setValidCvv(e.target.value.length === type.cvvlength)
                  setFocused(false)
                }}
                onFocus={() => setFocused(true)}
                value={value}
                disabled={disabled}
                className={classNames("p-inputtext-sm custommask w-full border-0 m-0 h-full", {
                  horizontal: horizontal,
                  invalidvalue: !validCvv,
                })}
              />
            </div>
          )}
        </Field>
      </div>
      <div className="flex items-start p-error h-2 bg-red -mt-3">{getError() && <small>{getError()}</small>}</div>
    </>
  )
}

const cards: CardType[] = [
  {
    name: "Visa",
    code: "V",
    length: [13, 16],
    prefixes: "4",
    checkdigit: true,
    mask: "9999-9999-9999-9999",
    icon: visa,
    cvvlength: 3,
  },
  {
    name: "MasterCard",
    code: "MC",
    length: [16],
    prefixes: "51,52,53,54,55",
    checkdigit: true,
    mask: "9999-9999-9999-9999",
    icon: masterCard,
    cvvlength: 3,
  },
  {
    name: "DinersClub",
    code: "DC",
    length: [14, 16],
    prefixes: "36,38",
    checkdigit: true,
    mask: "9999-9999-9999-99",
    icon: dinersClub,
    cvvlength: 3,
  },
  {
    name: "AmEx",
    code: "AE",
    length: [15],
    prefixes: "34,37",
    checkdigit: true,
    mask: "9999-999999-99999",
    icon: americanExpress,
    cvvlength: 4,
  },
  {
    name: "Discover",
    code: "D",
    length: [16],
    prefixes: "6011,622,64,65",
    checkdigit: true,
    mask: "9999-9999-9999-9999",
    icon: discover,
    cvvlength: 3,
  },
  {
    name: "JCB",
    code: "JCB",
    length: [16],
    prefixes: "35",
    checkdigit: true,
    mask: "9999-999999999999",
    icon: jcb,
    cvvlength: 3,
  },
]

const genericType: CardType = {
  name: "Generic",
  code: "G",
  length: [19],
  prefixes: "",
  checkdigit: true,
  mask: "9999999999999999999",
  icon: genericCard,
  cvvlength: 3,
}

const getType = (cardnumber: string): CardType => {
  if (!cardnumber) return genericType

  let prefixValid = false

  for (let i = 0; i < cards.length; i++) {
    const prefix = cards[i].prefixes.split(",")

    for (let j = 0; j < prefix.length; j++) {
      const exp = new RegExp("^" + prefix[j])
      if (exp.test(cardnumber)) {
        prefixValid = true
      }
    }

    if (prefixValid) {
      return cards[i]
    }
  }

  return genericType
}

const luhnCheck = (val: string) => {
  let checksum = 0 // running checksum total
  let j = 1 // takes value of 1 or 2

  // Process each digit one by one starting from the last
  for (let i = val.length - 1; i >= 0; i--) {
    let calc = 0
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(val.charAt(i)) * j

    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1
      calc = calc - 10
    }

    // Add the units element to the checksum total
    checksum = checksum + calc

    // Switch the value of j
    if (j === 1) {
      j = 2
    } else {
      j = 1
    }
  }

  //Check if it is divisible by 10 or not.
  return checksum % 10 === 0
}

const checkCreditCard = (cardnumber: string) => {
  const ccErrors = [
    "Unknown card type",
    "No card number provided",
    "Credit card number is in invalid format",
    "Credit card number is invalid",
    "Credit card number has an inappropriate number of digits",
  ]

  const response = (success: boolean, message: string | null = null, type: string | null = null) => ({
    message,
    success,
    type,
  })

  if (!cardnumber || cardnumber.length === 0) {
    return response(false, ccErrors[1])
  }

  cardnumber = cardnumber.replace(/-/g, "")

  if (!luhnCheck(cardnumber)) {
    return response(false, ccErrors[2])
  }

  let lengthValid = false
  let prefixValid = false
  let cardCompany = ""

  for (let i = 0; i < cards.length; i++) {
    const prefix = cards[i].prefixes.split(",")

    for (let j = 0; j < prefix.length; j++) {
      const exp = new RegExp("^" + prefix[j])
      if (exp.test(cardnumber)) {
        prefixValid = true
      }
    }

    if (prefixValid) {
      const lengths = cards[i].length
      for (let j = 0; j < lengths.length; j++) {
        if (cardnumber.length === lengths[j]) {
          lengthValid = true
        }
      }
    }

    if (lengthValid && prefixValid) {
      cardCompany = cards[i].name
      return response(true, null, cardCompany)
    }
  }

  if (!prefixValid) {
    return response(false, ccErrors[3])
  }

  if (!lengthValid) {
    return response(false, ccErrors[4])
  }

  return response(true, null, cardCompany)
}

type Props = {
  field: string
  label: string
  disabled?: boolean
  className?: string
  horizontal?: boolean
}

type CardType = {
  name: string
  code: string
  length: Array<number>
  prefixes: string
  checkdigit: boolean
  mask: string
  icon: string
  cvvlength: number
}

export { CreditcardNumberField }
