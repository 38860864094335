import { Address, Extension, ResourceObject } from "fhir"
import { AccountBETACreditCardArray } from "fhir/fhir-extended"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { organizationQueryKeys, useOrganizationContext } from "organizations"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { useBasisTheoryInstance } from "./useBasisTheoryInstance"
import { useAuthorizeBasisTheorySession } from "./useAuthorizeBasisTheorySession"
import { creditCardQueryKeys } from "../query-keys"

const useDeleteCreditCard = (onSettled: () => void, onSuccess?: () => void) => {
  const { patch } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()
  const { bt } = useBasisTheoryInstance()
  const { authorize } = useAuthorizeBasisTheorySession()
  const { currentOrganizationId } = useOrganizationContext()

  const removeCreditCardToken = async ({ tokenId, organizationId }: ResourceIds & { tokenId: string }) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    await authorize({
      nonce,
      organizationId: organizationId,
    })

    return bt.tokens.delete(tokenId, { apiKey: sessionKey })
  }

  const updatedAccount = async ({
    index,
    accountId,
    creditCardList,
    defaultCreditCardId,
    organizationId,
  }: UpdateArgs) => {
    const isDefaultCC = defaultCreditCardId === `${creditCardList[index].type}|${creditCardList[index].last4Digits}`
    const patchOps: {
      op: string
      path: string
      value?: AccountBETACreditCardArray<Address, Extension>[]
    }[] = [
      {
        op: "replace",
        path: "/creditCard",
        value: [...creditCardList.slice(0, index), ...creditCardList.slice(index + 1)],
      },
    ]

    if (isDefaultCC) {
      patchOps.push({ op: "remove", path: "/defaultCreditCard" })
    }

    await patch("Account", accountId, patchOps as ResourceObject)

    return removeCreditCardToken({
      tokenId: creditCardList[index]?.pciToken as string,
      ...{ organizationId: organizationId },
    })
  }

  const { mutate: removeCreditCard, isPending: isDeleting } = useMutation({
    mutationFn: updatedAccount,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: creditCardQueryKeys.withOrgId(currentOrganizationId) })
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.account.current(currentOrganizationId) })
      onSuccess?.()
      displayNotificationSuccess("Credit card deleted successfully!")
    },
    onError: async (error: CustomError) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: creditCardQueryKeys.withOrgId(currentOrganizationId) })
      }
      displayNotificationError(registerErrorTrace(error))
    },
    onSettled,
  })

  return { removeCreditCard, isDeleting }
}

type UpdateArgs = {
  index: number
  accountId: string
  creditCardList: AccountBETACreditCardArray<Address, Extension>[]
  defaultCreditCardId?: string
} & ResourceIds

type ResourceIds = { organizationId: string }

export { useDeleteCreditCard }
