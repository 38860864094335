import { Organization, Location, Reference, Device, Account, Composition } from "fhir"
import { createContext, FC, PropsWithChildren, ReactNode } from "react"

import { PAYMENT_METHODS } from "data"
import { useMedicationCatalogs } from "commons/meds"
import { useEnabledLabFacilities, useEnabledPaymentMethods } from "administration"

import { useOrganizationAccount, useOrganizationAdministrationData } from "../hooks"

const OrganizationContext = createContext<State | undefined>(undefined)
OrganizationContext.displayName = "OrganizationContext"

const OrganizationProvider: FC<Props> = ({ children, organizationId }: PropsWithChildren<Props>) => {
  const {
    organization: currentOrganization,
    location,
    rooms,
    roomsRefs,
    devices,
    devicesRefs,
  } = useOrganizationAdministrationData(organizationId)
  const { organizationCCAccount, organizationReaderAccounts } = useOrganizationAccount(organizationId)

  const { nutraCatalogs, rxCatalogs } = useMedicationCatalogs(organizationId)
  const { labs, labLocation } = useEnabledLabFacilities(organizationId)

  const { enabledPaymentMethods } = useEnabledPaymentMethods(organizationId)

  return (
    <OrganizationContext.Provider
      value={{
        currentOrganization,
        currentOrganizationId: currentOrganization.id as string,
        currentOrganizationCCAccount: organizationCCAccount,
        currentOrganizationReaderAccounts: organizationReaderAccounts,
        partOfId: currentOrganization.partOf?.id,
        location,
        rooms,
        roomsRefs,
        devices,
        devicesRefs,
        nutraCatalogs,
        rxCatalogs,
        cardReadersEnabled: enabledPaymentMethods?.includes(PAYMENT_METHODS.CARD_READER) ?? false,
        enabledLabs: labs,
        laboratoryLocation: labLocation,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

type State = {
  currentOrganization: Organization
  currentOrganizationId: string
  currentOrganizationCCAccount?: Account
  currentOrganizationReaderAccounts?: Account[]
  partOfId?: string
  location?: Location
  rooms: Location[]
  roomsRefs: Reference[]
  devices: Device[]
  devicesRefs: Reference[]
  nutraCatalogs: Composition[]
  rxCatalogs: Composition[]
  cardReadersEnabled: boolean
  enabledLabs: string[]
  laboratoryLocation: Record<string, string>
}

type Props = {
  children: ReactNode
  organizationId: string
}

export { OrganizationProvider, OrganizationContext }
