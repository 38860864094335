import { Account, AccountCreditCardArray, Organization, asReference } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { datadogLogs, registerErrorTrace } from "logger"
import { useAuthorizeBasisTheorySession, useBasisTheoryInstance } from "account/hooks"
import { CreditCardFormData, sanitize } from "account"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useCreateOrganizationAccount = (organization: Organization, onSettled: () => void) => {
  const { create } = useClient(IClient.AIDBOX)
  const { bt } = useBasisTheoryInstance()
  const { authorize } = useAuthorizeBasisTheorySession()
  const queryClient = useQueryClient()

  const newOrganizationAccount = async ({ creditCard }: { creditCard: CreditCardFormData }) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    const { container } = await authorize({ nonce, organizationId: organization.id as string })

    const token = await bt.tokens.create(
      {
        type: "card",
        data: {
          number: creditCard.number?.replace(/-/g, "") as string,
          cvv: creditCard.cvv!,
          expiration_month: creditCard.expirationMonth!,
          expiration_year: creditCard.expirationYear!,
        },
        containers: [container],
      },
      { apiKey: sessionKey },
    )

    const sanitizedCreditCard = sanitize(creditCard, true)

    const creditCardWithPCIToken: AccountCreditCardArray = { ...sanitizedCreditCard, pciToken: token.id }
    const account = {
      subject: [asReference(organization)],
      status: "active",
      creditCard: [creditCardWithPCIToken],
      defaultCreditCard: `${sanitizedCreditCard.type}|${sanitizedCreditCard.last4Digits}`,
    } as Account

    return await create("Account", account)
  }

  const { mutate: createOrgAccount, isPending: isAdding } = useMutation({
    mutationFn: newOrganizationAccount,
    onSuccess: async (account) => {
      await queryClient.invalidateQueries({
        queryKey: organizationQueryKeys.account.current(organization.id),
        refetchType: "all",
      })

      displayNotificationSuccess("Account created successfully")
      datadogLogs.logger.info(`Account ${account?.id} created successfully`, { account })
    },
    onSettled: onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createOrgAccount, isAdding }
}
export { useCreateOrganizationAccount }
