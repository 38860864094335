import { Task } from "fhir"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"

import { formatsByTypes } from "data"

export const getTaskDate = (task: Task) => {
  const startDate = task.restriction?.period?.start
  if (!startDate) return "Not specified date"
  const endDate = task.restriction?.period?.end
  return `${taskDateToString(startDate)}${endDate ? ` - ${taskDateToString(endDate)}` : ""}`
}

export const taskDateToString = (fieldDate: string) =>
  format(
    parseISO(fieldDate),
    fieldDate.includes("T") ? formatsByTypes.LONG_DATETIME_WITH_TIMEZONE : formatsByTypes.LONG_DATE,
  )
