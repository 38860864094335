import { useQuery } from "@tanstack/react-query"
import { Bundle, Parameters, Setting, SettingDefinition, SettingItemArray, getResources } from "fhir"

import { IClient, useClient } from "api"

import { settingsQueryKeys } from "../../query-keys"

const useSettings = (organizationId: string, settingId: string) => {
  const queryKey = settingsQueryKeys.all(organizationId, settingId)
  const getSetting = useSettingQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => getSetting(organizationId, settingId),
    refetchOnWindowFocus: false,
    meta: { context: { queryKey } },
  })

  return {
    settingId: data?.settingId,
    settingDescription: data?.description,
    items: data?.items ?? [],
    isLoading,
    isOverwritten: !!data?.isSettingOwner,
    readOnly: data?.readOnly ?? false,
  }
}

const useSettingQueryFunction = () => {
  const { operationRequest } = useClient(IClient.AIDBOX)

  return async (organizationId: string, settingId: string) => {
    const parameters = {
      resourceType: "Parameters",
      parameter: [
        { name: "owner", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
        { name: "code", value: { code: settingId } },
      ],
    } as Parameters

    const bundle = await operationRequest<Bundle>("Setting", "POST", "resolve", undefined, parameters)
    const setting = getResources<Setting>(bundle, "Setting")?.[0]
    const settingDefinition = getResources<SettingDefinition>(bundle, "SettingDefinition")[0]

    const items: SettingItemArray[] | undefined =
      settingDefinition.status !== "missing" && (!setting || setting.status !== "missing")
        ? setting?.item ??
          settingDefinition?.item?.map((item) => ({
            code: item.code,
            label: item.label,
            required: item.required,
            secure: item.secure,
            value: item.default,
            flag: item.flag,
          }))
        : []

    const description: string = setting?.description ?? settingDefinition?.description ?? "Unspecified"

    const accessPolicy = setting?.accessPolicy ?? settingDefinition?.accessPolicy

    return {
      settingId: setting?.id,
      description,
      items,
      readOnly: accessPolicy?.coding?.[0]?.code === "readonly",
      isSettingOwner: setting?.owner.id === organizationId,
    }
  }
}

export { useSettingQueryFunction, useSettings }
