import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationOnboard = (onSuccess?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const onboardOrganization = async ({ orgId, email }: { orgId: string; email: string }) =>
    create(`organizations/onboard/${orgId}`, {
      resourceType: "Parameters",
      parameter: [
        {
          name: "email",
          value: {
            string: email,
          },
        },
      ],
    })

  const { mutate: onboard, isPending } = useMutation({
    mutationFn: onboardOrganization,
    onSuccess: async () => {
      onSuccess?.()
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      displayNotificationSuccess("Organization onboarded successfully!")
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { onboard, isOnboarding: isPending }
}

export { useOrganizationOnboard }
