import { Device, asReference } from "fhir"

import {
  Button,
  DataContainerSlideoverForm,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
  useScreenContext,
} from "commons"
import {
  DeviceForm,
  deviceModel,
  getDeviceInitialValues,
  useCreateUpdateDevice,
  useReaders,
  useRegisterReader,
} from "commons/readers"
import { useOrganizationContext } from "organizations"
import { faPlus } from "@fortawesome/pro-light-svg-icons"

const PaymentReaders = () => {
  const { currentOrganization, currentOrganizationId } = useOrganizationContext()
  const { showSlide, initialValue, isNew, add, edit, reset } = useCrudReducer({
    defaultEntity: {
      ...getDeviceInitialValues(asReference(currentOrganization)),
      registrationCode: undefined,
    } as Device,
  })

  const { readers, locationsByReader, isLoading: isLoadingReaders } = useReaders(currentOrganizationId)
  const { registerReader } = useRegisterReader(currentOrganizationId, reset)
  const { createOrUpdateDevice } = useCreateUpdateDevice(reset)
  const { isSmallScreen } = useScreenContext()

  const onSubmit = (reader: Device & { registrationCode: string }) => {
    if (isNew)
      registerReader({ readerLabel: reader.deviceName?.[0].name as string, registrationCode: reader.registrationCode })
    else createOrUpdateDevice(reader)
  }

  if (isLoadingReaders) return <SkeletonLoader loaderType="two-lines" repeats={1} />

  return (
    <DataContainerSlideoverForm
      formTitle="Reader"
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      hasData={!!readers?.length}
      onButtonAddClick={add}
      formInitialValue={initialValue}
      showAddButton={!isSmallScreen}
      form={<DeviceForm isReaderForm isEditing={!isNew} />}
      onSubmit={onSubmit}
      onCancel={reset}
      customAddButtonText="Add new reader"
      subMessageDataNotFound="Get started by adding a new Reader"
    >
      {" "}
      <div>
        {isSmallScreen && (
          <div className="flex flex-col items-end mb-2">
            <Button label="Add" icon={faPlus} className="button-primary sm:text-sm w-20 h-8" onClick={add} />
          </div>
        )}
      </div>
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={readers ?? []}
          itemPadding
          itemModelBuilder={(item) =>
            deviceModel({
              device: item,
              onEdit: () => edit(item),
              isReader: true,
              locationName: locationsByReader?.[item.id as string]?.name,
            })
          }
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

export { PaymentReaders }
