import { Account, Address, Extension, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AccountBETACreditCardArray } from "fhir/fhir-extended"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { organizationQueryKeys } from "organizations"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { creditCardQueryKeys } from "../query-keys"

const useUpdateCreditCard = (onSettled: () => void, onSuccess?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedCreditCard = async ({
    creditCard,
    account,
  }: {
    creditCard: AccountBETACreditCardArray<Address, Extension>
    account: Account
  }) => {
    return patch(
      "Account",
      account.id as string,
      {
        creditCard: [creditCard],
        meta: account.meta,
      } as ResourceObject,
    )
  }

  const { mutate: updateCreditCard, isPending: isUpdating } = useMutation({
    mutationFn: updatedCreditCard,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: creditCardQueryKeys.all })
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.account.all })
      onSuccess?.()
      displayNotificationSuccess("Credit card updated successfully!")
    },
    onSettled,
    onError: async (error: CustomError) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: creditCardQueryKeys.all })
      }
      displayNotificationError(registerErrorTrace(error))
    },
  })

  return { updateCreditCard, isUpdating }
}

export { useUpdateCreditCard }
