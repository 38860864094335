import { User } from "security"

import { Client } from "./Client"

const apiUrl = window.VITE_APP_FHIR_SERVER

class AidboxClient extends Client {
  constructor(logout: (isSessionExpired?: boolean) => void, user?: User) {
    super(logout, apiUrl, user)
  }
}

export { AidboxClient }
