const usersQueryKeys = {
  all: ["users"] as const,
  userInvitation: ["user-invitation"] as const,
  list: (filter: string, status?: string, after?: string, before?: string) => [
    ...usersQueryKeys.all,
    filter,
    status,
    after,
    before,
  ],
}

export { usersQueryKeys }
