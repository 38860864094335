import { Practitioner, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { PractitionerApi, CustomError } from "commons"

import { practitionerQueryKeys } from "../query-keys"

const useActiveDeactivePractitioner = () => {
  const queryClient = useQueryClient()
  const { update } = useClient()

  const updatedPractitioner = async (practitioner: Practitioner) => {
    if (!practitioner.id) {
      throw new Error(`No practitioner id provided for ${practitioner.name ?? "No name"}`)
    }

    const practUpdated: PractitionerApi = {
      practitioner: { ...practitioner, active: !practitioner?.active },
      roles: [],
    }

    return update<PractitionerApi & ResourceObject>("practitioners", practitioner.id, practUpdated)
  }

  const { mutate: updatePractitionerStatus, isPending: isUpdating } = useMutation({
    mutationFn: updatedPractitioner,
    onSuccess: async (_, practitioner) => {
      await queryClient.invalidateQueries({ queryKey: practitionerQueryKeys.all })
      displayNotificationSuccess(
        `Practitioner has been ${!practitioner?.active ? "activated" : "deactivated"} successfully!`,
      )
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updatePractitionerStatus, isUpdating }
}

export { useActiveDeactivePractitioner }
