import React, { Suspense } from "react"
import { useEffect, useState } from "react"
import { classNames } from "primereact/utils"
import { useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { QueryCache, QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"
import { Sidebar } from "primereact/sidebar"
import { useResizeListener } from "primereact/hooks"

import { datadogLogs, registerErrorTrace } from "logger"
import { ErrorContainer, displayNotificationError } from "errors"
import { LoginProvider } from "security"
import { CustomError } from "commons"
import { BottomNav } from "navigation"
import { MainNavbar, ScreenContextProvider, Sidebar as SidebarMenu, LoadingView } from "commons"
import { OffLineModal, useCheckNetworkContext } from "check-network"
import { RoutesContainer } from "RoutesContainers"
import { OrgIdContextProvider } from "orders"

const BREAK_POINT_TABLET = 768

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { isOnline, setIsOnline } = useCheckNetworkContext()
  const location = useLocation()

  const isSmallScreen = screenWidth <= BREAK_POINT_TABLET
  const [sidebarCollapsed, setSidebarCollapsed] = useState(screenWidth < BREAK_POINT_TABLET)

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event: Event & { currentTarget: EventTarget & { innerWidth: number } }) => {
      setScreenWidth(event.currentTarget?.innerWidth ?? 0)
    },
  })

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [window.innerWidth])

  useEffect(() => {
    bindWindowResizeListener()

    return () => {
      unbindWindowResizeListener()
    }
  }, [bindWindowResizeListener, unbindWindowResizeListener])

  useEffect(() => {
    if (isSmallScreen) {
      setSidebarCollapsed(true)
    }
  }, [isSmallScreen, screenWidth, location])

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: isOnline,
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        if ((error as CustomError).cause?.name !== "499") {
          const context = query?.meta?.context

          displayNotificationError(registerErrorTrace(error as CustomError, { ...(context ? { data: context } : {}) }))
        } else {
          setIsOnline(false)
        }
      },
    }),
  })

  const showDevTool = process.env.NODE_ENV === "development" || window.location.search.includes("showDevTool")

  const ReactQueryDevtools = React.lazy(() =>
    import("@tanstack/react-query-devtools").then((d) => {
      return {
        default: () =>
          d.ReactQueryDevtools({
            buttonPosition: "bottom-left",
            position: "bottom",
            initialIsOpen: false,
          }),
      }
    }),
  )

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <>
              <ErrorBoundary
                FallbackComponent={ErrorContainer}
                onReset={reset}
                onError={(error) => {
                  datadogLogs.logger.error(error.message, error)
                }}
              >
                <Suspense fallback={<LoadingView />}>
                  <LoginProvider>
                    <ScreenContextProvider
                      isSmallScreen={isSmallScreen}
                      sidebarCollapsed={sidebarCollapsed}
                      setSidebarCollapsed={setSidebarCollapsed}
                    >
                      {isSmallScreen ? (
                        <div className={classNames("h-full flex bg-red-100 ", { "flex-col": isSmallScreen })}>
                          <Sidebar
                            visible={!sidebarCollapsed}
                            showCloseIcon={false}
                            className="sidebar-menu w-fit"
                            onHide={() => setSidebarCollapsed(true)}
                          >
                            <SidebarMenu className="pt-3 h-full" />
                          </Sidebar>
                          <div className="overflow-hidden h-full w-full flex-1 flex-col text-start bg-white">
                            <RoutesContainer />
                          </div>
                          <BottomNav className="h-16 bg-white border-t-2 border-t-gray-200 py-2.5" />
                        </div>
                      ) : (
                        <div className="h-screen flex flex-col bg-gray-100">
                          <MainNavbar />
                          <div className="flex-1 flex flex-col overflow-hidden text-start bg-white">
                            <OrgIdContextProvider>
                              <RoutesContainer />
                            </OrgIdContextProvider>
                          </div>
                        </div>
                      )}
                    </ScreenContextProvider>
                  </LoginProvider>
                </Suspense>
              </ErrorBoundary>
              {!isOnline && <OffLineModal reset={reset} />}
            </>
          )}
        </QueryErrorResetBoundary>
        {showDevTool ? (
          <React.Suspense fallback={null}>
            <ReactQueryDevtools />
          </React.Suspense>
        ) : null}
      </QueryClientProvider>

      <ToastContainer autoClose={10000} theme={"light"} position={"bottom-left"} />
    </>
  )
}

export default App
