import { displaySystem } from "users/utils"
import * as Yup from "yup"
import YupPassword from "yup-password"

YupPassword(Yup)

const PASSWORD_INITIAL_VALUES = {
  id: "",
  temporalPassword: "",
}

const INVITE_INITIAL_VALUES = {
  email: "",
  displayName: "",
  website: "",
}
const passwordInitialValues = (id: string) => {
  const newValue = {
    ...PASSWORD_INITIAL_VALUES,
    id: id,
  }
  return newValue
}

const setUserEmailInitialValues = (email: string) => {
  const newValue = {
    email: email,
    newEmail: "",
  }
  return newValue
}

const changePasswordSchema = Yup.object().shape({
  temporalPassword: Yup.string()
    .required("Password required")
    .min(8, "Must have at least 8 characters")
    .minLowercase(1, "At least 1 lowercase character")
    .minUppercase(1, "At least 1 uppercase character")
    .minNumbers(1, "At least 1 number"),
})

const inviteUserSchema = Yup.object().shape({
  website: Yup.string().required("Web app is required"),
  email: Yup.string()
    .when("website", {
      is: (val: string) => val === displaySystem[2].code,
      then: (schema) =>
        schema.test({
          message: "This domain cannot be used",
          test: (val) => !!val && !val.endsWith("@evexias.com"),
        }),
    })
    .required("Email is required"),
  displayName: Yup.string().required("Name is required"),
})

const setUserEmailSchema = Yup.object().shape({
  newEmail: Yup.string().required("Email is required"),
})

export {
  changePasswordSchema,
  PASSWORD_INITIAL_VALUES,
  INVITE_INITIAL_VALUES,
  inviteUserSchema,
  passwordInitialValues,
  setUserEmailInitialValues,
  setUserEmailSchema,
}
