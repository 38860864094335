import { ReactNode } from "react"

const SubsNotificationRow = ({ title, content }: Props) => (
  <div className="flex w-full items-center text-slate-400 text-sm border-b last:border-0 py-5 first:pt-0">
    <p className="w-2/6 font-semibold">{title}</p>
    <div className="w-4/6 whitespace text-ellipsis overflow-hidden">{content}</div>
  </div>
)

type Props = {
  title: string
  content: string | ReactNode
}

export { SubsNotificationRow }
