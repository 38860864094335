import { getFirstEmail, humanNameAsString, Practitioner } from "fhir"
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { InputText } from "primereact/inputtext"

import { useCheckEmailExists } from "hooks"

import { useUpdatePractitionerEmail } from "../../practitioners/hooks"

const PractitionerInvite = ({
  practitioner,
  showInviteDialog,
  isInviting,
  hideInviteDialog,
  invitePractitioner,
}: Props) => {
  const defaultEmail = useMemo(() => getFirstEmail(practitioner.telecom) ?? "", [practitioner.telecom])

  const [email, setEmail] = useState(defaultEmail)
  const [emailExists, setEmailExists] = useState(false)

  const onSuccessCheckEmail = useCallback(
    (exists: boolean) => {
      defaultEmailRef.current = email
      setEmailExists(exists)
    },
    [email],
  )
  const { checkEmailExists, isCheckingEmail } = useCheckEmailExists(onSuccessCheckEmail)
  const { updatePractitionerEmail, isUpdating } = useUpdatePractitionerEmail(() => {
    invitePractitioner()
  })

  const timerRef = useRef<NodeJS.Timeout>()
  const defaultEmailRef = useRef(defaultEmail)
  const hasEmailChanged = email !== defaultEmailRef.current
  const isEmailValid = emailValidation(email)
  const isLoading = isCheckingEmail || isUpdating || isInviting

  const handleSubmitInvitation = useCallback(() => {
    if (!emailExists) {
      if (email !== defaultEmail) {
        updatePractitionerEmail({ email, practitioner })
      } else {
        invitePractitioner()
      }
    }
  }, [defaultEmail, email, emailExists, invitePractitioner, practitioner, updatePractitionerEmail])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let isSameEmail = false
      const text = event.target.value
      const isEmailValid = emailValidation(text)
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      if (text !== defaultEmail) {
        isSameEmail = false
        if (isEmailValid) {
          timerRef.current = setTimeout(() => {
            checkEmailExists({ emailToCheck: text, isPractitionerRole: true })
          }, 1000)
        }
      } else {
        setEmailExists(false)
        isSameEmail = true
      }

      setEmail((prev) => {
        if (isSameEmail) {
          defaultEmailRef.current = defaultEmail
        } else {
          defaultEmailRef.current = prev
        }

        return text
      })
    },
    [checkEmailExists, defaultEmail],
  )

  return (
    <Dialog
      header="Send invitation"
      draggable={false}
      appendTo={"self"}
      visible={showInviteDialog}
      className="w-full md:w-[70%] lg:w-[30%] m-2 "
      onHide={hideInviteDialog}
      footer={
        <div className="mt-2">
          <Button label="Cancel" disabled={isLoading} className="button-default" onClick={hideInviteDialog} />
          <Button
            label="Accept"
            className="button-primary"
            loading={isLoading}
            disabled={isLoading || emailExists || hasEmailChanged || !isEmailValid || isInviting}
            onClick={handleSubmitInvitation}
          />
        </div>
      }
    >
      <div className="mb-4">
        Pick an email to invite <span className="font-semibold">{humanNameAsString(practitioner.name?.[0])}</span> to
        the platform
      </div>
      <div className="p-inputgroup mt-5">
        <span className="p-inputgroup-addon">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
        <InputText type="email" placeholder="Type an email" required value={email} onChange={onChange} />
      </div>

      <div className="flex  flex-row items-start h-2 mt-1">
        <small>
          {isCheckingEmail ? (
            <p className="text-slate-500 absolute">
              <FontAwesomeIcon icon={faSpinner} spin />
              <span className="ml-1">checking availability.</span>
            </p>
          ) : !isEmailValid ? (
            <p className="p-error absolute">{"Valid email address is required"}</p>
          ) : emailExists ? (
            <p className="p-error">This email address is in use</p>
          ) : null}
        </small>
      </div>
    </Dialog>
  )
}

const emailValidation = (text: string) => text && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(text)

type Props = {
  practitioner: Practitioner
  showInviteDialog: boolean
  isInviting: boolean
  hideInviteDialog(): void
  invitePractitioner(): void
}

export { PractitionerInvite }
