import { useState } from "react"
import { classNames } from "primereact/utils"

import { LoadingView, useScreenContext } from "commons"
import { useOrganizationContext } from "organizations"

import { SettingDetails } from "./SettingDetails"
import { useSettingsDefinition } from "../hooks"
import { SettingDefinitionItem } from "../types"
import { Accordion, AccordionTab } from "primereact/accordion"

const SettingsView = () => {
  const { currentOrganizationId } = useOrganizationContext()
  const { settingsDefinition, isLoading } = useSettingsDefinition(currentOrganizationId)
  const [selectedDefinition, setSelectedDefinition] = useState<SettingDefinitionItem>(settingsDefinition[0])
  const { isSmallScreen } = useScreenContext()
  const [activeIndex, setActiveIndex] = useState<number | number[]>(-1)

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : isSmallScreen ? (
        <Accordion
          className=" w-full px-2 overflow-y-auto"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          {settingsDefinition.map((setting, index) => (
            <AccordionTab tabIndex={index} header={setting.label} key={index}>
              <div className="flex-grow overflow-y-auto pl-1">
                <SettingDetails settingDefinitionId={setting.settingDefinitionId} label={setting.label} />
              </div>
            </AccordionTab>
          ))}
        </Accordion>
      ) : (
        <div className="flex flex-col pt-2 h-full w-full">
          <div className="w-full text-sm pr-32 mb-6 flex-shrink-0 pl-6">
            <ul className="flex flex-row border-b">
              {settingsDefinition.map((setting, index) => (
                <MenuItem
                  key={setting.label}
                  settingDefinitionItem={setting}
                  selected={setting.settingDefinitionId === selectedDefinition.settingDefinitionId}
                  onClick={setSelectedDefinition}
                  className={index === 0 ? "pl-0" : ""}
                />
              ))}
            </ul>
          </div>
          <div className="flex-grow overflow-y-auto pl-1">
            <SettingDetails
              settingDefinitionId={selectedDefinition.settingDefinitionId}
              label={selectedDefinition.label}
            />
          </div>
        </div>
      )}
    </>
  )
}

const MenuItem = ({ settingDefinitionItem, selected, onClick, className }: MenuItemProps) => (
  <li
    className={classNames(
      "p-3 text-gray-500 cursor-pointer border-b-2  hover:text-primary hover:bg-blue-100/10",
      {
        "text-primary border-primary bg-blue-100/10": selected,
        "border-transparent": !selected,
      },
      className,
    )}
    onClick={() => onClick(settingDefinitionItem)}
  >
    {settingDefinitionItem.label}
  </li>
)

type MenuItemProps = {
  settingDefinitionItem: SettingDefinitionItem
  selected?: boolean
  onClick(group: { label: string; settingDefinitionId: string }): void
  className: string
}

export { SettingsView }
