import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { RefObject } from "react"

import { Button, SearchInput } from "commons"

import "./SearchWithFilters.css"
import { useScreenContext } from "../hooks"
import { Sidebar } from "primereact/sidebar"

const SearchWithFilters = ({
  showOverlaypanel,
  showSidebar,
  setShowSidebar,
  filterNone,
  isLoading,
  onTextFilter,
  formContent,
  customSearchInput,
  placeholder = "Search",
  className,
  autoFocus,
  showSearchInput = true,
}: SearchWithFiltersProps) => {
  const { isSmallScreen } = useScreenContext()

  const overlaypanel = (
    <OverlayPanel
      ref={showOverlaypanel}
      showCloseIcon={false}
      dismissable
      id="overlay_panel"
      className="form-panel"
      breakpoints={{ "1330px": "25vw", "1024px": "45vw", "960px": "75vw", "640px": "90vw" }}
      style={{ width: "25vw" }}
    >
      {formContent}
    </OverlayPanel>
  )

  const sidebar = (
    <Sidebar
      visible={showSidebar}
      position="bottom"
      className="h-auto rounded-t-xl"
      onHide={() => setShowSidebar(false)}
    >
      <div className="relative h-full w-full mx-4 mt-2 mb-8">{formContent}</div>
    </Sidebar>
  )

  return (
    <>
      <div className={classNames("flex", className)}>
        {showSearchInput && (
          <SearchInput
            className="flex-1"
            search={(searchText) => onTextFilter?.(searchText)}
            isLoading={isLoading}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
        )}
        {!showSearchInput && customSearchInput && <div className="h-9 ">{customSearchInput}</div>}

        <>
          <Button
            icon={faFilter}
            iconClassName={filterNone ? "text-slate-400" : "text-secondary"}
            className="p-button-sm button-default ml-2"
            onClick={isSmallScreen ? () => setShowSidebar(true) : (e) => showOverlaypanel?.current?.toggle(e)}
          />
        </>
        {isSmallScreen ? sidebar : overlaypanel}
      </div>
    </>
  )
}

export type SearchWithFiltersProps = {
  showOverlaypanel: RefObject<OverlayPanel>
  showSidebar: boolean
  setShowSidebar(status: boolean): void
  filterNone?: boolean
  autoFocus?: boolean
  formContent?: JSX.Element
  customSearchInput?: JSX.Element
  isLoading: boolean
  placeholder?: string
  className?: string
  buttonClassName?: string
  showClearButton?: boolean
  onTextFilter?(searchText?: string): void
  showSearchInput?: boolean
}

export { SearchWithFilters }
