import { useMutation } from "@tanstack/react-query"

import { displayNotificationError } from "errors"
import { useClient, IClient } from "api"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useAzureContainer = (containerName: string) => {
  const { uploadFile: clientUploadFile } = useClient(IClient.AIDBOX)

  const { mutateAsync: uploadFile, isPending: isUploading } = useMutation({
    mutationFn: async (file: File): Promise<string> => {
      const fileUrl = await clientUploadFile(file, containerName)
      return fileUrl
    },

    onError: (error: CustomError, { name, type, size }) => {
      displayNotificationError(registerErrorTrace(error, { file: { name, type, size } }))
    },
  })

  return { uploadFile, isUploading }
}

export { useAzureContainer }
