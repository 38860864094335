import { useMutation } from "@tanstack/react-query"
import { Parameters, Reference } from "fhir"

import { useClient, IClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useCheckEmailExists = (onSuccess?: (data: boolean) => void, onSettled?: () => void) => {
  const { operationRequest } = useClient(IClient.AIDBOX)

  const checkEmail = async ({
    emailToCheck,
    isPractitionerRole,
    org,
  }: {
    emailToCheck?: string
    isPractitionerRole?: boolean
    org?: Reference
  }) => {
    if (!emailToCheck) return false

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "email",
          value: {
            string: emailToCheck,
          },
        },
        { name: "resourceType", value: { string: isPractitionerRole ? "Practitioner" : "Patient" } },
        ...(!isPractitionerRole && org ? [{ name: "organization", value: { Reference: org } }] : []),
      ],
    }

    const results = await operationRequest<Parameters>("", "POST", "email-exists-v2", undefined, parameters)

    return results.parameter?.find((param) => param.name === "exist")?.value?.boolean ?? false
  }

  const {
    mutate: checkEmailExists,
    isPending: isLoading,
    data,
  } = useMutation({
    mutationFn: checkEmail,
    onError: (error: CustomError, email) => {
      displayNotificationError(registerErrorTrace(error, email))
    },
    onSuccess: (data) => {
      onSuccess?.(data)
    },
    onSettled: onSettled,
  })

  return { checkEmailExists, isCheckingEmail: isLoading, exists: data ?? false }
}

export { useCheckEmailExists }
