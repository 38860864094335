import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FallbackProps } from "react-error-boundary"
import { FC } from "react"
import { Button } from "primereact/button"
import { faSignOut } from "@fortawesome/pro-light-svg-icons"

import { CustomError } from "commons"
import { useAuth } from "security"

const NotFoundErrorView: FC<Props> = ({ error, resetErrorBoundary }) => {
  const { logout } = useAuth()
  const message = error.cause?.message ?? "Not found"

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="text-secondary">404 Error</p>
        <h1 className="text-4xl py-2 font-bold">Resource not found</h1>
        <p className="text-sm text-gray-400 pt-2 pb-4">{message}</p>
        <div className="flex justify-center gap-4">
          <Button onClick={resetErrorBoundary} className="text-sm">
            <span>
              Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
            </span>
          </Button>
          <Button onClick={logout} className="text-sm">
            <span className="flex items-center">
              <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

type Props = Pick<FallbackProps, "resetErrorBoundary"> & { error: CustomError }

export { NotFoundErrorView }
