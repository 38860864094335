import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import "primeicons/primeicons.css"
import { MsalProvider } from "@azure/msal-react"
import { PublicClientApplication } from "@azure/msal-browser"
import { PrimeReactProvider } from "primereact/api"
import { twMerge } from "tailwind-merge"

import { init as loggerInit } from "logger"
import { dataDogInit } from "datadog"
import { msalConfig } from "authConfig"
import { AuthProvider } from "security"
import { NetworkContextProvider } from "check-network"

import "./index.css"
import "primereact/resources/themes/lara-light-blue/theme.css"
import "./prime-react-custom-theme.css"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"

loggerInit()
dataDogInit()

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <MsalProvider instance={msalInstance}>
    <React.StrictMode>
      <PrimeReactProvider
        value={{
          pt: {},
          ptOptions: {
            mergeSections: true,
            mergeProps: true,
            classNameMergeFunction: twMerge,
          },
        }}
      >
        <NetworkContextProvider>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </NetworkContextProvider>
      </PrimeReactProvider>
    </React.StrictMode>
  </MsalProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
