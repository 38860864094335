import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays, faEye, faPaperPlane } from "@fortawesome/pro-light-svg-icons"
import { SubsNotification } from "fhir"

import { BadgeProps, StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { format, parseISO } from "date-fns"

const notificationModelBuilder = (
  subsNotification: SubsNotification,
  showDeatil: (subsNotification: SubsNotification) => void,
  showResendDialog: (subsNotification: SubsNotification) => void,
): StackedListItemProps => {
  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        label: "View details",
        icon: <FontAwesomeIcon icon={faEye} size="sm" className="mr-2" />,
        command: () => showDeatil(subsNotification),
      },
      {
        label: "Resend",
        icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
        command: () => showResendDialog(subsNotification),
        disabled: subsNotification.status !== "failed",
      },
    ]

    return menuItems
  }

  const getBadgeProps = (): BadgeProps => ({
    text: subsNotification.status ?? " ",
    colorStyle: subsNotification.status === "failed" ? "red" : "green",
  })

  return {
    leftData: [
      { lineItems: [{ name: "Id", value: subsNotification.subscription?.id }] },
      {
        lineItems: [
          {
            name: "Created at",
            icon: faCalendarDays,
            value: format(parseISO(subsNotification.meta?.createdAt?.toString() ?? ""), formatsByTypes.LONG_DATETIME),
          },
        ],
      },
    ],
    badge: getBadgeProps(),
    menu: getMenuItems(),
  }
}

export { notificationModelBuilder }
