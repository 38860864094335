const displaySystem = [
  { display: "My Evexias", code: "my-evexias" },
  { display: "EHR", code: "ehr" },
  { display: "Patient Portal", code: "patient-portal" },
]

export type UserInvite = {
  email: string
  displayName: string
  website: string
}

export type SetUserEmail = {
  email: string
  newEmail: string
}

export { displaySystem }
