import { AccountCreditCardArray } from "fhir"
import { Field, FieldProps, ErrorMessage, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { Calendar } from "primereact/calendar"
import { isDate } from "date-fns"

const ExpirationDateField: FC<Props> = ({ field, label, className, disabled = false }) => {
  const { values, setFieldTouched } = useFormikContext<AccountCreditCardArray>()

  return (
    <Field
      name={field}
      validate={() => {
        if (!values.expirationYear || !values.expirationMonth) return "Invalid expiration date"
        const currentDate = new Date()

        if (
          parseInt(values.expirationYear) < currentDate.getFullYear() ||
          (parseInt(values.expirationYear) === currentDate.getFullYear() &&
            parseInt(values.expirationMonth) < currentDate.getMonth() + 1)
        )
          return "Expired date"
      }}
    >
      {({ field: { name }, meta: { touched, error }, form: { setFieldValue, values } }: FieldProps) => {
        const dateValue = new Date(parseInt(values["expirationYear"]), parseInt(values["expirationMonth"]) - 1, 1)
        return (
          <div className={classNames("relative field flex flex-col", className)}>
            {label && (
              <label htmlFor={name} className="text-sm font-medium mb-2 text-gray-700">
                {label}
              </label>
            )}
            <Calendar
              id={name}
              name={name}
              value={dateValue}
              readOnlyInput
              onChange={(e) => {
                if (isDate(e.target.value)) {
                  setFieldTouched("expirationMonth", false, true)
                  setFieldValue("expirationMonth", ((e.target.value as Date).getMonth() + 1).toString())
                  setFieldValue("expirationYear", (e.target.value as Date).getFullYear().toString())
                }
              }}
              showIcon
              dateFormat="MM-yy"
              view="month"
              className={classNames("p-inputtext-sm", { "p-invalid": touched && error })}
              disabled={disabled}
            />
            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={name}>{(msg) => <small className="">{msg}</small>}</ErrorMessage>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  disabled?: boolean
}

export { ExpirationDateField }
