import { useMutation, useQueryClient } from "@tanstack/react-query"
import { User } from "fhir/fhir-extended"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { usersQueryKeys } from "../query-keys"

const useRequestResetPassword = (onClose: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const requestReset = async (user: User) =>
    operationRequest("users/request-reset-password", "POST", "", undefined, {
      id: user.id,
    })

  const { mutate: requestResetPassword, isPending: isRequesting } = useMutation({
    mutationFn: requestReset,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      displayNotificationSuccess(`Request reset password successfully`)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: usersQueryKeys.all })
      onClose()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { requestResetPassword, isRequesting }
}

export { useRequestResetPassword }
