import { PractitionerRole, Reference } from "fhir"
import * as Yup from "yup"

const INITIAL_VALUES: Partial<PractitionerRole> = {
  code: [{ coding: [{ code: "", display: "", system: "http://chartedhealth.com/fhir/practitioner-role" }] }],
  organization: undefined,
  active: true,
  resourceType: "PractitionerRole",
}

const initialValues = (practitionerRef?: Reference) =>
  ({
    ...INITIAL_VALUES,
    practitioner: practitionerRef,
  } as PractitionerRole)

const practitionerRoleValidation = Yup.object().shape({
  organization: Yup.object().required("Organization is required"),
})

export { initialValues, practitionerRoleValidation, INITIAL_VALUES }
