import * as Yup from "yup"
import { sub } from "date-fns"

import { ContactPointSystem } from "data"
import { humanNameSchema, telecomSchema, notPhoneRequiredTelecomSchema, getRequiredAddressSchema } from "utils"
import { PractitionerApi } from "commons"
import { sanitize } from "practitioners/components/validations"
const INITIAL_VALUES: Partial<PractitionerApi> = {
  practitioner: {
    name: [{ use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }],
    active: true,
    gender: undefined,
    birthDate: "",
    telecom: [
      { system: ContactPointSystem.email, use: "home", value: "" },
      { system: ContactPointSystem.phone, use: "home", value: "" },
    ],
    address: [
      {
        country: "US",
        line: ["", ""],
        city: "",
        state: "",
        postalCode: "",
      },
    ],
  },
}

const initialValues = (pract: Partial<PractitionerApi> = INITIAL_VALUES) => {
  const [name = { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }] =
    pract.practitioner?.name ?? []

  return {
    ...pract,
    practitioner: { ...pract.practitioner, name: [name] },
  } as PractitionerApi
}

// const sanitize = ({ ...pract }: PractitionerApi) => {
//   if (pract.practitioner.name?.[0].given) {
//     pract.practitioner.name[0].given = pract.practitioner.name[0].given.filter((value) => value && value !== "")
//   }

//   if (pract.practitioner.name?.[0].suffix) {
//     pract.practitioner.name[0].suffix = pract.practitioner.name[0].suffix.filter((value) => value && value !== "")
//   }

//   if (pract.practitioner.name?.[0].prefix) {
//     pract.practitioner.name[0].prefix = pract.practitioner.name[0].prefix.filter((value) => value && value !== "")
//   }

//   if (!pract.practitioner?.birthDate) {
//     delete pract.practitioner?.birthDate
//   }

//   return pract
// }
const minDateTime = sub(new Date(), {
  minutes: 1,
})

const APIPractitionerValidationSchema = (parentFieldName?: string) =>
  Yup.object().shape({
    practitioner: Yup.object().shape({
      birthDate: Yup.date().nullable().max(minDateTime, "Practitioner birthdate should be before now"),
      gender: Yup.string().oneOf(["male", "female"], "Invalid value").required("Gender is required"),
      name: Yup.array(humanNameSchema(parentFieldName)).min(1, "At least one name is required"),
      telecom: Yup.array(notPhoneRequiredTelecomSchema),
      address: Yup.array(getRequiredAddressSchema()),
    }),
    roles: Yup.array().min(1, "At lasted one practitioner role is required"),
  })

const identifierInitialValues = {
  value: "",
  system: "",
}
const telecomValidationSchema = Yup.object().shape({
  telecom: Yup.array(telecomSchema),
})

export {
  initialValues,
  sanitize,
  humanNameSchema,
  telecomSchema,
  telecomValidationSchema,
  APIPractitionerValidationSchema,
  identifierInitialValues,
}
