import { createContext, FC, PropsWithChildren } from "react"

const ScreenCtx = createContext<Props | undefined>(undefined)

const ScreenContextProvider: FC<PropsWithChildren<Props>> = ({
  isSmallScreen,
  sidebarCollapsed,
  setSidebarCollapsed,
  children,
}) => {
  return (
    <ScreenCtx.Provider
      value={{
        isSmallScreen,
        sidebarCollapsed,
        setSidebarCollapsed,
      }}
    >
      {children}
    </ScreenCtx.Provider>
  )
}

type Props = {
  isSmallScreen: boolean
  sidebarCollapsed: boolean
  setSidebarCollapsed: (sidebarCollapsed: boolean) => void
}

export { ScreenContextProvider, ScreenCtx }
