import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient, IClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { subsnotificationQueryKeys } from "../query-keys"

const useResendSubsNotification = (onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient(IClient.AIDBOX)

  const resend = async (id: string) => {
    return operationRequest(`SubsNotification/${id}`, "POST", "notify")
  }

  const { mutate: resenSubsNotification, isPending: isSending } = useMutation({
    mutationFn: resend,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: subsnotificationQueryKeys.all })
      displayNotificationSuccess(`Resend notification success.`)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: subsnotificationQueryKeys.all })
      onSettled?.()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { context }))
    },
  })

  return { resenSubsNotification, isSending }
}

export { useResendSubsNotification }
