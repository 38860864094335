import { Device } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organizations"
import { displayNotificationSuccess } from "utils"

import { readerQueryKeys } from "../query-keys"
import { CustomError } from "../../types"

const useCreateUpdateDevice = (onSuccess?: (device: Device) => void, onSettled?: () => void) => {
  const { create, update } = useClient(IClient.AIDBOX)
  const queryClient = useQueryClient()

  const newDevice = async (device: Device) =>
    device.id ? update("Device", device.id, device) : create("Device", device)

  const { mutate: createOrUpdateDevice, isPending } = useMutation({
    mutationFn: newDevice,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (device, { id, owner }) => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.currentOrganization.all })
      await queryClient.invalidateQueries({ queryKey: readerQueryKeys.all(owner?.id as string) })

      const notificationLabel = device.identifier?.some(({ system }) => system?.includes("reader"))
        ? "Reader"
        : "Device"

      displayNotificationSuccess(`${notificationLabel} ${id ? "updated" : "added"} successfully!`)
      onSuccess?.(device)
    },
    onSettled,
  })

  return { createOrUpdateDevice, isPending }
}

export { useCreateUpdateDevice }
