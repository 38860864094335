import { asReference, Reference } from "fhir"
import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { useLoginContext } from "security"

import { OrganizationApi } from "../types"

const useOrganizationRefs = (filter: string = "", type?: string) => {
  const { search } = useClient()
  const { logedInPractitioner } = useLoginContext()
  const queryKey = ["organizationReference", filter]

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        ...(filter ? { "name:contains": filter } : {}),
        ...(type ? { type: type } : {}),
        _count: "all",
        _sort: "name",
      })

      const bundle = await search("organizations", filters)
      const organizations = bundle.entry as OrganizationApi[]

      return { organizations, total: bundle?.total ?? 0 }
    },

    enabled: !!logedInPractitioner.practitioner.id,
  })

  const { organizationRefs } =
    data?.organizations.reduce<{ organizationRefs: Reference[] }>(
      (prev, organization) => {
        return {
          organizationRefs: [...prev.organizationRefs, asReference(organization.organization)],
        }
      },
      { organizationRefs: [] },
    ) ?? {}

  return { organizationRefs, isLoading, isFetching, total: data?.total }
}

export { useOrganizationRefs }
