import { Reference } from "fhir"

const practitionerQueryKeys = {
  all: ["practitioners"] as const,
  list: (
    filter?: string,
    email?: string,
    gender?: string,
    organization?: Reference,
    role?: string,
    status?: string,
  ) => [...practitionerQueryKeys.all, filter, email, gender, organization, role, status],
  practId: (practId: string) => [...practitionerQueryKeys.all, practId],
  orgId: (orgId: string, isNotPractitionerId?: string) => [
    ...practitionerQueryKeys.all,
    orgId,
    ...(isNotPractitionerId ? [isNotPractitionerId] : []),
  ],
  invitationWhithId: (practId: string) => [...["practitioner-invitation"], practId],
}

export { practitionerQueryKeys }
