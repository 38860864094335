import { Device, asReference } from "fhir"

import { Button, DataContainerSlideoverForm, StackedListContainer, useCrudReducer, useScreenContext } from "commons"
import { DeviceForm, deviceModel, getDeviceInitialValues, useCreateUpdateDevice } from "commons/readers"
import { useOrganizationContext } from "organizations"

import { useDeleteDevice } from "../hooks"
import { faPlus } from "@fortawesome/pro-light-svg-icons"

const Devices = () => {
  const { devices, currentOrganization } = useOrganizationContext()
  const { showSlide, initialValue, deleteIndex, add, edit, setDeleteIndex, reset } = useCrudReducer({
    defaultEntity: getDeviceInitialValues(asReference(currentOrganization)),
  })
  const { isSmallScreen } = useScreenContext()
  const { createOrUpdateDevice } = useCreateUpdateDevice(reset)
  const { removeDevice, isDeleting } = useDeleteDevice()

  const onSubmit = (device: Device) => createOrUpdateDevice(device)

  return (
    <DataContainerSlideoverForm
      formTitle="Device"
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      hasData={devices.length > 0}
      onButtonAddClick={add}
      formInitialValue={initialValue}
      form={<DeviceForm />}
      onSubmit={onSubmit}
      onCancel={reset}
      showAddButton={!isSmallScreen}
      deleteSupport={{
        showDelete: !!deleteIndex,
        isDeleting,
        onConfirmDelete: () => removeDevice(deleteIndex as string),
        onCancelDelete: () => setDeleteIndex(undefined),
      }}
    >
      <div>
        {isSmallScreen && (
          <div className="flex flex-col items-end mb-2">
            <Button label="Add" icon={faPlus} className="button-primary sm:text-sm w-20 h-8" onClick={add} />
          </div>
        )}
      </div>
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={devices}
          itemPadding
          itemModelBuilder={(item) =>
            deviceModel({
              device: item,
              onEdit: () => edit(item),
              onDelete: () => setDeleteIndex(item.id as string),
            })
          }
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

export { Devices }
