import { Field, FieldProps, ErrorMessage } from "formik"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { RadioButton } from "primereact/radiobutton"

import { genders } from "data"

const GenderField: FC<Props> = ({ field, label, className, disabled }) => (
  <Field name={field}>
    {({ field: { name, value, onChange } }: FieldProps) => (
      <div className={classNames("field relative flex flex-col", className)}>
        {label && (
          <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-2">
            {label}
          </label>
        )}
        <div className="flex space-x-4 w-full">
          {genders.map(({ code, label }, index) => (
            <div key={index} className="text-sm flex items-center mt-2">
              <RadioButton
                inputId={code}
                name={name}
                value={code}
                checked={code === value}
                onChange={onChange}
                disabled={disabled}
              />
              <label htmlFor={code} className="flex w-full cursor-pointer mx-2">
                {label}
              </label>
            </div>
          ))}
        </div>
        <div className="flex items-start p-error h-2 mt-1">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  disabled?: boolean
}

export { GenderField }
