import { useInfiniteQuery } from "@tanstack/react-query"
import { Parameters } from "fhir"
import { useMemo } from "react"

import { IClient, useClient } from "api"

import { mapPanelParams } from "../../components/labs/utils"
import { settingsQueryKeys } from "../../query-keys"
import { AdvanceFilter, LabPanel } from "../../types"

const useLabPanels = (orgid: string, filters?: AdvanceFilter & Filters, enabled: boolean = true) => {
  const { operationRequest } = useClient(IClient.AIDBOX)
  const { archived, performerLabs, searchText, comboPD } = filters ?? {}
  const queryKey = settingsQueryKeys.labPanels(orgid, performerLabs, archived, comboPD, searchText)

  const params: Parameters = {
    parameter: [
      ...(performerLabs?.length
        ? performerLabs.map((lab) => {
            return {
              name: "lab",
              value: { Reference: { id: lab, resourceType: "Organization" } },
            }
          })
        : []),
      ...(comboPD ? [{ name: "combo", value: { Reference: { id: comboPD, resourceType: "PlanDefinition" } } }] : []),
      ...(archived !== undefined ? [{ name: "retired", value: { boolean: archived } }] : []),
    ],
    resourceType: "Parameters",
  }

  const { data, isLoading, isFetchingNextPage, fetchNextPage, refetch, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filter = new URLSearchParams({
        ...(searchText ? { _filter: searchText } : {}),
        _count: "100",
        _page: `${pageParam}`,
        _sort: "title",
      })
      const respnse = await operationRequest<Parameters>("Organization", "POST", "get-panels", orgid, params, filter)
      let total = 0
      let next: number | undefined

      const panels = respnse.parameter?.reduce<LabPanel[]>((prev, parameter) => {
        if (parameter.name === "next") {
          next = parameter.value?.integer
        } else if (parameter.name === "total") {
          total = parameter.value?.integer ?? 0
        }

        if (parameter.name === "panel-info") {
          return [...prev, { ...mapPanelParams(parameter.part) }]
        }

        return [...prev]
      }, [])

      return { panels, next, total }
    },
    meta: { context: { queryKey, orgid } },
    enabled,
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
  })

  const { panels, count } = useMemo(() => {
    const panels = data?.pages.flatMap((page) => page.panels ?? [])

    return { panels, count: panels?.length }
  }, [data])

  return {
    labPanels: panels ?? [],
    count,
    isFetchingNextPage,
    fetchNextPage,
    isLoadingLabPanels: isLoading && enabled,
    refetch,
    hasNextPage,
  }
}

type Filters = {
  searchText?: string
  comboPD?: string
}

export { useLabPanels }
