import { Reference, ResourceObject, asReference, isPractitioner } from "fhir"
import { useMsal } from "@azure/msal-react"
import { InteractionStatus, InteractionRequiredAuthError, AuthenticationResult } from "@azure/msal-browser"
import { createContext, useMemo, ReactNode, useCallback, useEffect, useState, useRef } from "react"
import { differenceInMinutes } from "date-fns"

import { LoadingView, CustomError } from "commons"
import { datadogLogs } from "logger"
import { datadogRum as dataDog } from "datadog"
import { loginRequest } from "authConfig"
import { useCheckNetworkContext } from "check-network"

import { AuthError } from "../components"

const DEFAULT_RENEW_TOKEN_TIMEOUT = 3 // Wait 3 minutes by default due the min time allowed is 5 minutes

const AuthContext = createContext<State | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const { instance, inProgress } = useMsal()
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isLoading, setIsloading] = useState(inProgress !== InteractionStatus.None)
  const [error, setError] = useState<Error | undefined>()
  const refreshTokenTimer = useRef<NodeJS.Timeout | null>(null)
  const { isOnline, setIsOnline } = useCheckNetworkContext()
  const isOnlineRef = useRef(isOnline)

  const clearRefreshTokenTimer = () => {
    if (refreshTokenTimer.current) {
      clearTimeout(refreshTokenTimer.current)
    }
  }

  const initializeUser = (response: AuthenticationResult) => {
    instance.setActiveAccount(instance.getAccountByHomeId(response.account?.homeAccountId as string))
    const name = response.account?.name ?? "unspecified"
    const email = (response.idTokenClaims as { email: string })?.email ?? "unspecified email"
    const token = `${response.tokenType} ${response.accessToken}`
    let logId = email

    const linkedUser = getLinkedUser(response)

    if (!linkedUser) {
      datadogLogs.setUser({ id: response.account?.homeAccountId ?? email, name, email })

      setError(
        new Error("Unauthorized", {
          cause: { name: "401", message: `No resource linked to user ${name}` },
        }),
      )
    } else {
      logId = linkedUser.id ?? email
      datadogLogs.setUser({ id: logId, name, email })

      setUser({ name, email, token, linkedUser })
    }

    dataDog.setUser({
      id: logId,
      name: name,
      email: email,
    })

    setIsloading(false)
  }

  const tryRenewAccessToken = async () => {
    const account = instance.getActiveAccount()

    if (account) {
      const request = {
        ...loginRequest,
        account,
      }

      // Silently acquires an access token which is then attached to a request for aidbox data
      await instance
        .acquireTokenSilent(request)
        .then((response) => {
          const interval = getRenewTokenInterval(response)

          initializeUser(response)
          clearRefreshTokenTimer()

          refreshTokenTimer.current = setTimeout(() => tryRenewAccessToken(), interval)
        })
        .catch(async (error) => {
          setIsloading(false)

          if (
            error.errorMessage.includes("AADB2C90077") ||
            error.errorMessage.includes("AADB2C90091") ||
            error.errorMessage.includes("AADB2C90080") ||
            error instanceof InteractionRequiredAuthError
          ) {
            await instance.loginRedirect(loginRequest)
          } else {
            if (isNetworkError(error)) {
              setIsOnline(false)
              throw new Error("NetworkError", { cause: { name: "499", message: "NetworkError" } })
            } else {
              setError(new Error(error.errorCode, { cause: { name: error.name, message: error.errorMessage } }))
            }
          }
        })
    } else {
      await instance.loginRedirect(loginRequest)
    }
  }

  const checkAccount = async () => {
    await instance.initialize()
    await instance
      .handleRedirectPromise()
      .then(async (response) => {
        if (response && response.account) {
          const interval = getRenewTokenInterval(response)

          initializeUser(response)
          clearRefreshTokenTimer()

          refreshTokenTimer.current = setTimeout(() => tryRenewAccessToken(), interval)
        } else {
          const account = instance.getActiveAccount()

          if (!account) {
            await instance.loginRedirect(loginRequest)
          } else {
            tryRenewAccessToken()
          }
        }
      })
      .catch(async (error) => {
        setIsloading(false)

        if (
          error.errorMessage.includes("AADB2C90077") ||
          error.errorMessage.includes("AADB2C90091") ||
          error.errorMessage.includes("AADB2C90080") ||
          error instanceof InteractionRequiredAuthError
        ) {
          await instance.loginRedirect(loginRequest)
        } else {
          if (isNetworkError(error)) {
            setIsOnline(false)
            throw new Error("NetworkError", { cause: { name: "499", message: "NetworkError" } })
          } else
            setError(
              new Error("Authentication error", {
                cause: { ...error },
              }),
            )
        }
      })
  }

  useEffect(() => {
    checkAccount()
  }, [])

  useEffect(() => {
    if (!isOnline) {
      clearRefreshTokenTimer()
    } else if (!isOnlineRef.current && isOnline) {
      checkAccount()
    }

    isOnlineRef.current = isOnline
  }, [isOnline])

  const logout = useCallback(() => {
    const account = instance.getActiveAccount()

    if (account) {
      const logoutRequest = {
        account: instance.getAccountByHomeId(account.homeAccountId),
        postLogoutRedirectUri: "/",
      }
      instance.logoutRedirect(logoutRequest)
    }
  }, [instance])

  const isNetworkError = (error: Error) => /endpoints_resolution_error|no_network_connectivity/.test(error?.message)

  const setLinkedResource = useCallback(
    (resource: ResourceObject) => {
      const linkedResource = asReference(resource)

      if (!isPractitioner(linkedResource)) {
        setError(
          new Error("Unauthorized", {
            cause: {
              name: "401",
              message: `Sorry ${
                linkedResource.display ?? "Unknown tenant"
              }, but you don't have permission to access to EHR. If you think it is a mistake, contact to support.`,
            },
          }),
        )
      } else {
        if (user) {
          datadogLogs.setUser({ id: linkedResource.id, name: user.name, email: user.email })
          setUser({ ...user, practitionerResource: linkedResource })
        }
      }
    },
    [user],
  )

  const value = useMemo(
    () => ({
      user,
      logout,
      setLinkedResource,
    }),
    [user, logout, setLinkedResource],
  )

  if (isLoading) {
    return <LoadingView />
  }

  if (error) {
    datadogLogs.logger.error(error.message, { cause: error.cause }, error)

    return <AuthError error={error as CustomError} logout={logout} />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const getRenewTokenInterval = (response: AuthenticationResult) => {
  const diff = response.expiresOn ? differenceInMinutes(response.expiresOn, new Date()) : DEFAULT_RENEW_TOKEN_TIMEOUT

  return Math.floor((diff * 2) / 3) * 60000
}

const getLinkedUser = (response: AuthenticationResult): Reference | undefined => {
  const claimUsers = JSON.parse((response.idTokenClaims as IdTokenClaims)["aidbox/users"]) as string[]

  if (claimUsers?.length) {
    const user = claimUsers.find((user) => user.includes("evexias"))

    if (user) {
      const [, id] = user.split("|")

      return {
        id,
        resourceType: "User",
      }
    }
  }

  if ((response.idTokenClaims as IdTokenClaims)["user/id"]) {
    return {
      id: (response.idTokenClaims as IdTokenClaims)["user/id"],
      resourceType: "User",
    }
  }
}

type IdTokenClaims = {
  "user/id": string
  "aidbox/users": string
}

type State = {
  user?: User
  logout(): void
  setLinkedResource(resource: ResourceObject): void
}

export type User = {
  email: string
  name: string
  token: string
  practitionerResource?: Reference
  linkedUser: Reference
}

type Props = {
  children: ReactNode
}

export { AuthProvider, AuthContext }
