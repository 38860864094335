const creditCardQueryKeys = {
  all: ["organization/credit-cards"],
  withOrgId: (orgId: string) => [...creditCardQueryKeys.all, orgId],
}
const accountsQueryKeys = {
  all: ["organization/accounts"],
  withOrgId: (orgId: string) => [...creditCardQueryKeys.all, orgId],
}

export { creditCardQueryKeys, accountsQueryKeys }
