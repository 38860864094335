import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faUsers,
  faSignOut,
  faUserAlt,
  faSitemap,
  faBell,
  faUsersCog,
  faUsersMedical,
  faMemoCircleCheck,
} from "@fortawesome/pro-light-svg-icons"
import { useRef, PropsWithChildren } from "react"
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import { humanNameAsString } from "fhir"
import { Menu } from "primereact/menu"
import { classNames } from "primereact/utils"

import { useAuth, useLoginContext } from "security"

import chIconLogo from "../../images/chIconLogo.webp"
import { AvatarImage } from "./AvatarImage"

const MainNavbar = () => {
  const { logout, user } = useAuth()
  const { logedInPractitioner, isRootAdmin } = useLoginContext()
  const navigate = useNavigate()

  const { name, photo } = logedInPractitioner.practitioner ?? {}
  const menu = useRef<Menu>(null)

  const viewProfile = () => {
    navigate(`/practitioner-profile/${user?.practitionerResource?.id}`)
  }

  const items = [
    { label: "View Profile", icon: <FontAwesomeIcon icon={faUserAlt} className="mr-3" />, command: viewProfile },
    { label: "Logout", icon: <FontAwesomeIcon icon={faSignOut} className="mr-3" />, command: logout },
  ]

  return (
    <div className="flex bg-white border-y border-gray-200 drop-shadow-sm h-10">
      <nav className="flex flex-row flex-1 leading-6">
        <Link to="/" className="flex mx-3 items-center">
          <img src={chIconLogo} alt="app logo" className={"w-8"}></img>
        </Link>
        <ul className="flex items-center">
          <SidebarLink icon={faSitemap} to={`/organizations`}>
            Organizations
          </SidebarLink>
          <SidebarLink icon={faUsersMedical} to={`/practitioners`}>
            Practitioners
          </SidebarLink>
          <SidebarLink icon={faUsers} to={`/patients`}>
            Patients
          </SidebarLink>
          <SidebarLink icon={faUsersCog} to={`/users`}>
            Users
          </SidebarLink>
          <SidebarLink icon={faMemoCircleCheck} to={`/orders`}>
            Orders
          </SidebarLink>
          {isRootAdmin && (
            <SidebarLink icon={faBell} to={`/subnotifications`}>
              Notifications
            </SidebarLink>
          )}
        </ul>

        <div className="flex flex-1 justify-end">
          <div
            className="flex items-center justify-center hover:bg-gray-50 cursor-pointer w-16"
            onClick={(event) => menu?.current?.toggle(event)}
          >
            <div className="flex ease-in-out">
              <span className="sr-only">Open user menu</span>

              <AvatarImage
                name={humanNameAsString(name?.[0])}
                photoUrl={photo?.[0]?.url}
                className="rounded-full h-8 w-8"
              />
            </div>

            <Menu className="ml-3" popup model={items} ref={menu} style={{ width: "230px" }} />
          </div>
        </div>
      </nav>
    </div>
  )
}

const SidebarLink = ({ to, icon, children }: PropsWithChildren<LinkProps>) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname })

  return (
    <li
      className={classNames("sidebar-link flex h-full", {
        "bg-gray-100": match,
        "hover:bg-gray-50": !match,
      })}
    >
      <Link
        to={to}
        className={classNames("flex items-center text-center px-4 border-b-2", {
          "border-secondary text-secondary": match,
          "text-slate-500 border-transparent": !match,
        })}
      >
        <span className="link-item items-center justify-center text-xl">
          <FontAwesomeIcon icon={icon} className="fa-fw" />
        </span>
        <span className="pl-3">{children}</span>
      </Link>
    </li>
  )
}

type LinkProps = {
  to: string
  icon: IconProp
}

export { MainNavbar }
